import AdministratorType from "../../../models/administratorType.model";
import { guid } from "@progress/kendo-react-common";
import { Project } from "../../../models/project.model";
import { UserGroupView } from "../../../models/userGroupView.model";
import { User } from "../../../models/user.model";
import { Group } from "../../../models/group.model";
import { INFRA_DIGITAL_GROUP_OWNER } from "../../../globalSettings";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { FormattedMessage, useIntl } from "react-intl";
import React from "react";
import { UsersService } from "../../../services/users/users.service";
import { useWraperContext } from "../../complements/Wrapper";
import { useMsal } from "../../../services/auth/authProvider";
import { useHistory } from 'react-router-dom';

export function GroupFormController(props: any) {
  const intl = useIntl();
  const { user } = useMsal();
  const { locale } = useWraperContext();
  const usersService = UsersService({ locale: locale });
  const history = useHistory();
  const getProjects = async (admType: AdministratorType) => {
    if (admType.IsSuperUser) {
      return await props.projectService.getAllItems();
    } else if (admType.IsAdminGroup) {
      return await props.userAppsService.getAdminUserApps();
    }
  }

  const getUsers = async () => {
    return await props.usersService.getUsers();
  }

  const getUsersGroup = async (groupId: number) => {

    const usersGroup = await props.projectService.getUserGroupsByGroupId(groupId);
    usersGroup.data.forEach((userData: any) => {
      userData.newId = guid();
      userData.isNew = false;
    });
    return usersGroup;
  }

  const addUsersGroup = () => {
    if (props.selectedUser && props.selectedUser.value.Id !== 0) {
      props.setLoading(true);
      const exists = existsUserInList(props.selectedUser.value.Id);
      if (!exists) {
        const userGroup: UserGroupView = {
          newId: guid(),
          Id: 0,
          GroupId: props.currentGroup.Id,
          Username: props.selectedUser.value.Username,
          Userfullname: props.selectedUser.value.Name,
          Role: props.selectedUser.value.Role,
          UserId: props.selectedUser.value.Id,
          isNew: true
        };
        if (allowAddUser(userGroup)) {

          props.userGroups.push(userGroup);
          props.setUserGroups(props.userGroups);
          props.setSelectedUser({
            value: props.defaultUser
          });
        }
      } else {
        props.setExistsGroupUser(true);
        setTimeout(() => {
          props.setExistsGroupUser(false);
        }, 3000)
      }
      props.setSubmitedUser(false);
      props.setLoading(false);
    }
  }

  const existsUserInList = (userId: number): boolean => {
    let result: boolean = false;
    if (props.userGroups && props.userGroups.filter((s: any) => s.UserId === userId).length > 0) {
      result = true;
    }
    return result;
  }

  const validateOwner = (): boolean => {
    if (props.currentGroup.Id && (props.currentGroup.IsAdminGroup !== undefined &&
      props.currentGroup.IsAdminGroup === true)) {
      if (props.userGroups.length === 0) {
        props.setIsRequiredOwner(true);
        setTimeout(() => {
          props.setIsRequiredOwner(false);

        }, 2000)
        return false;
      } else {
        props.setIsRequiredOwner(false);
        return true;
      }
    } else {
      props.setIsRequiredOwner(false);
      return true;
    }
  }

  const allowAddUser = (userGroup: UserGroupView): boolean => {
    let result = true;
    const totalUsersAdded = props.userGroups.length;
    if (userGroup.Username.toLocaleLowerCase().indexOf('iadb.org') === -1 && totalUsersAdded > 0) {
      const internalUsers = props.userGroups.filter((s: any) => s.Username.toLocaleLowerCase().indexOf('iadb.org') !== -1).length;
      const externalUsers = totalUsersAdded - internalUsers;

      if (externalUsers >= props.maxB2CUsers) {
        result = false;
      }
    }
    props.setAllowAddB2CUser(result);
    return result;
  }

  const getData = (projects: Project[]) => {
    const id: number = props.paramsId["id"];
    if (id) {
      props.projectService.getGroupById(id).then((response: any) => {
        const current: Group = response;
        if (current) {
          if (current.Name?.toLowerCase() === INFRA_DIGITAL_GROUP_OWNER.toLowerCase()) {
            props.setIsAppMain(true);
          }

          const currentApp = projects.filter(s => s.Id === current.AppId)[0];

          props.setStateApp({
            value: currentApp,
          });
          props.setIsAdminValue(current.IsAdminGroup === true ? "true" : "false");
          props.setIsUserDefaultGroupValue(current.IsUserDefaultGroup === true ? "true" : "false");
          props.setIsReadOnlyGroupValue(current.IsReadOnly === true ? "true" : "false");
          getUsersGroup(id).then((responseUsersGroup: any) => {
            const userGroupsData: UserGroupView[] = responseUsersGroup.data;
            props.setUserGroups(userGroupsData);
            props.setValue("groupName", current.Name);
            if(current.Name.toLocaleLowerCase().indexOf("owner")!== -1) {
              props.setIsOwnerGroup(true);
            }
            props.setIsBlocked(false);
            props.setLoading(false);
            
          });

        }

      });
    } else {
      props.setIsBlocked(false);
      props.setLoading(false);
    }
  }

  const saveUsersGroup = async () => {
    try {
      let saveUsersPromises: Promise<any>[] = [];
      let deleteUsersPromises: Promise<any>[] = [];
      props.userGroups.forEach(async (gu: UserGroupView) => {
        if (gu.isNew === true) {
          saveUsersPromises.push(props.usersService.addGroupUser(gu).catch((err: any) => { throw err }));
        }
      });
      await Promise.all(saveUsersPromises).catch(err => { throw err });

      props.deletedTempUserGroups.forEach(async (gu: UserGroupView) => {
        if (gu.isNew !== true) {
          deleteUsersPromises.push(props.usersService.deleteGroupUser(gu.Id).catch((err: any) => { throw err }));
        }
        await Promise.all(deleteUsersPromises).catch(err => { throw err });
      });
      if (user) {
        const userName = usersService.getUsername(user);

        const deletedOwner = props.deletedTempUserGroups.filter((s:any) => 
        s.Username.toLowerCase() === userName.toLocaleLowerCase());
         if(deletedOwner.length > 0) {
          localStorage.setItem("isSuperUser","");
          localStorage.setItem("isAdminGroup","");
          localStorage.clear();
         }
      }
    } catch (err) {
      throw err;
    }
  }

  const handleChangeApp = (event: DropDownListChangeEvent) => {
    const project: Project = event.target.value;
    props.setStateApp({
      value: project,
    });
  };

  const handleChangeUser = (event: DropDownListChangeEvent) => {
    props.setAllowAddB2CUser(true);
    const userData: User = event.target.value;
    props.setSelectedUser({
      value: userData,
    });
  };

  const onSubmit = async (data: any) => {
    props.setAllowAddB2CUser(true);
    props.setSubmited(true);
    props.setLoading(true);
    try {
      if (typeof data.appId === 'object') {
        data.appId = props.stateApp.value.Id;
      }
      if (typeof props.paramsId.id) {
        props.currentGroup.Id = props.paramsId.id;
      }
      if (data && props.stateApp.value.Id
         && data.groupName &&
          (props.isAdminValue === "true" || props.administratorType.IsSuperUser) && validateOwner()) {
        const group: Group = {
          Id: props.currentGroup.Id,
          AppId: Number(props.stateApp.value.Id),
          Name: data.groupName as string,
          IsAdminGroup: props.isAdminValue === 'true' ? true : false,
          IsUserDefaultGroup:
            props.isUserDefaultGroupValue === 'true' ? true : false,
          IsReadOnly: props.isReadOnlyGroupValue === 'true' ? true : false,
          IsDeleted: false,
        };
        if (props.currentGroup.Id && props.currentGroup.Id !== 0) {
          await props.projectService.updateGroup(group);
          const userApps: Project[] = await props.userAppsService.getUserApps();
          localStorage.removeItem('userGroup');
          localStorage.setItem('userGroup', btoa(JSON.stringify(userApps)));
          props.setLoading(false);
          props.history.push({
            pathname: '/admin/groups/',
            state: {},
          });
        } else {
          const added: any = await props.projectService.addGroup(group);
          let current = props.currentGroup;
          current.Id = added.data;
          props.userGroups.forEach((ug: UserGroupView) => {
            ug.GroupId = added.data;
          });

          props.setUserGroups(props.userGroups);
          props.setLoading(false);
          history.push(`/admin/groups/form/${current.Id}`);
        }

        await saveUsersGroup().catch(err => { throw err });
        props.setCurrentGroup(null);
      } else {
        props.setLoading(false);
      }
    } catch (err) {
      props.setLoading(false);

      props.setIsError(true);
      setTimeout(() => {
        props.setIsError(false);
      }, 4000);
    }
  };

  const loadDetailUsers = () => {
    return (
      <div className="col-12 col-md-8 mt-4">
        {!props.loading && (
          <table>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="admin.userGroups.grid.Name.title" />
                </th>
                <th>
                  <FormattedMessage id="admin.userGroups.grid.UserName.title" />
                </th>
                <th>
                  <FormattedMessage id="general.actions" />
                </th>
              </tr>
            </thead>
            <tbody>
              {props.userGroups.map((userGroup: any, index: number) => {
                return (
                  <React.Fragment>
                    <tr key={index}>
                      <td>{userGroup.Userfullname}</td>
                      <td>{userGroup.Username}</td>
                      <td>
                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            const usersG = props.userGroups.filter(
                              (s: any) => s.newId !== userGroup.newId
                            );
                            props.deletedTempUserGroups.push(userGroup);
                            props.setDeletedTempUserGroups(
                              props.deletedTempUserGroups
                            );
                            props.setUserGroups(usersG);
                          }}
                        >
                          <span className="icon-trash"></span>
                        </button>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
              <tr>
                <td colSpan={3}>
                  <DropDownList
                    data={props.users}
                    textField="Username"
                    dataItemKey="Id"
                    value={props.selectedUser.value}
                    defaultItem={intl.formatMessage({
                      id: 'admin.groups.form.user.placeHolder',
                    })}
                    name="users"
                    className="drop-field"
                    onChange={handleChangeUser}
                  />
                </td>
                <td>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      props.setSubmitedUser(true);
                      addUsersGroup();
                    }}
                  >
                    <span className="icon-add-user"></span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        )}
        {props.submitedUser &&
          (!props.selectedUser.value ||
            (props.selectedUser.value && props.selectedUser.value.Id === 0)) && (
            <span style={{ color: 'darkred' }}>
              <FormattedMessage id="admin.groups.form.user.required" />
            </span>
          )}
        {!props.allowAddB2CUser && (
          <span style={{ color: 'darkred' }}>
            <FormattedMessage id="admin.groups.form.user.notMoreB2C" />
          </span>
        )}
      </div>

    );
  }




  return ({
    getProjects,
    getUsers,
    getUsersGroup,
    validateOwner,
    addUsersGroup,
    getData,
    allowAddUser,
    saveUsersGroup,
    handleChangeApp,
    handleChangeUser,
    onSubmit,
    loadDetailUsers
  });
}