import { DefaultObject } from "../../../models/complements/defaultObject";
import { Dataset } from "../../../models/dataset.model";


export const accessLevels = (intl: any) => {
  return [
    {
      id: 'public',
      name: intl.formatMessage({
        id: 'admin.datasets.access.level.public',
      }),
    },
    {
      id: 'private-infra',
      name: intl.formatMessage({
        id: 'admin.datasets.access.level.private',
      }),
    },
  ];
}

export const getTypeRepository = (intl: any) => {
  return [
    {
      id: 'sql',
      name: intl.formatMessage({
        id: 'admin.datasets.type.sql',
      }),
    },
    {
      id: 'amazon-athena',
      name: intl.formatMessage({
        id: 'admin.datasets.type.athena',
      }),
    },
    {
      id: 'amazon-s3',
      name: intl.formatMessage({
        id: 'admin.datasets.amazon.s3',
      }),
    },
  ]
}

export const getProjects = async (cn: any) => {
  return await cn.projectService.getAllItems();
}

export const getData = (projects: DefaultObject[], cn: any) => {
  const typeRepository = getTypeRepository(cn.intl);
  cn.setLoading(true);
  const id: number = cn.paramsId['id'];
  if (id) {
    cn.setIsEdit(true);
    cn.datasetService.getDatasetById(id, cn.apiKey).then((data: any) => {
      const current: Dataset = data.data;
      if (current) {
        cn.setCurrentDataset(current);
        const currentApp = projects.filter((s) => s.id === current.AppId)[0];
        const currentAccess = accessLevels(cn.intl).filter((s) => s.id === current.AccessLevel)[0];
        const currentType = typeRepository.filter((s) => s.id === current.RepositoryType)[0];
        cn.setValue('Name', current.Name);
        cn.setValue('Config', current.Config);
        cn.setStateAccess({ value: currentAccess });
        cn.setStateType({ value: currentType });
        cn.setStateApp({ value: currentApp });
      }
      cn.setIsBlocked(false);
      cn.setLoading(false);
    });
  } else {
    cn.setIsBlocked(false);
    cn.setLoading(false);
  }
};

