import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MsalProvider, msalConfig, loginRequest } from "./services/auth/authProvider"
import { IntlProvider } from 'react-intl';
import English from './lang/en.json';
import Spanish from './lang/es.json';
import Portugues from './lang/pt.json';

ReactDOM.render(
  <IntlProvider
    locale={navigator.language}
    messages={{ ...English, ...Spanish, ...Portugues }}
  >
    <MsalProvider config={msalConfig} scopes={loginRequest}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </MsalProvider>
  </IntlProvider>
  ,
  document.getElementById('root')
);
reportWebVitals();

