import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { cardCategoryFilterHome } from '../../core/services/mocks/data-filter';
import { useMsal } from '../../services/auth/authProvider';
import ReactTooltip from "react-tooltip";

const dataCategoryFilter = cardCategoryFilterHome;
const getTitleByCategory = (title: string): string => {
  let value = '';
  switch (title.toLowerCase()) {
    case 'energy':
      value = 'category.energy';
      break;
    case 'transport':
      value = 'category.transport';
      break;
    case 'water':
      value = 'category.water.and.sanitation';
      break;
    case 'tools':
      value = 'category.tools';
      break;
  }
  return value;
}

const Filter = (props: any) => {
  const intl = useIntl();


  const { userInternal } = useMsal();
  const [categoryActive, setCategoryActive] = useState<string>('');
  const [isUserInternalFilt, setIsUserInternalFilt] = useState<boolean>(false);
  function shoot(index: number, categoryName: string) {
    const isDisabled = dataCategoryFilter[index].category === categoryActive;
    if (props.filterCallback) {
      props.filterCallback({ category: (isDisabled) ? '' : dataCategoryFilter[index].category });
    }
    setCategoryActive(isDisabled ? '' : dataCategoryFilter[index].category);
  }
  const changeCategory = (category: string): void => {
    setCategoryActive(category);
  };

  const getDescriptionCategory = (title: string): string => {

    let result = '';
    switch (title.toLowerCase()) {
      case 'energy':
        result = intl.formatMessage({
          id: 'category.energy.description',
        });
        break;
      case 'transport':
        result = intl.formatMessage({
          id: 'category.transport.description',
        });
        break;
      case 'water':
        result = intl.formatMessage({
          id: 'category.water.description',
        });
        break;
      case 'tools':
        result = intl.formatMessage({
          id: 'category.tools.description',
        });
        break;
    }
    return result;
  };

 

  useEffect(() => {
    function fetchBusiness (){
      if (userInternal) {
        setIsUserInternalFilt(userInternal());
      }
      if (props.projectFilter.category !== undefined) {
        changeCategory(props.projectFilter.category);
      }
    }
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <section id="filter">
      {isUserInternalFilt && (
        <div>
          <h2>
            <FormattedMessage id={'home.filter.filterby'} />
          </h2>
          <div className="filterby">
            {dataCategoryFilter.map((data: any, index: number) => {
              return (
                <React.Fragment>
                  <article data-tip data-for={`registerTip${data.category}`}
                    className={`filtro ${data.category} ${data.category === categoryActive ? ' filtered' : ''
                      }`}
                  >

                    <span
                      className="btn"
                      onClick={() => shoot(index, dataCategoryFilter.category)}
                    >
                      <div className={'square ' + data.category}>
                        <span className={'icon-' + data.category}></span>
                      </div>
                      <h3 className={data.category}>
                        <FormattedMessage
                          id={getTitleByCategory(data.category)}
                        />
                      </h3>
                      <p>
                        {`${getDescriptionCategory(data.category).substring(0, 100)}...`}
                      </p>
                    </span>
                  </article>
                  <ReactTooltip className='loquesea' id={`registerTip${data.category}`} data-place="top" effect="solid" >
                    <p style={{ maxWidth: "900px" }}>
                      {getDescriptionCategory(data.category)}
                    </p>
                  </ReactTooltip>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}{' '}
    </section>
  );
}


export default Filter;