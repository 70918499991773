import React, { useEffect } from "react";
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { FormattedMessage } from 'react-intl';
import { DatasetLower } from '../../../models/dataset.lower.model';
import { Alert } from "react-bootstrap";
import AdministratorType from "../../../models/administratorType.model";
import Loading from "../../loading/Loading.component";
import { accessLevels, getProjects, getTypeRepository, getData } from './datasets.Form.Controller';
import { DatasetsFormConsts } from "./datasetsForm.admin.consts";

function DatasetForm() {
  const cn = DatasetsFormConsts();

  useEffect(() => {
    function fetchBusiness() {
      const id: number = cn.paramsId['id'];
      if (id) {
        cn.setIsBlocked(true);
      }
      cn.setLoading(true);
      if (cn.user) {
        const userName = cn.usersService.getUsername(cn.user);
        cn.usersService.getIsAdminbyUsername(userName).then((admType: AdministratorType) => {

          if (admType.IsSuperUser) {
            getProjects(cn).then((projects) => {
              if (projects.length > 0) {
                const projectsAC = projects.map((x: any) => { return { id: x.Id, name: x.Name }; });
                cn.setApps(projectsAC);
                getData(projectsAC, cn);
              }
            });
          } else if (admType.IsAdminGroup) {
            cn.userAppsService.getAdminUserApps().then(projects => {
              const projectsUA = projects.map((p: any) => { return { id: p.Id, name: p.Name }; });
              cn.setApps(projectsUA);
              getData(projectsUA, cn);
            });
          }
        });
      }
    }
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const typeRepository = getTypeRepository(cn.intl);

  const onSubmit = async (data: any) => {
    cn.setLoading(true);
    try {
      if (
        data && data.Name && data.Config && cn.stateAccess.value.id && cn.stateType.value.id && cn.stateApp.value.id
      ) {
        const dataset: DatasetLower = {
          id: cn.currentDataset.Id,
          name: data.Name,
          slug: data.Name.trim().replace(/\s/g, '-'),
          config: data.Config,
          accessLevel: cn.stateAccess.value.id,
          repositoryType: cn.stateType.value.id,
          appId: cn.stateApp.value.id,
        };
        if (!cn.isEdit) {
          const status = await cn.datasetService.addDataset(dataset);
          cn.history.push({ pathname: `/admin/datasets/showKey/${status.data.apiKeyGenerated}`, state: {} });
        } else {
          await cn.datasetService.updateDataset(dataset);
          cn.history.push({ pathname: '/admin/datasets/', state: {} });
        }
        cn.setLoading(false);
      } else {
        cn.setLoading(false);
      }
    } catch (e) {
      cn.setLoading(false);
      cn.setIsError(true);
      setTimeout(() => {
        cn.setIsError(false);
      }, 4000);
      console.error(e);
    }
  }
  const handleChangeApp = (event: DropDownListChangeEvent) => {
    cn.setStateApp({
      value: event.target.value,
    });
  };
  const handleChangeType = (event: DropDownListChangeEvent) => {
    cn.setStateType({
      value: event.target.value,
    });
  };
  const handleChangeAccess = (event: DropDownListChangeEvent) => {
    cn.setStateAccess({
      value: event.target.value,
    });
  };
  return (
    <React.Fragment>
      {cn.successfullSaved && (
        <Alert variant="success">
          <FormattedMessage id={'general.successfullSaved'} />
        </Alert>
      )}
      {cn.isError && (
        <Alert variant="warning">
          <FormattedMessage id={'general.error'} />
        </Alert>
      )}
      <div className={!cn.loading ? 'module-pjs' : 'module-pjs d-none'}>
        <div className="header-pj">
          <h2>
            <FormattedMessage id={'admin.datasets.titleForm'} />
          </h2>
        </div>
        <div className="hide-scroll-horizontal-bodies content-body">
          <form onSubmit={cn.handleSubmit(onSubmit)} className={cn.isBlocked ? 'form noClick' : 'form'}>
            <div className="row mt-4">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label label-for="name"><FormattedMessage id={'admin.datasets.name'} /></label>
                  <span style={{ color: 'darkred' }}>*</span>
                  <input {...cn.register('Name', { required: true, maxLength: 200 })}
                    id="name" className="fields" type="text"
                    placeholder={cn.intl.formatMessage({ id: 'admin.datasets.name' })}
                    onChange={(e) => cn.setValue('Name', e.target.value)}
                    defaultValue={cn.stateDataset.Name}></input>
                  {cn.submited && cn.errors.Name && (
                    <span style={{ color: 'darkred' }}><FormattedMessage id="admin.datasets.name.required" /></span>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label label-for="config">
                    <FormattedMessage id={'admin.datasets.config'} />
                  </label>
                  <span style={{ color: 'darkred' }}>*</span>
                  <input {...cn.register('Config', { required: true, maxLength: 255 })}
                    id="config" className="fields" type="text"
                    placeholder={cn.intl.formatMessage({ id: 'admin.datasets.config' })}
                    onChange={(e) => cn.setValue('Config', e.target.value)}
                    defaultValue={cn.stateDataset.Config}></input>
                  {cn.submited && cn.errors.Config && (
                    <span style={{ color: 'darkred' }}><FormattedMessage id="admin.datasets.config.required" />
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12"></div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label label-for="access">
                    <FormattedMessage id={'admin.datasets.access.level'} />
                  </label>
                  <span style={{ color: 'darkred' }}>*</span>
                  <DropDownList
                    name="access" className="drop-field" defaultValue={cn.stateAccess.value}
                    textField="name" dataItemKey="id"
                    data={accessLevels(cn.intl)} onChange={handleChangeAccess}
                  />
                  {cn.submited &&
                    (!cn.stateAccess.value.id ||
                      (cn.stateAccess.value && !cn.stateAccess.value)) && (
                      <span style={{ color: 'darkred' }}><FormattedMessage id="admin.datasets.access.level.required" />
                      </span>
                    )}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label label-for="repository">
                    <FormattedMessage id={'admin.datasets.repository.type'} />
                  </label>
                  <span style={{ color: 'darkred' }}>*</span>
                  <DropDownList name="repository" className="drop-field" defaultValue={cn.stateType.value}
                    textField="name" dataItemKey="id" data={typeRepository}
                    onChange={handleChangeType}
                  />
                  {cn.submited &&
                    (!cn.stateType.value.id || (cn.stateType.value && !cn.stateType.value)) && (
                      <span style={{ color: 'darkred' }}><FormattedMessage id="admin.datasets.repository.type.required" />
                      </span>
                    )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label label-for="appId"><FormattedMessage id={'admin.datasets.app'} /></label>
                  <span style={{ color: 'darkred' }}>*</span>
                  <DropDownList {...cn.register('appId', { required: true })}
                    name="appId" className="drop-field" textField="name" dataItemKey="id"
                    data={cn.apps} defaultValue={cn.stateApp.value}
                    onChange={handleChangeApp}></DropDownList>
                  {cn.submited && (!cn.stateApp.value.id || (cn.stateApp.value && !cn.stateApp.value)) && (
                    <span style={{ color: 'darkred' }}><FormattedMessage id="admin.datasets.app.required" /></span>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6"></div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6"></div>
              <div className="col-12 col-md-6"></div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-7"></div>
              <div className="row mt-5">
                <div className="col-12 col-md-7"></div>
                <div className="col-12 col-md-5">
                  <button type="submit" className="newPj" onClick={() => { cn.setSubmited(true); }}>
                    <FormattedMessage id={'admin.groups.form.save'} /> </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {cn.loading && (<Loading></Loading>
      )}
    </React.Fragment>
  );
}
export default DatasetForm;