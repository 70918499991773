import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

// Components
import Logout from './components/Logout/Logout';
import Header from './components/Header/Header';
import Descript from './components/Descript/Descript';
import Related from './components/Related/Related';
import Home from './components/Home/Home';
import { useMsal } from './services/auth/authProvider';
import AxiosInterceptors from './axios.interceptors';
import ProjectsAdmin from './components/admin/projects/projects.admin';
import UsersAdmin from './components/admin/users/users.admin';
import DatasetsAdmin from './components/admin/datasets/datasets.admin';
import DatasetAdminForm from './components/admin/datasets/datasetsForm.admin';
import UserFormAdmin from './components/admin/users/userForm.admin';
import ProjectFormAdmin from './components/admin/projects/projectForm.admin';
import NavigationAdmin from './components/admin/navigation/navigation.admin';
import HeaderAdmin from './components/admin/headerAdmin/header.admin';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import { useWraperContext } from './components/complements/Wrapper';
import GroupsAdmin from './components/admin/groups/groups.admin';
import GroupFormAdmin from './components/admin/groups/groupForm.admin';
import DashboardAdmin from './components/admin/dashboard/dashboard.admin';
import { UsersService } from './services/users/users.service';
import AdministratorType from './models/administratorType.model';
import DatasetShowNewKey from './components/admin/datasets/datasetShowNewKey.admin';
import { AboutUs } from './components/about/AboutUs';

function Router(props?: any) {
  const { locale } = useWraperContext();
  const usersService = UsersService({
    locale: locale
  });
  const [isAdmin, setIsAdmin] = useState<boolean>();
  const { isAuthenticated, token, user } = useMsal();

  const setCategoryMainColorActive = (filter: any) => {
    if (props.setCategoryFActive) {
      props.setCategoryFActive(filter);
    }
  };
  const clean = () => {
    const colors = document.getElementById('colors');
    if (colors !== null) {
      colors.className = '';
    }
  };
  useEffect(() => {
    clean();
  }, [locale])

  const requireSuperUser = (to: any, from: any, next: any) => {
    if (user) {
      localStorage.setItem('userLogin', JSON.stringify(user));

      let userName = usersService.getUsername(user);
      usersService
        .getIsAdminbyUsername(userName)
        .then((result: AdministratorType) => {
          if (result.IsSuperUser) {
            next();
          } else {
            next.redirect('/home');
          }
        });
    }
  };

  const requireAdministrator = (to: any, from: any, next: any) => {
    if (user) {
      let userName = usersService.getUsername(user);
      usersService.getIsAdminbyUsername(userName).then((result: AdministratorType) => {
        setIsAdmin(result.IsSuperUser || result.IsAdminGroup);
        if (result.IsSuperUser || result.IsAdminGroup) {
          next();
        } else {
          next.redirect('/home');
        }
      });
    }
  };

  return (
    <React.Fragment>
      {isAuthenticated && token && AxiosInterceptors(token)}
      <Switch>
        <Route
          exact
          path="/"
          component={() => (
            <Home setCategoryFActive={setCategoryMainColorActive} />
          )}
        />
        <Route
          exact
          path="/home"
          component={() => (
            <Home setCategoryFActive={setCategoryMainColorActive} />
          )}
        />

        <Route
          exact
          path="/about"
          component={() => (
            <AboutUs />
          )}
        />
        <Route exact path="/logout" component={Logout} />
        <Route
          exact
          path="/info/:slug"
          render={() => (
            <React.Fragment>
              <Header />
              <Descript setCategoryNameActive={setCategoryMainColorActive} />
              <Related />
            </React.Fragment>
          )}
        />
        <GuardProvider guards={[requireAdministrator]}>
          <Switch>
            <GuardedRoute
              guards={[requireSuperUser]}
              exact
              path="/admin"
              render={() =>
                isAdmin && (
                  <React.Fragment>
                    <div id="admin" className="row fx-end-md">
                      <div className="nav-menu">
                        <NavigationAdmin></NavigationAdmin>
                      </div>
                      <div className="col-9 pad-0 flx-cont">
                        <HeaderAdmin></HeaderAdmin>
                        <section className="contain">
                          <DashboardAdmin></DashboardAdmin>
                        </section>
                      </div>
                    </div>
                  </React.Fragment>
                )
              }
            />
            <GuardedRoute
              exact
              path="/admin/projects"
              render={() =>
                isAdmin && (
                  <React.Fragment>
                    <div id="admin" className="row fx-end-md">
                      <div className="nav-menu">
                        <NavigationAdmin></NavigationAdmin>
                      </div>
                      <div className="pad-0 bg-grey flx-cont">
                        <HeaderAdmin></HeaderAdmin>
                        <section className="contain">
                          <ProjectsAdmin></ProjectsAdmin>
                        </section>
                      </div>
                    </div>
                  </React.Fragment>
                )
              }
            />

            <GuardedRoute
              exact
              path="/admin/projects/form"
              render={() => (
                <React.Fragment>
                  <div id="admin" className="row fx-end-md">
                    <div className="nav-menu">
                      <NavigationAdmin></NavigationAdmin>
                    </div>
                    <div className="pad-0 bg-grey flx-cont">
                      <HeaderAdmin></HeaderAdmin>
                      <section className="contain">
                        <ProjectFormAdmin></ProjectFormAdmin>
                      </section>
                    </div>
                  </div>
                </React.Fragment>
              )}
            />
            <GuardedRoute
              exact
              path="/admin/projects/form/:id"
              render={() => (
                <React.Fragment>
                  <div id="admin" className="row fx-end-md">
                    <div className="nav-menu">
                      <NavigationAdmin></NavigationAdmin>
                    </div>
                    <div className="pad-0 bg-grey flx-cont">
                      <HeaderAdmin></HeaderAdmin>
                      <section className="contain">
                        <ProjectFormAdmin></ProjectFormAdmin>
                      </section>
                    </div>
                  </div>
                </React.Fragment>
              )}
            />
            <GuardedRoute
              guards={[requireSuperUser]}
              exact
              path="/admin/users"
              render={() =>
                isAdmin && (
                  <React.Fragment>
                    <div id="admin" className="row fx-end-md">
                      <div className="nav-menu">
                        <NavigationAdmin></NavigationAdmin>
                      </div>
                      <div className="pad-0 bg-grey flx-cont">
                        <HeaderAdmin></HeaderAdmin>
                        <section className="contain">
                          <UsersAdmin></UsersAdmin>
                        </section>
                      </div>
                    </div>
                  </React.Fragment>
                )
              }
            />
            <GuardedRoute
              exact
              path="/admin/users/form"
              render={() => (
                <React.Fragment>
                  <div id="admin" className="row fx-end-md">
                    <div className="nav-menu">
                      <NavigationAdmin></NavigationAdmin>
                    </div>
                    <div className="pad-0 bg-grey flx-cont">
                      <HeaderAdmin></HeaderAdmin>
                      <section className="contain">
                        <UserFormAdmin></UserFormAdmin>
                      </section>
                    </div>
                  </div>
                </React.Fragment>
              )}
            />
            <GuardedRoute
              exact
              path="/admin/users/form/:id"
              render={() => (
                <React.Fragment>
                  <div id="admin" className="row fx-end-md">
                    <div className="nav-menu">
                      <NavigationAdmin></NavigationAdmin>
                    </div>
                    <div className="pad-0 bg-grey flx-cont">
                      <HeaderAdmin></HeaderAdmin>
                      <section className="contain">
                        <UserFormAdmin></UserFormAdmin>
                      </section>
                    </div>
                  </div>
                </React.Fragment>
              )}
            />
            <GuardedRoute
              exact
              path="/admin/groups"
              render={() =>
                isAdmin && (
                  <React.Fragment>
                    <div id="admin" className="row fx-end-md">
                      <div className="nav-menu">
                        <NavigationAdmin></NavigationAdmin>
                      </div>
                      <div className="pad-0 bg-grey flx-cont">
                        <HeaderAdmin></HeaderAdmin>
                        <section className="contain">
                          <GroupsAdmin></GroupsAdmin>
                        </section>
                      </div>
                    </div>
                  </React.Fragment>
                )
              }
            />
            <GuardedRoute
              exact
              path="/admin/groups/form"
              render={() =>
                isAdmin && (
                  <React.Fragment>
                    <div id="admin" className="row fx-end-md">
                      <div className="nav-menu">
                        <NavigationAdmin></NavigationAdmin>
                      </div>
                      <div className="pad-0 bg-grey flx-cont">
                        <HeaderAdmin></HeaderAdmin>
                        <section className="contain">
                          <GroupFormAdmin></GroupFormAdmin>
                        </section>
                      </div>
                    </div>
                  </React.Fragment>
                )
              }
            />
            <GuardedRoute
              exact
              path="/admin/groups/form/:id"
              render={() =>
                isAdmin && (
                  <React.Fragment>
                    <div id="admin" className="row fx-end-md">
                      <div className="nav-menu">
                        <NavigationAdmin></NavigationAdmin>
                      </div>
                      <div className="pad-0 bg-grey flx-cont">
                        <HeaderAdmin></HeaderAdmin>
                        <section className="contain">
                          <GroupFormAdmin></GroupFormAdmin>
                        </section>
                      </div>
                    </div>
                  </React.Fragment>
                )
              }
            />

            <GuardedRoute
              exact
              path="/admin/datasets"
              render={() =>
                isAdmin && (
                  <React.Fragment>
                    <div id="admin" className="row fx-end-md">
                      <div className="nav-menu">
                        <NavigationAdmin></NavigationAdmin>
                      </div>
                      <div className="pad-0 bg-grey flx-cont">
                        <HeaderAdmin></HeaderAdmin>
                        <section className="contain">
                          <DatasetsAdmin></DatasetsAdmin>
                        </section>
                      </div>
                    </div>
                  </React.Fragment>
                )
              }
            />
            <GuardedRoute
              exact
              path="/admin/datasets/form"
              render={() =>
                isAdmin && (
                  <React.Fragment>
                    <div id="admin" className="row fx-end-md">
                      <div className="nav-menu">
                        <NavigationAdmin></NavigationAdmin>
                      </div>
                      <div className="pad-0 bg-grey flx-cont">
                        <HeaderAdmin></HeaderAdmin>
                        <section className="contain">
                          <DatasetAdminForm></DatasetAdminForm>
                        </section>
                      </div>
                    </div>
                  </React.Fragment>
                )
              }
            />
            <GuardedRoute
              exact
              path="/admin/datasets/form/:id"
              render={() =>
                isAdmin && (
                  <React.Fragment>
                    <div id="admin" className="row fx-end-md">
                      <div className="nav-menu">
                        <NavigationAdmin></NavigationAdmin>
                      </div>
                      <div className="pad-0 bg-grey flx-cont">
                        <HeaderAdmin></HeaderAdmin>
                        <section className="contain">
                          <DatasetAdminForm></DatasetAdminForm>
                        </section>
                      </div>
                    </div>
                  </React.Fragment>
                )
              }
            />
            <GuardedRoute
              exact
              path="/admin/datasets/showKey/:key"
              render={() =>
                isAdmin && (
                  <React.Fragment>
                    <div id="admin" className="row fx-end-md">
                      <div className="nav-menu">
                        <NavigationAdmin></NavigationAdmin>
                      </div>
                      <div className="pad-0 bg-grey flx-cont">
                        <HeaderAdmin></HeaderAdmin>
                        <section className="contain">
                          <DatasetShowNewKey></DatasetShowNewKey>
                        </section>
                      </div>
                    </div>
                  </React.Fragment>
                )
              }
            />
          </Switch>
        </GuardProvider>
        <Route path="*" exact={true} component={Home} />
      </Switch>
    </React.Fragment>
  );

}

export default Router;