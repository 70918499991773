
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { User } from "../../../models/user.model";
import { UsersService } from "../../../services/users/users.service";
import { useWraperContext } from "../../complements/Wrapper";
import {
	Grid,
	GridColumn,
	GridCellProps,
	GRID_COL_INDEX_ATTRIBUTE,
	GridPageChangeEvent,
	GridFilterChangeEvent,
	GridFilterCellProps,
	GridNoRecords,
} from "@progress/kendo-react-grid";
import { PageState } from "../../../models/pagestate.model";
import { useHistory } from 'react-router';
import { CompositeFilterDescriptor, filterBy } from "@progress/kendo-data-query";
import { DropdownFilterCell } from "../../common/dropdownFilterCell";
import { confirmAlert } from "react-confirm-alert";
import { Alert, Modal } from "react-bootstrap";
import moment from 'moment';
import AdministratorType from "../../../models/administratorType.model";
import { ProjectsService } from "../../../services/projects/projects.service";
import { GroupView } from "../../../models/groupView.model";
import { useMsal } from "../../../services/auth/authProvider";

const initialFilter: CompositeFilterDescriptor = {
	logic: "and",
	filters: [],
};

const generalYes = 'general.yes';
const generalNo ='general.no';
const adminMenuGroupsTitle='admin.menu.groups.title';

function UsersAdmin() {
	const { locale } = useWraperContext();
	const projectsService = new ProjectsService({
		locale: locale
	});
	const history = useHistory();
	const usersService = UsersService({ locale: locale });
	const defaultUsers: User[] = new Array<User>();
	const [users, setUsers] = useState<User[]>(defaultUsers);
	const initialDataState: PageState = { skip: 0, take: 10 };
	const [page, setPage] = React.useState<PageState>(initialDataState);
	const intl = useIntl();
	const [filter, setFilter] = React.useState(initialFilter);
	const [loading, setLoading] = useState<boolean>(false);
	const [isDeleted, setIsDeleted] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);
	const [groups, setGroups] = useState<GroupView[]>([]);
	const {user } = useMsal();
	const [showModalGroups, setShowModalGroups] = useState(false);
	const [currentGroups, setCurrentGroups] = useState<any[]>([]);
	const [currentUser, setCurrentUser] = useState<User>();

	const getData = async () => {
		setLoading(true);
		try {
			let admType = new AdministratorType();
			if (user) {
				const userName = usersService.getUsername(user);
				admType = await usersService.getIsAdminbyUsername(userName);
			}
			const response = await usersService.getUsers();
			if (response.data.length > 0) {
				setUsers(response.data.map((x: any) => {
					const j = x;
					if (j.CreatedAt) {
						j.CreatedAt = moment(x.CreatedAt).format('MMMM DD, yyyy');
					}
					if (j.Infra2_V_GroupsByUsers && j.Infra2_V_GroupsByUsers.length > 0) {
						j.GroupsString = j.Infra2_V_GroupsByUsers.map((s: any) => `${s.Name} : ${s.IsAdminGroup ? 'Admin' : 'User'}`).join(',');
					}
					return j;
				}
				));
			}
			await getGroups(admType);
			setLoading(false);
		} catch (ex) {
			setLoading(false);
			setIsError(true);

			setTimeout(() => {
				setIsError(false);
			}, 4000);
		}
	}



	useEffect(()=>{
		function fetchBusiness (){
			getData();
		}
		fetchBusiness();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	interface Item {
		color: string;
	}
	interface CustomBooleanCellProps extends GridCellProps {
		myProp: Array<Item>;
	}

	const addNewUser = async (event: any) => {
		history.push({
			pathname: '/admin/users/form',
			state: {},
		});
	}

	const CustomBooleanCell = (props: CustomBooleanCellProps) => {
		const field = props.field || "";
		const value = props.dataItem[field];

		return (
			<td
				style={{ width: '200px' }}
				role={"gridcell"}
				aria-colindex={props.ariaColumnIndex}
				aria-selected={props.isSelected}
				{...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
			>
				{value === null ? "" : (props.dataItem[field] === true ? intl.formatMessage({
					id: generalYes,
				}) : intl.formatMessage({
					id: generalNo,
				}))}
			</td>
		);
	};
	const CrudCommandCell = (props: any) => {
		return (
			<td>
				<button
					className="k-button k-primary"
					onClick={() => props.enterEdit(props.dataItem)}
				>
					<FormattedMessage id={'general.edit'} />
				</button>
				<button
					className="k-button btn pl-2"
					onClick={() => props.enterDelete(props.dataItem)}
				>
					<FormattedMessage id={'general.delete'} />
				</button>
			</td>
		);
	};


	const customData: Array<any> = [{ color: "green" }, { color: "red" }];

	const enterEdit = (item: User) => {
		history.push({
			pathname: `/admin/users/form/${item.Id}`,
			state: {},
		});
	};

	const enterDelete = async (item: User) => {
		const options = {
			title: intl.formatMessage({
				id: 'general.delete',
			}),
			message: intl.formatMessage({
				id: 'general.deleteItemMessage',
			}),
			buttons: [
				{
					label: intl.formatMessage({
						id: generalYes,
					}),
					onClick: () => {
						handleClickDelete(item);
					}
				},
				{
					label: intl.formatMessage({
						id: generalNo,
					}),
					onClick: () => {  /*nothing here*/}
				}
			],
			childrenElement: () => <div />,
			// customUI: ({ onClose }) => <div>Custom UI</div>,
			closeOnEscape: true,
			closeOnClickOutside: true,
		willUnmount: () => { /*nothing here*/ },
			afterClose: () => { /*nothing here*/},
			onClickOutside: () => { /*nothing here*/},
			onKeypressEscape: () => { /*nothing here*/},
			overlayClassName: "overlay-custom-class-name"
		};
		confirmAlert(options);



	}

	const handleClickDelete = async (item: User) => {
		setLoading(true);
		try {
			await usersService.deleteUser(item.Id);
			getData();
			setIsDeleted(true);
			setTimeout(() => {
				setIsDeleted(false);
			}, 4000);


		} catch (err) {
			setIsError(true);
			setTimeout(() => {
				setIsError(false);
			}, 4000);
		}
		setLoading(false);
	}

	const customIsAdmin = (props: GridCellProps) => (
		<CustomBooleanCell {...props} myProp={customData} />
	);

	const customIsInternal = (props: GridCellProps) => (
		<CustomBooleanCell {...props} myProp={customData} />
	);

	const customInactive = (props: GridCellProps) => (
		<CustomBooleanCell {...props} myProp={customData} />
	);

	const MyCrudCommandCell = (props: any) => (
		<CrudCommandCell {...props} enterEdit={enterEdit} enterDelete={enterDelete} />
	);

	const BooleanFilterCell: any = (props: GridFilterCellProps) => (
		<DropdownFilterCell
			{...props}
			data={[
				{ text: "All", value: null },
				{
					text: intl.formatMessage({
						id: generalYes,
					}),
					value: true,
				},
				{
					text: intl.formatMessage({
						id: generalNo,
					}), value: false

				}]}
			defaultItem={"All"}
		/>
	);
	

	const pageChange = (event: GridPageChangeEvent) => {
		setPage(event.page);
	};

	const getGroups = async (admType: AdministratorType) => {
		let groupsResult: GroupView[] = [];


		if (admType.IsSuperUser) {
			groupsResult = (await projectsService.getGroups()).data;
		} else if (admType.IsAdminGroup) {
			groupsResult = (await projectsService.getAdminGroups()).data;
		}

		setGroups(groupsResult);
	}


	const GroupsFilterCell: any = (props: GridFilterCellProps) => (
		<DropdownFilterCell
			{...props}
			customOperator="contains"
			data={groups.map(group => {
				return {
					text: group.Name,
					value: group.Name
				}
			})}
			defaultItem={"All"}
		/>
	);

	const onFilterChange = (eventFilter: any) => {
    setPage(initialDataState);
    setFilter(eventFilter);
  };

	return (
		<React.Fragment>
			{isDeleted && <Alert variant="success">
				<FormattedMessage id={'general.successfullDeleted'} />
			</Alert>}
			
			{isError && <Alert variant="warning">
				<FormattedMessage id={'general.error'} />
			</Alert>}
			<div className="module-pjs">
				<div className="header-pj">
					<h2>
						<FormattedMessage id={'admin.users.title'} />
					</h2>
					<button className="btn btn-primary" onClick={addNewUser}>
						<FormattedMessage id={'admin.users.AddNew'} />
					</button>
				</div>
				<div className="content-body">
					{
						<Modal
							show={showModalGroups}
							backdrop="static"
							// visible={showModalGroups}
							width="600"
							minHeight="600"
							centered
							onClickAway={() => {
								setShowModalGroups(false);
							}}
						>
							<Modal.Header>
								<Modal.Title>
									{intl.formatMessage({
										id: adminMenuGroupsTitle,
									})} {currentUser && currentUser.Username}
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Grid
									sortable={false}
									filter={filter}
									groupable={false}
									reorderable={false}
									filterable={false}
									data={currentGroups}
									className="hidde-scroll-horizontal"
								>
								    <GridColumn field="Name" title={intl.formatMessage({
											id: 'admin.groups.grid.groupName.title',
										})}
                                     cell={(props) => {
										 return (<td>{`${props.dataItem.Name}`}
										 </td>)
									 }}
									/>
									<GridColumn field="AppName" title={
										intl.formatMessage({
											id: 'admin.project',
										})
									}
                                     cell={(props) => {
										 return (<td>{`${props.dataItem.AppName}`}
										 </td>)
									 }}
									/>
								</Grid>
								{/* <table className="table table-striped">
									{currentGroups && currentGroups.map((s: any) => {
										return <tr><td>{`${s.Name} : ${s.IsAdminGroup ? 'Admin' : 'User'}`}
										</td></tr>
									})
									}
								</table> */}

							</Modal.Body>

							<Modal.Footer>
								<button className="btn btn-groupUsers" onClick={(e) => {
									setShowModalGroups(false);
								}}>
									{intl.formatMessage({
										id: 'general.close',
									})}
								</button>
							</Modal.Footer>
						</Modal>
					}
					{users.length > 0 && <Grid

						sortable={true}
						filterable={true}
						resizable={true}
						filter={filter}
						onFilterChange={(e: GridFilterChangeEvent) => {
							onFilterChange(e.filter);
						}
						}
						groupable={false}
						reorderable={true}
						skip={page.skip}
						take={page.take}
						total={filterBy(users, filter).length}
						pageable={true}
						onPageChange={pageChange}
						data={filterBy(users, filter).slice(page.skip, page.take + page.skip)}
					>
						<GridNoRecords>
							<FormattedMessage
								id={'general.norecords'}
							/>
						</GridNoRecords>
						<GridColumn field="Name" width="250px"
							title={intl.formatMessage({
								id: 'admin.users.grid.name.title',
							})} />
						<GridColumn field="Username" width="250px"
							title={intl.formatMessage({
								id: 'admin.users.grid.userName.title',
							})} />
						{/* TODO */}
						<GridColumn field="GroupsString"
							title={intl.formatMessage({
								id: adminMenuGroupsTitle,
							})}
							width="200px"
							cell={(props: any) => {
								return (<td>
									{<button className="k-button btn-groupUsers" onClick={(e) => {
										setCurrentGroups(props.dataItem.Infra2_V_GroupsByUsers);
										setCurrentUser(props.dataItem);
										setShowModalGroups(true);
									}}
										disabled={props.dataItem.Infra2_V_GroupsByUsers.length === 0}
									>
										<span className="icon-group-users"></span>
										<FormattedMessage id={adminMenuGroupsTitle} />
									</button>}
									{/* <table>
										{props.dataItem.Infra2_V_GroupsByUsers.map((s: any) => {
											return <tr><td>{`${s.Name} : ${s.IsAdminGroup ? 'Admin' : 'User'}`}
											</td></tr>
										})
										}
									</table> */}

								</td>)
							}}
							filterCell={GroupsFilterCell}
							filter="text"
						/>
						{/* <GridColumn field="Role" width="160px"
							title={intl.formatMessage({
								id: 'admin.users.grid.role.title',
							})}
							filterCell={RolesFilterCell}
						/> */}
						{/* <GridColumn field="Sub" title="Sub" width="200px" /> */}
						<GridColumn field="IsInternal" cell={customIsInternal} width="120px"
							title={intl.formatMessage({
								id: 'admin.users.grid.isInternal.title',
							})}
							filterCell={BooleanFilterCell}
						/>
						{/* <GridColumn field="IsAdmin" title="Is Admin" width="200px" /> */}
						<GridColumn field="IsAdmin"
							title={intl.formatMessage({
								id: 'admin.users.grid.isAdmin.title',
							})}
							cell={customIsAdmin} width="80px"
							filterCell={BooleanFilterCell}
							filter="text"
						/>
						<GridColumn field="CreatedAt" width="200px"
							title={intl.formatMessage({
								id: 'admin.users.grid.createdAt.title',
							})} />
						<GridColumn field="Inactive"
							title={intl.formatMessage({
								id: 'admin.users.grid.inactive.title',
							})}
							cell={customInactive} width="80px"
							filterCell={BooleanFilterCell}
						/>
						<GridColumn cell={MyCrudCommandCell} width="150px"
							title={intl.formatMessage({
								id: 'general.actions',
							})}
							filterable={false} />
					</Grid>}
				</div>
			</div>
			{loading &&
				<svg className="pl" viewBox="0 0 200 200" width="200" height="200" xmlns="http://www.w3.org/2000/svg">
					<defs>
						<linearGradient id="pl-grad1" x1="1" y1="0.5" x2="0" y2="0.5">
							<stop offset="0%" stopColor="hsl(61,53%,45%)" />
							<stop offset="100%" stopColor="hsl(145,100%,23%)" />
						</linearGradient>
						<linearGradient id="pl-grad2" x1="0" y1="0" x2="0" y2="1">
							<stop offset="0%" stopColor="hsl(198,100%,45%)" />
							<stop offset="100%" stopColor="hsl(0,0%,37%)" />
						</linearGradient>
					</defs>
					<circle className="pl__ring" cx="100" cy="100" r="82" fill="none" stroke="url(#pl-grad1)" strokeWidth="36" strokeDasharray="0 257 1 257" strokeDashoffset="0.01" strokeLinecap="round" transform="rotate(-90,100,100)" />
					<line className="pl__ball" stroke="url(#pl-grad2)" x1="100" y1="18" x2="100.01" y2="182" strokeWidth="36" strokeDasharray="1 165" strokeLinecap="round" />
				</svg>
			}
		</React.Fragment>
	)

}

export default UsersAdmin;