import axios from 'axios';
import React from 'react';
import { useMsal } from './services/auth/authProvider';

 function RunnerProvider(loginRequest: any) {
  const { isAuthenticated, getToken, setLogedInF } = useMsal();

 
  React.useEffect(() =>{
    function fetchBusiness() { 
      async function getTokenP() {
        if (isAuthenticated && getToken !== undefined) {
          const x = await getToken(loginRequest.loginRequest);
          if (x !== undefined) {
            try {
              axios.interceptors.request.use(
                config => {
                  config.headers['Authorization'] = 'Bearer ' + x;
                  return config;
                },
                error => {
                  Promise.reject(error)
                });
              if (setLogedInF !== undefined) {
                setLogedInF(true);
              }
            } catch (ex) {
              console.log(ex);
            }
          }
        }
  
      }
      getTokenP();
    }
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
    </React.Fragment>
  );
}

export default RunnerProvider;