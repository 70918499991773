import { FormattedMessage } from "react-intl";
import { ProjectFormController } from "./projectForm.controller";
import { Tooltip } from '@progress/kendo-react-tooltip';
import ProjectFormContacts from "./projectForm.contacts";
import ProjectFormAppTeams from "./projectForm.appTeams";
import { defaultUser } from "./projectForm.consts";
import { Upload } from "@progress/kendo-react-upload";
import { ProjectFormHeader } from "./projectForm.header";
import { Link } from "react-router-dom";

export function ProjectFormView(cn: any) {
    const ctrl = ProjectFormController(cn);
return (
  <form
    noValidate
    className={cn.isBlocked ? 'form noClick' : 'form'}
    onSubmit={cn.handleSubmit(ctrl.onSubmit)}
  >
    <div className="row justify-content-center">
      <ProjectFormHeader {...cn} />
      <div className="d-none col-md-3 d-md-block"></div>
      <div className="col-12 col-md-6">
        <h3 className="core">
          <div>
            <FormattedMessage id={'admin.projects.form.contactInfo.title'} />
          </div>
          <div className="help-icon-area">
            <Tooltip anchorElement={cn.anchor} parentTitle={true}>
              <div>
                <svg
                  color="#4ba6de"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-color="lightgray"
                  data-icon="circle-info"
                  className="icon-help-infra svg-inline--fa fa-circle-info"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <title>
                    {cn.intl.formatMessage({
                      id: 'admin.projects.form.informative.contact.info',
                    })}
                  </title>
                  <path
                    fill="currentColor"
                    d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"
                  ></path>
                </svg>
              </div>
            </Tooltip>
          </div>
        </h3>
          <ProjectFormContacts
            appContacts={cn.appContacts}
            deletedTemAppContacts={cn.deletedTemAppContacts}
            setDeletedTempAppContacts={cn.setDeletedTempAppContacts}
            setAppContacts={cn.setAppContacts}
            currentContact={cn.currentContact}
            setCurrentContact={cn.setCurrentContact}
            contactInfoSubmited={cn.contactInfoSubmited}
            setContactInfoSubmited={cn.setContactInfoSubmited}
            errors={cn.errors}
            setLoading={cn.setLoading}
          />
        {cn.submited && cn.appContacts.length === 0 && (
          <span style={{ color: 'darkred' }}>
            <FormattedMessage id="admin.projects.form.appContact.required" />
          </span>
        )}
      </div>
      <div className="d-none col-md-3 d-md-block"></div>

      <div className="d-none col-md-3 d-md-block mt-5"></div>
      <div className="col-12 col-md-6 mt-5">
        <h3 className="core">
          <FormattedMessage id="general.coreTeam" />
          <div className="help-icon-area">
            <Tooltip anchorElement={cn.anchor} parentTitle={true}>
              <div>
                <svg
                  color="#4ba6de"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="circle-info"
                  className="icon-help-infra svg-inline--fa fa-circle-info"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <title>
                    {cn.intl.formatMessage({
                      id: 'admin.projects.form.informative.core.team',
                    })}
                  </title>
                  <path
                    fill="currentColor"
                    d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"
                  ></path>
                </svg>
              </div>
            </Tooltip>
          </div>
        </h3>
        <ProjectFormAppTeams
          appTeams={cn.appTeams}
          users={cn.users}
          selectedUser={cn.selectedUser}
          setSubmitedUser={cn.setSubmitedUser}
          setSelectedUser={cn.setSelectedUser}
          setLoading={cn.setLoading}
          setAppTeams={cn.setAppTeams}
          defaultUser={defaultUser}
          setExistsTeamUser={cn.setExistsTeamUser}
        />
        {cn.submitedUser &&
          (!cn.selectedUser.value ||
            (cn.selectedUser.value && cn.selectedUser.value.Id === 0)) && (
            <span style={{ color: 'darkred' }}>
              <FormattedMessage id="admin.groups.form.user.required" />
            </span>
          )}
      </div>
      <div className="d-none col-md-3 d-md-block mt-5"></div>
      <div className="d-none col-md-2 d-md-block"></div>
      <div className="col-12 col-md-8 fileUp">
        <h3>
          <FormattedMessage id="general.main.image" />
          <span style={{ color: 'darkred' }}>*</span>
          <div className="help-icon-area">
            <Tooltip anchorElement={cn.anchor} parentTitle={true}>
              <div>
                <svg
                  color="#4ba6de"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="circle-info"
                  className="icon-help-infra svg-inline--fa fa-circle-info"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <title>
                    {cn.intl.formatMessage({
                      id: 'admin.projects.form.informative.main.image',
                    })}
                  </title>
                  <path
                    fill="currentColor"
                    d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"
                  ></path>
                </svg>
              </div>
            </Tooltip>
          </div>
        </h3>
        <div className="upload wrapper-flow-hidden">
          {cn.showUpload && (
            <Upload
              autoUpload={false}
              batch={true}
              restrictions={{
                allowedExtensions: cn.avaliableMainExtension,
                maxFileSize: cn.maxFilePermitted,
              }}
              multiple={false}
              showActionButtons={false}
              defaultFiles={[]}
              withCredentials={false}
              files={cn.filesMain}
              onAdd={ctrl.onAddMain}
              onRemove={ctrl.onRemoveMain}
              onProgress={ctrl.onProgressMain}
              listItemUI={(e: any) => ctrl.CustomListItemUI(e, 1)}
              ariaDescribedBy="Subir imagen"
            />
          )}
        </div>
        {cn.fileSizeExceded && (
          <span
            style={{ color: 'darkred', paddingTop: '5em', display: 'inherit' }}
          >
            <FormattedMessage id="admin.projects.form.mainPicture.exceded" />
          </span>
        )}
        {cn.fileTypeInvalid && (
          <span
            style={{ color: 'darkred', paddingTop: '5em', display: 'inherit' }}
          >
            <FormattedMessage id="admin.projects.form.mainPicture.typeInvalid" />
          </span>
        )}
        {!cn.isNewMainImage &&
          cn.currentProject.Id &&
          cn.currentProject.Thumbnail && (
            <div className="row-box-addeds">
              <span className="bold-title">
                <FormattedMessage id="general.actual.main.picture" />
                &nbsp;&nbsp;
              </span>
              <img
                className="imagePreview"
                alt="actualProfileImg"
                src={process.env.REACT_APP_CLOUD_FRONT +"/"+ cn.currentProject.Thumbnail}
              />
            </div>
          )}
        {cn.mainPictureError && (
          <span style={{ color: 'darkred' }}>
            <FormattedMessage id="admin.projects.form.mainPicture.required" />
          </span>
        )}
      </div>
      <div className="d-none col-md-2 d-md-block"></div>
      <div className="col-12 col-md-8 fileUp fileUpTwo">
        <h3>
          <FormattedMessage id="general.screenshots" />
          <div className="help-icon-area">
            <Tooltip anchorElement={cn.anchor} parentTitle={true}>
              <div>
                <svg
                  color="#4ba6de"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="circle-info"
                  className="icon-help-infra svg-inline--fa fa-circle-info"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <title>
                    {cn.intl.formatMessage({
                      id: 'admin.projects.form.informative.screenshots',
                    })}
                  </title>
                  <path
                    fill="currentColor"
                    d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"
                  ></path>
                </svg>
              </div>
            </Tooltip>
          </div>
        </h3>
        <div className="upload wrapper-flow-hidden">
          {cn.showUploadS && (
            <Upload
              autoUpload={false}
              batch={true}
              restrictions={{
                allowedExtensions: cn.avaliableScreenshotExtension,
                maxFileSize: cn.maxFilePermitted,
              }}
              multiple={true}
              showActionButtons={false}
              defaultFiles={[]}
              withCredentials={false}
              files={cn.files}
              onAdd={ctrl.onAdd}
              onRemove={ctrl.onRemove}
              onProgress={ctrl.onProgress}
              listItemUI={(e: any) => ctrl.CustomListItemUI(e, 2)}
            />
          )}
        </div>
        {cn.screenshotfileSizeExceded && (
          <span
            style={{
              color: 'darkred',
              paddingTop: '5em',
              display: 'inherit',
            }}
          >
            <FormattedMessage id="admin.projects.form.screenshots.exceded" />
          </span>
        )}
        {cn.fileTypeSInvalid && (
          <span
            style={{ color: 'darkred', paddingTop: '5em', display: 'inherit' }}
          >
            <FormattedMessage id="admin.projects.form.screenshots.typeInvalid" />
          </span>
        )}
        {(!cn.isNewScreenshotsImage) &&
          cn.currentProject.Id &&
          cn.currentProject.Screenshots !== 'null' &&
          cn.currentProject.Screenshots &&
          cn.noReloadingScreenshots &&
          JSON.parse(cn.currentProject.Screenshots).map !== undefined && (
            <div className="row-box-addeds">
              {JSON.parse(cn.currentProject.Screenshots).length !== undefined &&
                JSON.parse(cn.currentProject.Screenshots).length > 0 && (
                  <span className="bold-title">
                    <FormattedMessage id="general.actual.screenshots" />
                    &nbsp;&nbsp;
                  </span>
                )}
              {JSON.parse(cn.currentProject.Screenshots).map(
              
                (screenshot: string, index: number) => {
                  return (
                    <div>
                      <ul className="k-upload-files k-reset">
                        <li
                          className="k-file"
                          data-uid="fed74e2d-8f7c-4f0e-826b-1c1ba4f80b36"
                        >
                          <ul className="ul-no-decoration">
                            <li>
                              <img
                                src={
                                  process.env.REACT_APP_CLOUD_FRONT +
                                  '/' +
                                  screenshot
                                }
                                alt="filePreview"
                                style={{ width: '70px', height: '70px' }}
                              />
                              <strong className="k-upload-status">
                                <button
                                  type="button"
                                  className="k-button k-button-icon k-flat k-upload-action"
                                  onClick={() => {
                                    ctrl.removeActualScreenshotByUrl(
                                      screenshot
                                    );
                                  }}
                                >
                                  <span
                                    aria-label="Remove"
                                    title="Remove"
                                    className="k-icon k-delete k-i-x"
                                  ></span>
                                </button>
                              </strong>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  );
                }
              )}
            </div>
          )}
      </div>
    </div>
    <div className="row mt-5">
      <div className="col-12 col-md-6">
        <div className="d-flex">
          <FormattedMessage id="general.isTheApplicationActive.question" />
          <label className="radio-btn">
            <FormattedMessage id="general.yes" />
            <input
              type="radio"
              {...cn.register('isActive')}
              id="isActive"
              onChange={(e) => {
                cn.setValue('isActive', true);
                cn.setIsActiveValue(e.target.value);
              }}
              checked={cn.isActiveValue === 'true'}
              value="true"
            />
            <span className="checkmark"></span>
          </label>
          <label className="radio-btn">
            <FormattedMessage id="general.no" />
            <input
              type="radio"
              {...cn.register('isActive')}
              id="isActive"
              onChange={(e) => {
                cn.setValue('isActive', true);
                cn.setIsActiveValue(e.target.value);
              }}
              checked={cn.isActiveValue === 'false'}
              value="false"
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div className="d-flex">
          <FormattedMessage id="admin.projects.form.status.title" />
          <label className="radio-btn">
            <FormattedMessage id="admin.projects.form.status.process.title" />
            <input
              type="radio"
              {...cn.register('finished')}
              id="finished"
              onChange={(e) => {
                cn.setValue('finished', false);
                cn.setIsFinishedValue(e.target.value);
              }}
              checked={cn.isFinishedValue === 'false'}
              value="false"
            />
            <span className="checkmark"></span>
          </label>
          <label className="radio-btn">
            <FormattedMessage id="admin.projects.form.status.finished.title" />
            <input
              type="radio"
              {...cn.register('finished')}
              id="finished"
              onChange={(e) => {
                cn.setValue('finished', true);
                cn.setIsFinishedValue(e.target.value);
              }}
              checked={cn.isFinishedValue === 'true'}
              value="true"
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>

      <div className="col-12 col-md-8">
        <button
          type="submit"
          className="newPj"
          onClick={(e) => {
            cn.setSubmited(true);
          }}
        >
          <FormattedMessage id={'admin.users.form.save'} />
        </button>
        <button className="newPj ms-2">
          <Link to={{ pathname: '/admin/projects' }}>
            <FormattedMessage id={'admin.users.form.cancel'} />
          </Link>
        </button>
      </div>
    </div>
  </form>
);
    
}