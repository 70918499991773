import React from "react";
import { useMsal } from "../../services/auth/authProvider";

const Logout: React.FC = ()=>{
    const { logout } = useMsal();
    if(logout){
        localStorage.setItem("isSuperUser","");
        localStorage.setItem("isAdminGroup","");
        localStorage.clear();
        logout();
    }
    return(
        <div>
        </div>
    )
}

export default Logout;