import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

const DatasetShowNewKey: React.FC = () => {

  const history = useHistory();
  const paramsId: any = useParams<{}>();
  const key: number = paramsId['key'];

  const returnButton = () => {
    history.push({
      pathname: `/admin/datasets`,
      state: {},
    });
  };

  const copyInputText = () => {
    let copy:any = document.getElementById('copyInput');
    copy.select();
    navigator.clipboard.writeText(copy.value);
  }

  return (
    <main id="login">
      <div className="row">
        <div className="pad-0 log  p-relative d-flex justify-content-center">
          <div className='data_title text-center'>
            <h1>Tener en cuenta que este hash es usado para descargar el Dataset</h1>
          </div>
        </div>

        <div className='d-flex justify-content-center'>
          <div className='input_container text-center'>
            <div className='data_input'>
              <input className='field' type="text" value={key} id='copyInput' />
              <div className='tooltip'>
                <button className="k-button data_copy_button" onClick={ () => copyInputText() }>
                  <span className="tooltiptext" id="myTooltip">Copy to clipboard</span>
                  <em className="far fa-copy"></em></button>
              </div>
            </div>
          </div>
        </div>
          <div className='m-4'>
            <button
              className="newPj"
              onClick={() => returnButton()}>
              <FormattedMessage id={'admin.datasets.key.return'} />
            </button>
          </div>
      </div>
    </main>
  );
};

export default DatasetShowNewKey;
