import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import AdministratorType from "../../models/administratorType.model";

export function evalAdmin(props: any) {
    if (props.user) {
      let userName = props.usersService.getUsername(props.user);
      props.usersService.getIsAdminbyUsername(userName).then((result: AdministratorType) => {
        props.setIsAdmin(result.IsSuperUser || result.IsAdminGroup);
        props.setIsSuperUser(result.IsSuperUser);
        props.setLoading(false);
      });

    }
  }

export const NavItemAboutUs = (props: any) => {
  return (
    props.isUserInternalHd && (
      <li className={"nav-item aboutUs"}>
        <span
          onClick={() => {
            const categoryActivedAboutUs =
              props.categoryActive !== "aboutUs" ? "aboutUs" : "";
            props.onFilterCategoryByMobile(categoryActivedAboutUs);
            props.setCategoryActive(categoryActivedAboutUs);
            props.setShowed(false);
          }}
          className="no-decorations cursor-pointer"
        >
          <NavLink to="/about" className="a-aboutUs">
            <span className="nav-link flex-center">
              <FormattedMessage id={"category.aboutUs"} />
            </span>
          </NavLink>
        </span>
      </li>
    )
  );
};

export const NavItemEnergy = (props: any) => {
    return (
        props.isUserInternalHd && (
            <li className={'nav-item energy'}>
                <span
                    onClick={() => {
                        const categoryActivedEnergy =
                            props.categoryActive !== 'energy' ? 'energy' : '';
                        props.onFilterCategoryByMobile(categoryActivedEnergy);
                        props.setCategoryActive(categoryActivedEnergy);
                        props.setShowed(false);
                    }}
                    className="no-decorations cursor-pointer"
                >
                    <span className="nav-link flex-center">
                        <FormattedMessage id={'category.energy'} />
                    </span>
                </span>
            </li>
        )
    );
}

export const NavItemTransport = (props: any) => {
    return (
        props.isUserInternalHd && (
        <li className={'nav-item transport'}>
          <span
            onClick={() => {
              const categoryActivedTransport =
              props.categoryActive !== 'transport' ? 'transport' : '';
              props.onFilterCategoryByMobile(categoryActivedTransport);
              props.setCategoryActive(categoryActivedTransport);
              props.setShowed(false);
            }}
            className="no-decorations cursor-pointer"
          >
            <span className="nav-link flex-center">
              <FormattedMessage id={'category.transport'} />
            </span>
          </span>
        </li>
      )
    );
  }

  export const NavItemWater = (props: any) => {
    return (
        props.isUserInternalHd && (
        <li className={'nav-item water'}>
          <span
            onClick={() => {
              const categoryActivedWater =
              props.categoryActive !== 'water' ? 'water' : '';
              props.onFilterCategoryByMobile(categoryActivedWater);
              props.setCategoryActive(categoryActivedWater);
              props.setShowed(false);
            }}
            className="no-decorations cursor-pointer"
          >
            <span className="nav-link flex-center">
              <FormattedMessage id={'category.water.and.sanitation'} />
            </span>
          </span>
        </li>
      )
    );
  }
  
  export const NavItemTools = (props: any) => {
    return (props.isUserInternalHd && (
      <li className={'nav-item tools'}>
        <span
          onClick={() => {
            const categoryActivedTools =
            props.categoryActive !== 'tools' ? 'tools' : '';
            props.onFilterCategoryByMobile(categoryActivedTools);
            props.setCategoryActive(categoryActivedTools);
            props.setShowed(false);
          }}
          className="no-decorations cursor-pointer"
        >
          <span className="nav-link flex-center">
            <FormattedMessage id={'category.tools'} />
          </span>
        </span>
      </li>
    )
    )
  }