import { isMobile } from 'react-device-detect';

const General = {
    API_MANAGMENTFILE_URL: process.env.REACT_APP_API_MANAGMENTFILE_URL,
    AUTHORIZATION: '',
    REQUEST_CONFIG: {
        headers: {
            Authorization: ''
        }
    }
}
const getImageByCategory = (category: string): string => {
    let value = '';
    switch (category) {
      case 'energy':
        value = `./images/default-categories/GN-energy${
          isMobile ? '' : '-2x'
        }.jpg`;
        break;
      case 'transport':
        value = `./images/default-categories/GN-transport${
          isMobile ? '' : '-2x'
        }.jpg`;
        break;
      case 'water':
        value = `./images/default-categories/GN-water${
          isMobile ? '' : '-2x'
        }.jpg`;
        break;
      case 'tools':
        value = `./images/default-categories/GN-tools${
          isMobile ? '' : '-2x'
        }.jpg`;
        break;
    }
    return value;
};
export {
    General,
    getImageByCategory
}
