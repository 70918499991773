import React, { useContext, useState, useEffect} from "react";

import {
    AccountInfo,
    PublicClientApplication,
    RedirectRequest,
    SilentRequest,
    InteractionRequiredAuthError,
    AuthenticationResult,
  } from "@azure/msal-browser";
import { UsersService } from "../users/users.service";

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: process.env.REACT_APP_REDIRECT_URL,
        navigateToLoginRequestUrl: false,
        knownAuthorities: [process.env.REACT_APP_AUTHORITY]
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["openid", "profile"],
    forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};
  
  // Add here scopes for id token to be used at MS Identity Platform endpoints.
export const apiRequest = {
    scopes: [process.env.REACT_APP_SCOPE],
    forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};


type ContextProps = {
    isAuthenticated?: boolean,
    isInternalUser?: boolean,
    user?: AccountInfo,
    token?: string,
    loading?: boolean,
    loginError?: boolean,
    login?: (loginRequest: RedirectRequest)=>void,
    logout?: ()=>void,
    getToken?: (loginRequest: SilentRequest)=>Promise<string|undefined>,
    isLogedIn?: boolean,
    setLogedInF?: (isLogedIn:boolean) => void,
    userInternal?: () => boolean,
    getIsInternal?: () => boolean
}

export const MsalContext = React.createContext<ContextProps>({});
export const useMsal = () => useContext(MsalContext);
export const MsalProvider = ({ children, config }: any) => {
  const usersService = UsersService({ locale: 'en' });
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<AccountInfo>();
  const [token, setToken] = useState<string>();
  const [isLogedIn, setLogedIn] = useState<boolean>();
  const [publicClient, setPublicClient] = useState<PublicClientApplication>();
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [isInternalUser,setIsInternalUser] = useState(false);

  const userInternal = () =>    {
    let isInternal: boolean = false;
    if (user) {
      const userName = usersService.getUsername(user);

      const domainInternal = userName.split('@');
      if (domainInternal.length > 1
        && domainInternal[1].toLocaleLowerCase() === 'iadb.org') {
        isInternal = true;
        setIsInternalUser(true);
      } else {
        setIsInternalUser(false);
      }
    }
    return isInternal;
  }
  
 

  useEffect(()=>{
    function fetchBusiness(){ 
      const pc: PublicClientApplication = new PublicClientApplication(config);
      setPublicClient(pc);
  
      pc.handleRedirectPromise()
        .then((response: AuthenticationResult | null) => {
          setLoading(false);
          if(response!==null){            
              setUser(response.account?response.account:undefined);
              userInternal();
              setIsAuthenticated(true);
          }
          else{
              const accounts = pc.getAllAccounts();            
              if(accounts === null){
                console.log("No accounts detected.");
                return null;
              }
              if(accounts.length>0){              
                setUser(accounts[0]);
                userInternal();
                setIsAuthenticated(true);
              }
          }        
        })
        .catch((error: any) => {
          console.log(error);
          setLoginError(error);
        });
  
      if (pc.getAllAccounts()) {
        
        const accounts = pc.getAllAccounts();
        if (accounts.length > 0) {
          setUser(accounts[0]);
          userInternal();
          setIsAuthenticated(true);
        }
      }
    }
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (loginRequestD: RedirectRequest) => {
    const pc = publicClient;
    if (pc) {
      setLoading(true);
      pc.loginRedirect(loginRequestD);
    }
  };


  const logout = () => {
    const pc = publicClient;
    if(pc){
      pc.logout();  
    }
  };



  const getIsInternal = () => {
    return userInternal();
  };

  const getTokenRedirect = async (loginRequestR: SilentRequest):Promise<string|undefined> => {
    // having an account is a requirement for silent token acquisition.
    loginRequestR.account = user;
    const pc = publicClient;
    try {
        if(pc){
            const tokenR = await pc.acquireTokenSilent(loginRequestR);
            setToken(tokenR.idToken);
            return tokenR.idToken;
        }
      
    } catch (error: any) {
        console.log("Silent token acquisition failed.");
        localStorage.clear();
        if (error instanceof InteractionRequiredAuthError){
            console.log("aquiring token using redirect");
            setLoading(true);
            if(pc)
                await pc.acquireTokenRedirect(loginRequest).catch(console.error);
        }
        else{
            console.error(error);
            setLoginError(error);
        }
    }
  };

  const getToken = async (loginRequestV: SilentRequest):Promise<string|undefined> => {
    const tokenResult = await getTokenRedirect(loginRequestV);    
    setLogedIn(true);
    return tokenResult;
  };

  return (
    <MsalContext.Provider
      value={{
        isAuthenticated,
        isInternalUser,
        user,
        token,
        loading,
        loginError,
        login,
        logout,
        getToken,
        isLogedIn,
        userInternal,
        getIsInternal
      }}
    >
      {children}
    </MsalContext.Provider>
  );
};
