/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import { Dropdown, NavItem, NavLink } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

const ProfileToggle = (props: any) =>  {
  const location = useLocation();
  const closeSession = () => {
    window.location.href = '/logout';
  };

  const goToAdmin = () => {
    if(props.isSuperUser) {
    window.location.href = '/admin';
  }
   else  {
    window.location.href = '/admin/projects';

   }
  };
  useEffect(() => {
    // do something here
  }, [location]);
  return (
    <div>
      <Dropdown as={NavItem}>
        <Dropdown.Toggle as={NavLink}>{props.username}</Dropdown.Toggle>
        <Dropdown.Menu>
          {(props.showAdmin && (props.isAdmin || props.isSuperUser)) && (
            <Dropdown.Item onClick={() => goToAdmin()}>
              <span className="adminSession">
                <span className="far fa-user"></span>
              </span>
              <FormattedMessage id={'general.admin.title'} />
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={() => closeSession()}>
            <a
              className="closeSession"
              style={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'gray',
              }}
            >
              <span className="icon-exit"></span>
              <FormattedMessage id={'general.exit.title'} />
            </a>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );}


export default ProfileToggle;