import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AppContact } from "../../../models/appContact";
import { ProjectFormController } from "./projectForm.controller";

export default function ProjectFormContacts(props: any) {
    const intl = useIntl();
    const ctrl = ProjectFormController({
        setLoading: props.setLoading,
        currentContact: props.currentContact,
        appContacts: props.appContacts,
        setAppContacts: props.setAppContacts,
        setCurrentContact: props.setCurrentContact,
        setContactInfoSubmited: props.setContactInfoSubmited
    });
    const [nameContact, setNameContact] = useState<string>("");
    const [emailContact, setEmailContact] = useState<string>("");
    const [refreshCurrentContact, setRefreshCurrentContact] = useState<boolean>(false);

    useEffect(() => {
      if (props.currentContact.NameContact) {
      setNameContact(props.currentContact.NameContact);
      }
      if (props.currentContact.EmailContact) {
        setEmailContact(props.currentContact.EmailContact);
      }
    }, [])

    return (
      <table>
        <thead>
          <tr>
            <th>
              <FormattedMessage id={'general.nameContact'} />
            </th>
            <th>
              <FormattedMessage id={'general.emailContact'} />
            </th>
            <th>
              <FormattedMessage id={'admin.common.grid.actions.title'} />
            </th>
          </tr>
        </thead>
        <tbody>
          {props.appContacts.map((ac: AppContact, index: number) => {
            return (
              <React.Fragment key={index}>
                <tr>
                  <td>
                    <span>{ac.NameContact}</span>
                  </td>
                  <td>
                    <span>{ac.Emailcontact}</span>
                  </td>
                  <td>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        const appCo = props.appContacts.filter(
                          (s: any) => s.newId !== ac.newId
                        );
                        props.deletedTemAppContacts.push(ac);
                        props.setDeletedTempAppContacts(
                          props.deletedTemAppContacts
                        );
                        props.setAppContacts(appCo);
                      }}
                    >
                      <span className="icon-trash"></span>
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
          <tr>
            <td>
              {(!refreshCurrentContact) &&
                <input
                  id="nameContact"
                  type="text"
                  className="fields"
                  placeholder={intl.formatMessage({
                    id: 'general.nameContact',
                  })}
                  onChange={(e) => {
                    props.currentContact.NameContact = e.target.value;
                    props.setCurrentContact(props.currentContact);
                  }}
                  defaultValue={nameContact}
                ></input>
              }
              {props.contactInfoSubmited &&
                props.currentContact.NameContact === '' && (
                  <span style={{ color: 'darkred' }}>
                    <FormattedMessage id="admin.projects.form.appContact.name.required" />
                  </span>
                )}
            </td>
            <td>
              {(!refreshCurrentContact) &&
                <input
                  id="emailContact"
                  type="text"
                  className="fields"
                  placeholder={intl.formatMessage({
                    id: 'general.emailContact',
                  })}
                  onChange={(e) => {
                    props.currentContact.Emailcontact = e.target.value;
                    props.setCurrentContact(props.currentContact);
                  }}
                  defaultValue={emailContact}
                ></input>
              }
              {props.contactInfoSubmited &&
                props.currentContact.Emailcontact === '' && (
                  <span style={{ color: 'darkred' }}>
                    <FormattedMessage id="admin.projects.form.appContact.email.required" />
                  </span>
                )}
              {props.contactInfoSubmited && props.errors.emailContact && (
                <span style={{ color: 'darkred' }}>
                  {props.errors.emailContact}
                </span>
              )}
            </td>
            <td>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  props.setContactInfoSubmited(true);
                  ctrl.addContact().then(() => {
                    setNameContact('');
                    setEmailContact('');
                    setRefreshCurrentContact(true);
                    setTimeout(() => {
                      setRefreshCurrentContact(false);
                    }, 200);
                  });
                }}
              >
                <span className="icon-add-user"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    );
}