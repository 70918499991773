import React, { useContext, useState } from 'react';
import { IntlProvider } from 'react-intl';
import English from '../../lang/en.json';
import Spanish from '../../lang/es.json';
import Portugues from '../../lang/pt.json';

type ContextProps = {
  locale?: string;
  languageName?: string;
  selectLanguage?: (e:any)=>void;
}

const Context = React.createContext<ContextProps>({});
export const useWraperContext = () => useContext(Context);

let local = navigator.language;
const localStorageLang = localStorage.getItem('lang');
if(localStorageLang){
  local = localStorageLang;  
}
let lang = Spanish;
let languageN = 'Spanish';
if (local === 'en') {
  lang = English;
  languageN = 'English';
} else {
  if (local === 'pt') {
    lang = Portugues;
    languageN ='Portuguese';
  } else {
    lang = Spanish;
    languageN = 'Spanish';
  }
}

const Wrapper = ({ children, config }: any) => {
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);
  const [languageName, setLanguageName] = useState(languageN);
  function selectLanguage(lang: string) {
    const newLocale = lang;
    setLocale(newLocale);
    if (newLocale === 'en') {
      setMessages(English);
      setLanguageName('English');
    } else {
      if (newLocale === 'es') {
        setMessages(Spanish);
        setLanguageName('Spanish');
      } else {
        setMessages(Portugues);
        setLanguageName('Portuguese');
      }
    }
  }
  return (
    <IntlProvider 
    messages={messages} 
    locale={locale}>
    <Context.Provider 
         value={{ locale, 
          languageName,
         selectLanguage }}>
        {children}
    </Context.Provider>
    </IntlProvider>

  );
};
export default Wrapper;
