import React, {  useEffect, useState } from 'react';
import Slider from "react-slick";
import { Link, useParams } from 'react-router-dom';
import { getImageByCategory } from '../../services/general';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { UserAppsService } from '../../services/users/userApps.service';
import { Project } from '../../models/project.model';
import moment from 'moment';
import { useWraperContext } from '../complements/Wrapper';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useMsal } from '../../services/auth/authProvider';
import { ProjectsService } from '../../services/projects/projects.service';

function Related() {
  let paramsId: any = {};
  const { userInternal, user } = useMsal();
  const { locale } = useWraperContext();
  const [cardsProjectsData, setCardsProjectsData] = useState<Project>(new Project());
  const [carData, setCarData] = useState<Project[]>(new Array<Project>());
  
  const userAppsService = new UserAppsService({ locale: locale });
  const appsService = new ProjectsService({
    locale: locale
  });
  const settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
          arrows: false
        }
      }
    ]
  };

  paramsId = useParams<{}>();

  useEffect(() => {
    function fetchBusiness() {
      getData();
    }

    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = async () => {
    const slug = paramsId['slug'];
    let projects: Project[] = [];
    if (userInternal && userInternal()) {
      projects = await appsService.getAllItems(user?user:undefined)
    } else {
      projects = await userAppsService.getUserAppsMemory();
    }
    if (projects) {
      const currentProject = projects.filter(s => s.Slug === slug);
      if (currentProject.length > 0) {
        let filterProject = projects.filter(s => s.Category === currentProject[0].Category 
                  && s.Slug !== currentProject[0].Slug && s.IsActive);
        if (filterProject.length > 0) {

           filterProject.forEach((item: Project) =>{
            if (item && item.Description) {
              var description: any = undefined;
              if (item.Description.indexOf("\"En\":") !== -1 && item.Description.indexOf("\"Es\":") !== -1
                && item.Description.indexOf("\"Pt\":") !== -1) {
                description = JSON.parse(item.Description);
  
                switch (locale) {
                  case "en-US":
                    case "en":
                      description = description.En;
                      break;
                    case "es-ES":
                    case 'es-US':
                    case "es":
                      description = description.Es;
                      break;
                    case "pt-PT":
                    case "pt":
                      description = description.Pt;
                      break;
                }
              } else {
                description = item.Description;
              }
    
              item.Description = description;
            }
           });
          
          setCarData([]);
          setCarData(filterProject);
          setCardsProjectsData(currentProject[0]);
        }
      }
    }

  }

  const loadSlider = carData.map((data: Project, index: number) => {
    let image = null;
    if (data.Category) {
      image = data.Thumbnail
        ? data.Thumbnail
        : getImageByCategory(data.Category);
    }
    return (
      <div key={index}>
        <article className={data.Category}>
          <img
            src={
              process.env.REACT_APP_CLOUD_FRONT + '/' + image
            }
            alt=""
          />
          <div className="contenido">
            <h3 className="title">{data.Name}</h3>
            <p>
              <span>
                {data.LaunchDate !== null && (
                  <FormattedDate
                    value={
                      new Date(moment(data.LaunchDate).format('MMMM DD, yyyy'))
                    }
                    year="numeric"
                    month="long"
                    day="2-digit"
                  />
                )}
              </span>
            </p>
            {data.Description !== null && <p>{data.Description}</p>}
            <div className="lnk-btn">
              {/* TODO:  */}
              {
                //data.infReadMore &&} (
                <button key={index}>
                  <Link
                    onClick={() => {
                      paramsId['slug'] = data.Slug;
                      setTimeout(() => {
                        window.location.reload();
                      }, 200);
                    }}
                    to={
                      `/info/${data.Slug}`

                      // search: "?sort=name",
                      // hash: "#the-hash"
                      // state: { fromDashboard: true }
                    }
                  >
                    <FormattedMessage id={'home.button.readmore'} />
                  </Link>
                </button>
                //)
              }
              {data.Url && data.ShowEnter && (
                <button className="enter">
                  <Link
                    to={{
                      pathname: data.Url,
                      // search: "?sort=name",
                      // hash: "#the-hash"
                      // state: { fromDashboard: true }
                    }}
                  >
                    <FormattedMessage id={'home.button.enter'} />
                  </Link>
                </button>
              )}
            </div>
          </div>
        </article>
      </div>
    );
  });

  return (
    <section id="related" className={cardsProjectsData.Category}>
      <div className="iner">
        {(carData.length > 0) &&
        <h3 className={'rela ' + cardsProjectsData.Category}>
          <FormattedMessage id={'app.detail.related.applications'} />
        </h3>}
        <Slider {...settings}>{loadSlider}</Slider>
      </div>
    </section>
  );
}

export default Related;