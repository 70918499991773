export const filterOperators = {
  "text": [
    {
      "text": "grid.filterContainsOperator",
      "operator": "contains",
      "showOperators":"false"
    }
  ],
  "numeric": [
    {
      "text": "grid.filterEqOperator",
      "operator": "eq",
      "showOperators":"false"
    }
  ],
  "date": [
    {
      "text": "grid.filterEqOperator",
      "operator": "eq",
      "showOperators":"false"
    }
  ],
  "boolean": [
    {
      "text": "grid.filterEqOperator",
      "operator": "eq",
      "showOperators":"false"
    }
  ]
};