import LanguageToggle from "../../complements/tools/Language-toggle";

import "../../../stylesheets/admin/header/_header.admin.scss";
import ProfileToggle from '../../complements/tools/Profile-toggle';
import { UsersService } from '../../../services/users/users.service';
import AdministratorType from '../../../models/administratorType.model';
import { useMsal } from '../../../services/auth/authProvider';
import { useEffect, useState } from 'react';
import { locale } from 'moment';

function HeaderAdmin(_props: any) {
  const [isSuperUser, setIsSuperUser] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('user');
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const usersService = UsersService({ locale: locale });
  const { user } = useMsal();


  function evalAdmin() {
    if (user) {
      const userName = usersService.getUsername(user);
      usersService
        .getIsAdminbyUsername(userName)
        .then((result: AdministratorType) => {
          setIsAdmin(result.IsSuperUser || result.IsAdminGroup);
          setIsSuperUser(result.IsSuperUser);
        });
    }
  }


  useEffect(() => {

    function fetchBusiness() {
      if (user) {
        const userName = usersService.getUsername(user);

        setUsername(userName);
      }
      evalAdmin();
    }
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header id="nav" className="navbar navbar-expand-md navbar-light">
      <div className="container">
        <div className="col-8"></div>
        <div className="col-4">
          <button
            className="navbar-toggler"
            type="button"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="d-flex justify-content-end">
            {username && (
              <ProfileToggle
                username={username}
                showAdmin={false}
                isAdmin={isAdmin}
                isSuperUser={isSuperUser}
              ></ProfileToggle>
            )}
            <LanguageToggle></LanguageToggle>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderAdmin;