import axios, { AxiosResponse } from "axios";
import { Project } from "../../models/project.model";
import ProjectFilter from "../../models/filters/project.filter";
import { Pagination } from "../../models/pagination.model";
import { Group } from "../../models/group.model";
import { GroupView } from "../../models/groupView.model";
import { UserGroupView } from "../../models/userGroupView.model";
import { ProjectProps } from '../../models/complements/props/projectProps.model';
import { UserAppsService } from "../users/userApps.service";
import { AccountInfo } from "@azure/msal-common";
import { User } from "../../models/user.model";

export class ProjectsService {
  private locale: string = 'en';
  private userAppsService: UserAppsService = new UserAppsService({ locale: 'en' });

  constructor(props: ProjectProps) {
    if (props.locale) {
      this.locale = props.locale;
    }
  }

  public async getProjectsPaged(page: number = 1, pageSize: number = 5, projects: Project[]): Promise<[Pagination, Project[]]> {
    let pagedProjects = new Array<Project>();
    if (projects && projects.length > 0) {
      pagedProjects = projects.slice((page - 1) * pageSize, page * pageSize);
    }
    let pagination: Pagination = {
      totalElements: projects.length,
      totalPages: Math.ceil(projects.length / pageSize),
      pageNumber: page,
      pageSize: pageSize
    };
    return [pagination, pagedProjects];
  }



  public async getAppById(id: number): Promise<Project> {
    const result = await axios.get(`${process.env.REACT_APP_API}/apps/getAppById?id=${id}`);
    var project = new Project();
    if (result.data) {
      project = result.data;
    }
    return project;
  }

  private getUsername = (user: AccountInfo): string => {
    let userName = '';
    if (user) {
      if (user.username) {
        userName = user.username;
      } else if (user.idTokenClaims) {
        const idTokenClaims = user.idTokenClaims as any;
        if (idTokenClaims.email) {
          userName = idTokenClaims.email.toLowerCase();
        }
      }
    }
    return userName;
  }



  public async getAllItems(user?: AccountInfo): Promise<Project[]> {

    const promise: Promise<Project[]> = new Promise<Project[]>((resolve, reject) => {
      axios.get<Project[], AxiosResponse<Project[]>>(`${process.env.REACT_APP_API}/apps/getApps`).then(
        async response => {

          let userApps: Project[] = [];
          try {
            if (user) {
              console.log('user Info: all Items', user);
              const userExists = await axios.post<User, AxiosResponse<User>>(`${process.env.REACT_APP_API}/users/getUsersByUsername?username=${this.getUsername(user)}`
              );

              if (userExists && userExists.data) {
                userApps = await this.userAppsService.getUserApps();
              }
            } else {
              userApps = await this.userAppsService.getUserApps();
            }
          }
          catch (ex) {
            userApps = [];
          }
          if (response.data) {
            response.data.forEach(project => {
              project.ShowEnter = false;
              if (userApps) {
                if (userApps.filter(s => s.Name && project.Name && s.Name.toLowerCase() === project.Name.toLowerCase()).length > 0) {
                  project.ShowEnter = true;
                }
              }
              switch (this.locale) {
                case 'es':
                  if (project.Translations && project.Translations.Es) {
                    project.Name = project.Translations.Es.Name;
                    project.Description = project.Translations.Es.Description
                  }
                  break;
                case 'pt':
                  if (project.Translations && project.Translations.Pt) {
                    project.Name = project.Translations.Pt.Name;
                    project.Description = project.Translations.Pt.Description;
                  }
                  break;

                default:
                  break;
              }
            });
          }
          resolve(response.data);
        });
    }).catch(error => {
      throw error
    });
    return await promise;
  }

  public async addApp(project: Project, ownerId: number): Promise<any> {
    return axios.post(`${process.env.REACT_APP_API}/admin/adminManagementApp`, {
      App: {
        Id: null,
        Name: project.Name as string,
        Slug: project.Slug,
        IsShowcased: true,
        Category: project.Category,
        Summary: project.Summary,
        Description: project.Description,
        LaunchDate: project.LaunchDate,
        Url: project.Url,
        IsActive: project.IsActive,
        Team: project.Team ? JSON.stringify(project.Team) : '[]',
        ContactInfo: project.ContactInfo ? JSON.stringify(project.ContactInfo) : '[]',
        Thumbnail: project.Thumbnail,
        Screenshots: project.Screenshots,
        Finished: project.Finished
      },
      OwnerId: ownerId
    }
    );
  }

  public async updateApp(project: Project): Promise<Project> {
    return axios.post(`${process.env.REACT_APP_API}/admin/adminManagementApp`, {
      App: {
        Id: project.Id,
        Name: project.Name as string,
        Slug: project.Slug,
        Category: project.Category,
        IsShowcased: true,
        Summary: project.Summary,
        Description: project.Description,
        LaunchDate: project.LaunchDate,
        Url: project.Url,
        IsActive: project.IsActive,
        Team: project.Team ? JSON.stringify(project.Team) : '[]',
        ContactInfo: project.ContactInfo ? JSON.stringify(project.ContactInfo) : '[]',
        Thumbnail: project.Thumbnail,
        Screenshots: project.Screenshots,
        Finished: project.Finished
      },
    }
    );
  }

  public async deleteApp(id: number): Promise<boolean> {
    return axios.post(`${process.env.REACT_APP_API}/admin/deleteApp`,
      { "Id": id.toString() }
    );
  }


  public async getProjectsByFilter(filter: ProjectFilter, projects?: Project[]): Promise<Project[]> {
    let result: Project[] = [];
    if (!projects) {
      result = await this.getAllItems();
    } else {
      result = projects;
    }

    if (result.length > 0) {
      if (filter) {
        result = result.filter((s) => {
          return ((filter.name && typeof s.Name != 'undefined' && s.Name
            && ((s.Name?.toLowerCase() !== null
              && s.Name?.toLowerCase().indexOf(filter.name?.toLowerCase()) !== -1)
              || (s.Description?.toLowerCase() !== null
                && s.Description?.toLowerCase().indexOf(filter.name?.toLowerCase()) !== -1)))
            || !filter.name)
            && ((filter.category && typeof s.Category != 'undefined' && s.Category
              && s.Category?.toLowerCase() !== null && s.Category?.toLowerCase() === filter.category?.toLowerCase())
              || !filter.category)
            && ((filter.slug && typeof s.Slug != 'undefined' && s.Slug
              && s.Slug?.toLowerCase() !== null && s.Slug?.toLowerCase() === filter.slug?.toLowerCase())
              || !filter.slug)
        });
      }
    }


    return result;
  }

  public async getAppsPresigned(app: string, directory: string = 'HONDURAS'): Promise<AxiosResponse<Project[]>> {
    return axios.get<Project[], AxiosResponse<Project[]>>(`${process.env.REACT_APP_API}/apps/getPresignedURL?app=${app}&extension=zip&contentType=application/zip&directory=${directory}`
    );
  }

  public async getUserGroups(app: string): Promise<any> {
    return axios.get(`${process.env.REACT_APP_API}/apps/getUserGroups?app=${app}`
    );
  }

  public async getGroups(): Promise<AxiosResponse<GroupView[]>> {
    return axios.get(`${process.env.REACT_APP_API}/groups/getGroups`
    );
  }

  public async getAdminGroups(): Promise<AxiosResponse<GroupView[]>> {
    return axios.get(`${process.env.REACT_APP_API}/groups/getAdminGroups`
    );
  }


  public async getGroupById(id: number): Promise<Group> {
    let group: Group = {
      Id: 0,
      AppId: 0,
      Name: '',
      IsDeleted: false
    };

    const result = await axios.get(`${process.env.REACT_APP_API}/groups/getGroupById?id=${id.toString()}`
    );
    //TODO: change for real getGroupById
    //let groups = await this.getGroups();

    if (result.data.length > 0) {
      group = result.data[0];
    }
    return group;
  }

  public async addGroup(group: Group): Promise<number> {
    localStorage.removeItem("groupApps");
    return axios.post(`${process.env.REACT_APP_API}/admin/addGroup`, {
      AppId: group.AppId.toString(),
      Name: group.Name,
      IsAdminGroup: group.IsAdminGroup,
      IsUserDefaultGroup: group.IsUserDefaultGroup,
      IsReadOnly: group.IsReadOnly
    }
    );
  }

  public async updateGroup(group: Group): Promise<any> {
    localStorage.removeItem("groupApps");
    return axios.post(`${process.env.REACT_APP_API}/admin/updateGroup`, {
      Id: group.Id.toString(),
      AppId: group.AppId.toString(),
      Name: group.Name,
      Config: null,
      IsAdminGroup: group.IsAdminGroup,
      IsUserDefaultGroup: group.IsUserDefaultGroup,
      IsReadOnly: group.IsReadOnly
    }
    );
  }

  public async deleteGroup(id: number): Promise<any> {
    localStorage.removeItem("groupApps");
    return axios.post(`${process.env.REACT_APP_API}/admin/deleteGroup`,
      { "Id": id.toString() }
    );
  }

  public async getUserGroupsByGroupId(id: number): Promise<AxiosResponse<UserGroupView[]>> {
    return axios.get<UserGroupView[], AxiosResponse<UserGroupView[]>>(`${process.env.REACT_APP_API}/groups/getUserGroups?Id=${id}`
    );
  }

}