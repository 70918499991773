import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            dots: false,
            arrows: false
          }
        }
      ]
};

const DetailSlider = (props: any) => { return(
    <div>
        <section id="detail-slide">
            {(props.data && props.data.map !== undefined) && <Slider {...settings}>
                {props.data.map((item: string) => { return <div>
                    <img src={process.env.REACT_APP_CLOUD_FRONT+"/"+item} alt="" />
                </div>})}
            </Slider>}
        </section>
    </div>
)}

export default DetailSlider;