import React, { useEffect } from "react";
import { FormattedMessage } from 'react-intl';
import { Alert } from "react-bootstrap";
import { User } from "../../../models/user.model";
import { loginRequest } from '../../../services/auth/authProvider';
import { ProjectFormController } from "./projectForm.controller";
import Loading from "../../loading/Loading.component";
import { ProjectFormConsts } from "./projectForm.consts";
import { ProjectFormView } from "./projectForm.form";

function ProjectFormAdmin() {
  const cn = ProjectFormConsts();
  const ctrl = ProjectFormController(cn);

 
  useEffect(() => {
    function fetchBusiness() {
      const loginData: any = loginRequest;
      if (cn.getToken && loginData) {
        cn.getToken(loginData).then((x: string | undefined) => {
          if (x) {
            cn.setToken(x);
          }
        });
      }
      const id: number = cn.paramsId['id'];
      if (id) {
        cn.setIsBlocked(true);
      }
      cn.setLoading(true);
      ctrl.getUsers().then(responseUsers => {
        responseUsers.data.forEach((user: User) => {
          user.TextField = `${user.Username} ${user.Name ? (' - ' + user.Name) : ''} ${user.Role ? (' - ' + user.Role) : ''}`;
        });
        cn.setUsers(responseUsers.data);
        ctrl.getData();
      });
    }
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {cn.successfullSaved && (<Alert variant="success"><FormattedMessage id={'general.successfullSaved'} /></Alert>)}
      {cn.isError && (<Alert variant="warning"><FormattedMessage id={'general.error'} /></Alert>)}
      <div
        className={!cn.loading ? 'module-pjs' : 'module-pjs d-none'} id="managmentApp"
      >
        <div className="header-pj">
          <h2>
            <FormattedMessage id={'admin.apps.title'} />
          </h2>
        </div>
        <div className="hide-scroll-horizontal-bodies content-body">
          <ProjectFormView {...cn} />
        </div>
      </div>
      {cn.loading && (
        <Loading />
      )}
      {cn.existsTeamUser && (
        <Alert variant="warning">
          <FormattedMessage id={'admin.groups.form.user.exists'} />
        </Alert>
      )}
    </React.Fragment>
  );
}
export default ProjectFormAdmin;