import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ProjectFilter from '../../models/filters/project.filter';
import { useMsal } from '../../services/auth/authProvider';
import SearchIcon from '@material-ui/icons/Search';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

const FilterByValue = (props: any) => {
  const { userInternal } = useMsal();
  const [value, setValue] = useState<ProjectFilter>(new ProjectFilter());
  const [isUserInternalFiltB, setIsUserInternalFiltB] = useState<boolean>(false);

  useEffect(() => {
    function fetchBusiness() {
      if (userInternal) {
        setIsUserInternalFiltB(userInternal());
      }
    }
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchValue = (valueParam:any) => {
    if (props.valueSearchedCallback) {
      props.valueSearchedCallback(valueParam);
    }
  }
  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchValue(value);
    }
  }
  return (
    <section
      className="filterby-home"
      id="filterByValue"
      onKeyDown={_handleKeyDown}
    >
      {isUserInternalFiltB && (
        <div>
          <div className="filterby filterByValueContent">
            <React.Fragment>
              <article className="d-flex justify-content-end">
                <span className="btn">
                  <TextField
                    onChange={(name: any) =>
                      setValue({ name: name.target.value })
                    }
                    value={value.name}
                    label={
                      <FormattedMessage id={'home.filter.byvalue.title'} />
                    }
                    variant="outlined"
                    type="text"
                    fullWidth
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),

                      endAdornment: value.name && (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                           setValue({ name: '' });
                           searchValue({ name: '' });}
                          }
                        >
                          <CancelRoundedIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </span>
                <button
                  className="btn"
                  type="button"
                  onClick={() => searchValue(value)}
                >
                  <FormattedMessage id={'home.filter.byvalue.button'} />
                </button>
              </article>
            </React.Fragment>
          </div>
        </div>
      )}{' '}
    </section>
  );
}


export default FilterByValue;