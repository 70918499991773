import { useState } from "react";
import ProjectFilter from "../../models/filters/project.filter";
import Header from "../Header/Header";
import { bannerHome } from "../../core/services/mocks/data-slider";
import imgTransform from "../../assets/img/Imagen_del_infografico_completo.png";
import water from "../../assets/img/Agua.jpg";
import energy from "../../assets/img/Energia.jpg";
import transport from "../../assets/img/Transporte.jpg";
import { FormattedMessage } from 'react-intl';

export interface DataBanner {
  img: string;
  title: string;
  description: string;
  infReadMore: boolean;
  infEnter: boolean;
  category: string;
  Url?: string;
  Slug?: string;
}

export const AboutUs = (props: any) => {
  console.log(bannerHome);

  const [projectFilter, setProjectFilter] = useState<ProjectFilter>(
    new ProjectFilter()
  );

  const onFilterCategoryByMobile = (category: string) => {
    setFilterByparam(category);
  };
  const setFilterByparam = (categoryV: string): void => {
    const filter: ProjectFilter = {
      category: categoryV,
    };
    setProjectFilter({ ...filter });
    if (props.setCategoryFActive) {
      props.setCategoryFActive(filter);
    }
  };
  return (
    <div id="about-us">
      <Header onFilterCategoryByMobile={onFilterCategoryByMobile} />
      <div className="banner-about">
        <div className="opacity" />
        <div className="banner" />
        <h2><FormattedMessage id={"about.infradigital"} /></h2>
      </div>
      <section className="transformation container">
        <p>
        <FormattedMessage id={"about.transformation.p1"} />
        </p>
        <img src={imgTransform} alt="" />
        <p>
          <FormattedMessage id={"about.transformation.p2"} />
        </p>
      </section>
      <section className="about-list">
        <div className="container">
          <p>
            <FormattedMessage id={"about.list"}/>
          </p>
          <div className="ul">
            <div className="icon">
              <em className="about-usNube_cloud"></em>
            </div>
            <div className="cont">
              <p>
                <FormattedMessage id={"about.list.nube"}/>
              </p>
            </div>
          </div>
          <div className="ul">
            <div className="icon">
              <em className="about-usTroquel"></em>
            </div>
            <div className="cont">
              <p>
                <FormattedMessage id={"about.list.troquel"}/>
              </p>
            </div>
          </div>
          <div className="ul">
            <div className="icon">
              <em className="about-usCheck"></em>
            </div>
            <div className="cont">
              <p>
                <FormattedMessage id={"about.list.check"}/>
              </p>
            </div>
          </div>
          <div className="ul">
            <div className="icon">
              <em className="about-usportatil"></em>
            </div>
            <div className="cont">
              <p>
                <FormattedMessage id={"about.list.portatil"}/>
              </p>
            </div>
          </div>
          <div className="ul">
            <div className="icon">
              <em className="about-usidea"></em>
            </div>
            <div className="cont">
              <p>
                <FormattedMessage id={"about.list.idea"}/><span>infradigital@iadb.org</span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="water container">
        <h5><FormattedMessage id={"water.title"}/></h5>
        <img src={water} alt="" className="img-water" />
        <p>
          <FormattedMessage id={"water.parragraph.p1"} />
        </p>
        <p>
          <FormattedMessage id={"water.parragraph.p2.part1"}/><span>25% </span>
          <br /><FormattedMessage id={"water.parragraph.p2.part2"}/><span>30% </span><br /><FormattedMessage id={"water.parragraph.p2.part3"}/><span>8% </span>
          <br />
          <FormattedMessage id={"water.parragraph.p2.part4"}/><span>99%</span>
        </p>
        <p>
        <FormattedMessage id={"water.parragraph.p3.part1"}/><b><FormattedMessage id={"water.parragraph.p3.bold"}/></b><FormattedMessage id={"water.parragraph.p3.part2"}/>
        </p>
        <p>
        <FormattedMessage id={"water.parragraph.p4"}/>
          <b>Hydro-BID.</b>
        </p>
        <p>
        <FormattedMessage id={"water.parragraph.p5.part1"}/><b>Big Data</b><FormattedMessage id={"water.parragraph.p5.part2"}/>
        </p>
      </section>
      <section className="energy">
        <div className="container">
          <h5><FormattedMessage id={"energy.title"}/></h5>
          <img src={energy} alt="" className="img-energy" />
          <p>
          <FormattedMessage id={"energy.parragraph.p1"}/>
          </p>
          <p>
          <FormattedMessage id={"energy.parragraph.p2"}/>
          </p>
          <p>
          <FormattedMessage id={"energy.parragraph.p3"}/>
          </p>
          <p>
          <FormattedMessage id={"energy.parragraph.p4.part1"}/>
            <b><FormattedMessage id={"energy.parragraph.p4.bold"}/></b>
          </p>
          <p>
          <FormattedMessage id={"energy.parragraph.p5.part1"}/><b><FormattedMessage id={"energy.parragraph.p5.bold1"}/></b> <FormattedMessage id={"energy.parragraph.p5.part2"}/><b><FormattedMessage id={"energy.parragraph.p5.bold2"}/></b><FormattedMessage id={"energy.parragraph.p5.part3"}/><b><FormattedMessage id={"energy.parragraph.p5.bold3"}/></b><FormattedMessage id={"energy.parragraph.p5.part4"}/><b><FormattedMessage id={"energy.parragraph.p5.bold4"}/></b><FormattedMessage id={"energy.parragraph.p5.part5"}/><b><FormattedMessage id={"energy.parragraph.p5.bold5"}/></b><FormattedMessage id={"energy.parragraph.p5.part6"}/>
          </p>
        </div>
      </section>
      <section className="transport container">
        <h5><FormattedMessage id={"transport.title"}/></h5>
        <img src={transport} alt="" className="img-transport" />
        <p>
        <FormattedMessage id={"transport.parragraph.p1"}/>
        </p>
        <p>
        <FormattedMessage id={"transport.parragraph.p2"}/>
        </p>
        <p>
        <FormattedMessage id={"transport.parragraph.p3"}/>
        </p>
      </section>
    </div>
  );
};