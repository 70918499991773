import React, { useEffect, useState } from "react";
import { useWraperContext } from "../../complements/Wrapper";
import { Grid, GridColumn, GridFilterCellProps, GridFilterChangeEvent, GridNoRecords, GridPageChangeEvent } from "@progress/kendo-react-grid";
import { FormattedMessage, useIntl } from 'react-intl';
import { ProjectsService } from "../../../services/projects/projects.service";
import { GroupView } from "../../../models/groupView.model";
import { useHistory } from "react-router";
import { CompositeFilterDescriptor, filterBy } from "@progress/kendo-data-query";
import { PageState } from "../../../models/pagestate.model";
import { Alert } from "react-bootstrap";
import { Project } from "../../../models/project.model";
import { DropdownFilterCell } from "../../common/dropdownFilterCell";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { UsersService } from "../../../services/users/users.service";
import { useMsal } from "../../../services/auth/authProvider";
import AdministratorType from "../../../models/administratorType.model";
import { UserAppsService } from "../../../services/users/userApps.service";
import { INFRA_DIGITAL_APP_0 } from "../../../globalSettings";
import Loading from '../../loading/Loading.component';

const generalYes = 'general.yes';
const generalNo = 'general.no';
const initialDataState: PageState = { skip: 0, take: 10 };
const initialFilter: CompositeFilterDescriptor = {
  logic: "and",
  filters: [],
};


function GroupsAdmin() {
  const { locale } = useWraperContext();
  const usersService = UsersService({ locale: locale });
  const userAppsService = new UserAppsService({ locale: locale });
  const history = useHistory();
  const [groups, setGroups] = useState<GroupView[]>([]);
  const projectsService = new ProjectsService({
    locale: locale

  });
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [projects, setProjects] = useState<Project[]>([]);

  const { user } = useMsal();
  const intl = useIntl();

  const [administratorType, setAdministratorType] = useState<AdministratorType>({ IsSuperUser: false, IsAdminGroup: false });
  const [loading, setLoading] = useState<boolean>(false);
  const [filter, setFilter] = React.useState(initialFilter);
  const [page, setPage] = React.useState<PageState>(initialDataState);

  const getData = async () => {
    setLoading(true);
    try {
      let admType = new AdministratorType();
      if (user) {
        const userName = usersService.getUsername(user);
        admType = await usersService.getIsAdminbyUsername(userName);
        setAdministratorType(admType);
      }
      let projectsData: Project[] = [];
      if (admType.IsSuperUser) {
        projectsData = await projectsService.getAllItems();
        setProjects(projectsData);
      } else if (admType.IsAdminGroup) {
        const projectsResult = await userAppsService.getAdminUserApps();
        projectsResult.forEach(pr => {
          if (projectsData.filter(s => s.Name === pr.Name).length === 0) {
            projectsData.push(pr);
          }
        });

        setProjects(projectsData);

      }
      await getGroups(admType, projectsData);

      setLoading(false);
    } catch (ex) {
      setLoading(false);
      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 4000);
    }
  }



  useEffect(() => {
    function fetchBusiness() {
      getData();
    }
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageChange = (event: GridPageChangeEvent) => {
    setPage(event.page);
  };

  const getGroups = async (admType: AdministratorType, projectsR: Project[]) => {
    let groupsData: GroupView[] = [];


    if (admType.IsSuperUser) {
      groupsData = (await projectsService.getGroups()).data;
    } else if (admType.IsAdminGroup) {
      const groupsResult = (await projectsService.getAdminGroups()).data;
      groupsResult.forEach(gr => {
        if (groupsData.filter(s => s.Name === gr.Name).length === 0) {
          groupsData.push(gr);
        }
      });

    }

    const result: GroupView[] = [];
    groupsData.forEach((group: GroupView) => {
      if (projectsR.filter(s => s.Id === group.AppId).length > 0) {
        group.IsAdminGroupString = group.IsAdminGroup === true ? intl.formatMessage({
          id: generalYes
        }) : intl.formatMessage({
          id: generalNo
        });

        group.IsReadOnlyString = group.IsReadOnly === true ? intl.formatMessage({
          id: generalYes
        }) : intl.formatMessage({
          id: generalNo
        });

        result.push(group);
      }
    });
    setGroups(result);
  }

  const gridWidth = 1400;

  const addNewGroup = async (event: any) => {
    history.push({
      pathname: `/admin/groups/form`,
      state: {},
    }
    )
  };

  const enterEdit = async (item: GroupView) => {
    history.push({
      pathname: `/admin/groups/form/${item.Id}`,
      state: {},
    }
    )
  };

  const enterDelete = async (item: GroupView) => {
    const options = {
      title: intl.formatMessage({
        id: 'general.delete',
      }),
      message: intl.formatMessage({
        id: 'general.deleteItemMessage',
      }),
      buttons: [
        {
          label: intl.formatMessage({
            id: generalYes,
          }),
          onClick: () => {
            handleClickDelete(item);
          }
        },
        {
          label: intl.formatMessage({
            id: generalNo,
          }),
          onClick: () => {/*do something here*/ }
        }
      ],
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => { /*do something here*/ },
      afterClose: () => { /*do something here*/ },
      onClickOutside: () => {/*do something here*/ },
      onKeypressEscape: () => { /*do something here*/ },
      overlayClassName: "overlay-custom-class-name"
    };
    confirmAlert(options);
  };

  const handleClickDelete = async (item: GroupView) => {
    setLoading(true);
    try {
      await projectsService.deleteGroup(item.Id);
      await getGroups(administratorType, projects);
      setIsDeleted(true);
      setTimeout(() => {
        setIsDeleted(false);
      }, 4000);


    } catch (err) {
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 4000);
    }
    setLoading(false);
  }

  const dataCrud = {
    confirm: {
      title: intl.formatMessage({
        id: 'confirm.to.submit.title',
      }),
      description: intl.formatMessage({
        id: 'confirm.to.submit.description',
      }),
      yes: intl.formatMessage({
        id: generalYes,
      }),
      no: intl.formatMessage({
        id: generalNo,
      }),
    },
  };

  const ActionsCommandCell = (props: any) => {
    return (
      <td>
        <button
          className="k-button k-primary"
          onClick={() => props.enterEdit(props.dataItem)}
        >
          <FormattedMessage id={'general.edit'} />
        </button>
        {<button
          className={props.dataItem
            && (props.dataItem.Infra2_App.AppName.toLowerCase() !== INFRA_DIGITAL_APP_0.toLowerCase())
            && !props.dataItem.IsAdminGroup && (administratorType.IsSuperUser
              || (!props.dataItem.IsAdminGroup && !props.dataItem.IsUserDefaultGroup)) ?
            "k-button btn pl-2" : "k-button btn pl-2 btn disabled"}
          onClick={() => {
            props.enterDelete(props.dataItem);
          }}
        >
          <FormattedMessage id={'general.delete'} />
        </button>}
      </td>
    );
  };

  const CustomActions = (props: any) => (
    <ActionsCommandCell {...props} data={dataCrud} enterEdit={enterEdit} enterDelete={enterDelete} />
  );

  const setPercentage = (percentage: number) => {
    return Math.round(gridWidth / 100) * percentage;
  };

  const ProjectsFilterCell: any = (props: GridFilterCellProps) => (
    <DropdownFilterCell
      {...props}
      data={projects.map(project => {
        return {
          text: project.Name as string,
          value: project.Name as string
        }
      })}
      defaultItem={"All"}
    />
  );



  const BooleanFilterCell: any = (props: GridFilterCellProps) => (
    <DropdownFilterCell
      {...props}
      data={[
        { text: "All", value: null },
        {
          text: intl.formatMessage({
            id: generalYes,
          }), value: intl.formatMessage({
            id: generalYes,
          })
        },
        {
          text: intl.formatMessage({
            id: generalNo,
          }), value: intl.formatMessage({
            id: generalNo,
          })
        }]}
      defaultItem={"All"}
    />
  );

  return (
    <React.Fragment>
      {isDeleted && (
        <Alert variant="success">
          <FormattedMessage id={'general.successfullDeleted'} />
        </Alert>
      )}
      {isError && (
        <Alert variant="warning">
          <FormattedMessage id={'general.error'} />
        </Alert>
      )}

      {!loading && (
        <div className="module-pjs">
          <div className="header-pj">
            <h2>
              <FormattedMessage id={'admin.groups.title'} />
            </h2>
            <button className="btn btn-primary" onClick={addNewGroup}>
              <FormattedMessage id={'admin.groups.AddNew'} />
            </button>
          </div>
          <div className="content-body content-body-hide-scroll-horizontal">
            {groups && (
              <Grid
                sortable={true}
                data={filterBy(groups, filter).slice(
                  page.skip,
                  page.take + page.skip
                )}
                skip={page.skip}
                take={page.take}
                filterable={true}
                filter={filter}
                onFilterChange={(e: GridFilterChangeEvent) =>
                  setFilter(e.filter)
                }
                groupable={false}
                reorderable={true}
                total={filterBy(groups, filter).length}
                pageable={true}
                onPageChange={pageChange}
                expandField="expanded"
                style={{
                  width: gridWidth,
                }}
                className="hidde-scroll-horizontal"
              >
                <GridNoRecords>
                  <FormattedMessage id={'general.norecords'} />
                </GridNoRecords>
                <GridColumn
                  field="Infra2_App.AppName"
                  title={intl.formatMessage({
                    id: 'admin.groups.grid.appName.title',
                  })}
                  width={setPercentage(28)}
                  filterable={true}
                  filterCell={ProjectsFilterCell}
                />
                <GridColumn
                  field="Name"
                  title={intl.formatMessage({
                    id: 'admin.groups.grid.groupName.title',
                  })}
                  width={setPercentage(25)}
                  filter="text"
                />
                <GridColumn
                  field="IsAdminGroupString"
                  headerClassName=""
                  title={intl.formatMessage({
                    id: 'admin.groups.grid.isAdminGroup.title',
                  })}
                  width={setPercentage(16)}
                  filter="text"
                  filterCell={BooleanFilterCell}
                />

                <GridColumn
                  field="IsReadOnlyString"
                  title={intl.formatMessage({
                    id: 'admin.groups.grid.readOnlyGroup.title',
                  })}
                  filterCell={BooleanFilterCell}
                  width={setPercentage(15)}
                  filter="text"
                />
                <GridColumn
                  cell={CustomActions}
                  title={intl.formatMessage({
                    id: 'admin.common.grid.actions.title',
                  })}
                  width={setPercentage(12)}
                  filterable={false}
                />
              </Grid>
            )}
          </div>
        </div>
      )}
      {loading && (
        <Loading></Loading>
      )}
    </React.Fragment>
  );

}

export default GroupsAdmin;