import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import ProjectFilter from "../../models/filters/project.filter";
import FilterByValue from '../Filter-by-value/Filter-by-value';
import Filter from '../Filter/Filter';
import Header from '../Header/Header';
import Projects from '../Projects/Projects';
import SliderHome from '../SliderHome/SliderHome';

function Home(props: any) {
  const [projectFilter, setProjectFilter] = useState<ProjectFilter>(
    new ProjectFilter()
  );
  const filterHomeCallback = (filter: ProjectFilter) => {
    setProjectFilter({ ...projectFilter, ...filter });

    if (props.setCategoryFActive) {
       props.setCategoryFActive(filter);
    }
  };
  const valueSearchedCallback = (filter: ProjectFilter) => {
    setProjectFilter({...projectFilter, ...filter});
  };
  const onFilterCategoryByMobile = (category: string) => {
    setFilterByparam(category);
  };

  const location = useLocation();

  const setFilterByparam = (categoryV: string): void => {
    const filter: ProjectFilter = {
      category: categoryV,
    };
    setProjectFilter({ ...filter });
    if (props.setCategoryFActive) {
      props.setCategoryFActive(filter);
    }
  }

  useEffect(() => {
    /*do something here*/
  }, [location, props]);
  return (
    <div>
      <React.Fragment>
        <Header onFilterCategoryByMobile={onFilterCategoryByMobile} />
        {/* <ContactSupport /> */}
        <SliderHome />
        <Filter
          projectFilter={projectFilter}
          filterCallback={filterHomeCallback}
        />
        <FilterByValue valueSearchedCallback={valueSearchedCallback} />
        <Projects projectFilter={projectFilter} />
      </React.Fragment>
    </div>
  );
}

export default Home;