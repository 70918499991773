import axios from "axios";

export class DashboardService {
    private locale: string = 'en';
    constructor(props?: any) {
        if (props.locale) {
            this.locale = props.locale;
        }
    }

    public async getDashboard(): Promise<any> {
        return axios.get(`${process.env.REACT_APP_API}/dashboard/getPanelDashboard`);
    }

}