import axios from 'axios';

function AxiosInterceptors(token: string) {
    if (token !== undefined) {

      try {
        axios.interceptors.request.use(
          config => {
            if (config.headers['data-DisabledAuthorization'] === undefined) {
              config.headers['Authorization'] = `Bearer ${token}`;
            } else {
              if (config.headers['data-DisabledAuthorization']) {
                delete config.headers['Authorization'];
                delete config.headers['data-DisabledAuthorization'];
              }
            }
            return config;
          },
          error => {
            Promise.reject(error)
          });
      } catch (ex) {
        console.log(ex);
      }
    }
}

export default AxiosInterceptors;