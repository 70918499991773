export class Dataset {
        Id?: number;
        Name?: string;
        Slug?: string;
        Config?: string;
        AccessLevel?: string;
        RepositoryType?: string;
        AppId?: number;
        AppName?: string;
        Infra2_App?: {
                Name: string;
        }
        Key?: string;
        UserId?: number;
        CreatedAt?: Date;
        ModifiedAt?: Date;
        Description?: string;
        Owner?: string;
}