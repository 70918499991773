import { Dropdown, NavItem, NavLink } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useWraperContext } from '../Wrapper';
import { useEffect } from 'react';
const LanguageToggle = (props: any) =>  {
  const location = useLocation();
  const { selectLanguage } = useWraperContext();
  useEffect(() => {
    // do something here
  }, [location]);

  const changeLanguage = (lang: string) => {
    if (selectLanguage) {
      selectLanguage(lang);
      localStorage.setItem('lang', lang);
    }
  }

  return (
    <div>
      <Dropdown as={NavItem}>
        <Dropdown.Toggle as={NavLink}>
          <FormattedMessage id={'sidebar.principal.translate.general'} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              changeLanguage('en');
            }}
          >
            <FormattedMessage id={'sidebar.principal.translate.english'} />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              changeLanguage('es');
            }}
          >
            <FormattedMessage id={'sidebar.principal.translate.spanish'} />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              changeLanguage('pt');
            }}
          >
            <FormattedMessage id={'sidebar.principal.translate.portuguese'} />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );}


export default LanguageToggle;