import * as React from "react";
import axios, { AxiosResponse } from "axios";
import { User } from "../../models/user.model";
import { useIntl } from "react-intl";
import { UserGroup } from "../../models/userGroup.model";
import { UserAppsService } from "./userApps.service";
import AdministratorType from "../../models/administratorType.model";
import { INFRA_DIGITAL_APP_0 } from "../../globalSettings";
import { AccountInfo } from "@azure/msal-browser";

export function UsersService(props?: any) {
  const [locale, setLocale] = React.useState<string>('en');
  const intl = useIntl();

  React.useEffect(() => {
    function fetchBusiness() {
      if (props.locale) {
        setLocale(props.locale);
      }
    }
    fetchBusiness();
  }, [props]);


  const getUsers = async (): Promise<AxiosResponse> => {
    return axios.get(`${process.env.REACT_APP_API}/users/getUsers`
    );
  }

  const getUserbyUsername = async (userName: string): Promise<User> => {

    const data: AxiosResponse<User> = await axios.post<User, AxiosResponse<User>>(`${process.env.REACT_APP_API}/users/getUsersByUsername?username=${userName}`
    );
    if (data.data) {
      return data.data;
    } else {
      return {
        Id: 0,
        Username: '',
        Sub: '',
        IsInternal: false,
        IsAdmin: false,
        CreatedAt: new Date(),
        Inactive: false
      };
    }
  }

  const getIsAdminbyUsername = async (userName: string): Promise<AdministratorType> => {

    let administrator: AdministratorType = { IsSuperUser: false, IsAdminGroup: false };
    if (!localStorage.getItem("IsSuperUser") && !localStorage.getItem("IsAdminGroup")) {

      const data: AxiosResponse<User> = await axios.post<User, AxiosResponse<User>>(`${process.env.REACT_APP_API}/users/getUsersByUsername?username=${userName}`
      );

      if (data.data) {

        const userAppsService = new UserAppsService({ locale: locale });
        let adminUserAppsResult = await userAppsService.getAdminUserApps();
        if (adminUserAppsResult && adminUserAppsResult.length > 0) {
          if (adminUserAppsResult.filter(s => s.Slug?.toLocaleLowerCase() === INFRA_DIGITAL_APP_0?.toLowerCase()).length > 0) {
            administrator.IsSuperUser = true;
            administrator.IsAdminGroup = false;
          } else {
            administrator.IsSuperUser = false;
            administrator.IsAdminGroup = true;
          }
        }


      } else {
        administrator.IsSuperUser = false;
        administrator.IsAdminGroup = false;

      }
      localStorage.setItem('IsSuperUser', administrator.IsSuperUser.toString());
      localStorage.setItem('IsAdminGroup', administrator.IsAdminGroup.toString());
    } else {
      administrator.IsSuperUser = localStorage.getItem("IsSuperUser") === "true";
      administrator.IsAdminGroup = localStorage.getItem("IsAdminGroup") === "true";
    }
    return administrator;
  }

  const addUser = async (user: User): Promise<User> => {
    return axios.post(`${process.env.REACT_APP_API}/admin/addUser`,
      {
        Username: user.Username,
        IsAdmin: user.IsAdmin,
        IsInternal: user.IsInternal,
        Role: user.Role,
        Name: user.Name
      }
    );
  }

  const updateUser = (user: User): Promise<User> => {
    return axios.post(`${process.env.REACT_APP_API}/admin/updateUser`,
      {
        Id: user.Id.toString(),
        Username: user.Username,
        IsInternal: user.IsInternal,
        IsAdmin: user.IsAdmin,
        Inactive: user.Inactive,
        Role: user.Role,
        Name: user.Name,
        CreatedAt: Date.parse(user.CreatedAt.toString())
      }
    );
  }


  const deleteUser = async (id: number): Promise<User> => {
    return axios.post(`${process.env.REACT_APP_API}/admin/deleteUser`,
      { Id: id.toString() }
    );
  }

  const addGroupUser = async (data: UserGroup): Promise<any> => {
    return axios.post(`${process.env.REACT_APP_API}/admin/addGroupUser`,
      {
        userId: data.UserId.toString(),
        groupId: data.GroupId.toString()
      }
    );
  }

  const deleteGroupUser = async (id: number): Promise<any> => {
    return axios.post(`${process.env.REACT_APP_API}/admin/deleteUserGroup?userGroupId=${id}`
    ).catch(err => { throw err });
  }

  const getRoles = (): string[] => {
    const roles: string[] = [
      intl.formatMessage({
        id: 'general.roles.administrator',
      }),
      intl.formatMessage({
        id: 'general.roles.supervisor',
      })
    ];

    return roles;
  }

  const getUsername = (user: AccountInfo): string => {
    let userName = '';
    if (user) {
      if (user.username) {
        userName = user.username;
      } else if (user.idTokenClaims) {
        const idTokenClaims = user.idTokenClaims as any;
        if (idTokenClaims.email) {
          userName = idTokenClaims.email.toLowerCase();
        }
      }
    }
    return userName;
  }

  return (
    {
      getUsers,
      getUserbyUsername,
      getIsAdminbyUsername,
      addUser,
      updateUser,
      deleteUser,
      getRoles,
      addGroupUser,
      deleteGroupUser,
      getUsername
    }
  );


}
