import axios from "axios";
import { StatusModel } from '../../models/status.model';
import { General } from "../general";

export class ManagmentArchivesService {
    private locale: string = 'en';
    constructor(props?: any) {
        if (props.locale) {
            this.locale = props.locale;
        }
    }

    public readFile = async (file:File) => {
        return new Promise((resolve) => {
            let reader = new FileReader();
            reader.addEventListener("load",() => {resolve(reader.result);});
            reader.readAsArrayBuffer(file)
        })
    }

    public async getDashboard(): Promise<any> {
        return axios.get(`${process.env.REACT_APP_API}/dashboard/getPanelDashboard`);
    }

    public async sendUrlConfirmed(url: any, file: any, type: string): Promise<void> {
        let fileData: any = await this.readFile(file);
        await fetch(`${url}`, {method:"PUT", body: fileData, headers: {'Content-Type': type}});
    }

    public async uploadfile(
        fileName: string,
        contentType: string,
        path: string): Promise<StatusModel> {
            return new Promise<StatusModel>((resolveU, rejectU) => {
                if(path.substring(path.length-1)!=='/') {
                    path+='/';
                }
                axios.post(
                    `${process.env.REACT_APP_API}/attachments/getUploadFileUrl?` +
                `contentType=${contentType}&` +
                `fileName=${fileName}&` +
                `expiryTime=${3600}&` +
                `path=${path}`,
                    {}).then((x: any) => {
                    if (x.status !== undefined && x.status !== 200) {
                        resolveU({ Status: x.status, Data: [x.data] });
                    } else {
                        resolveU({ Status: 200, Data: [x.data.uploadUrl], FileName: x.data.filename});
                    }
                });
            });
    }

    public async uploadfileStatic(
        fileName: string,
        type: string,
        path: string,
        file: any): Promise<StatusModel> {
        return new Promise<StatusModel>((resolveU, reject) => {
            axios.post(
                (`${General.API_MANAGMENTFILE_URL}/uploadFile?`+
            `fileName=${fileName}&`+
            `type=${type}&`+
            `path=${path}`),
            file,
            {
                headers: {
                  "data-DisabledAuthorization": true,
                  Authorization: undefined,
                  'Content-type': `application/x-${type}`,
            }}).then((x: any) => {
                if (x.status === 200 && x.data.url) {
                    resolveU({ Status: x.status, Data: [x.data.url]});
                } else {
                    if (x.status === 413) {
                        resolveU({ Status: x.Status,  Data: []});
                    }
                }
            });
        });
    }
}