import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ProjectFormController } from "./projectForm.controller";

export function ProjectFormHeader(cn: any) {
    const ctrl = ProjectFormController(cn);
    return (
      <React.Fragment>
        <div className="col-12 col-md-6">
          <div className="form-group">
            <span>
              <FormattedMessage id={'general.name'} />
              <span style={{ color: 'darkred' }}>*</span>
            </span>
            <input
              disabled={cn.isAppMain}
              {...cn.register('name', {
                required: true,
                maxLength: 255,
              })}
              id="name"
              type="text"
              className="fields"
              placeholder={cn.intl.formatMessage({ id: 'general.name' })}
              onChange={(e) => cn.setValue('name', e.target.value)}
              defaultValue={cn.currentProject.Name}
            ></input>
          </div>
          {cn.errors.name && (
            <span style={{ color: 'darkred' }}>
              <FormattedMessage id="admin.projects.form.name.required" />
            </span>
          )}
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
            <span>
              <FormattedMessage id="admin.projects.form.slug.label" />
            </span>
            <span style={{ color: 'darkred' }}>*</span>
            <input
              disabled={cn.isAppMain}
              {...cn.register('slug', {
                required: true,
                maxLength: 255,
                pattern: {
                  value: /^[a-zA-Z\d\-_]+$/,
                  message: 'invalid slug',
                },
              })}
              id="slug"
              type="text"
              className="fields"
              placeholder={cn.intl.formatMessage({
                id: 'admin.projects.form.slug.placeHolder',
              })}
              onChange={(e) => cn.setValue('slug', e.target.value)}
              defaultValue={cn.currentProject.Slug}
            ></input>
          </div>
          {cn.errors.slug && (
            <span style={{ color: 'darkred' }}>
              <FormattedMessage id="admin.projects.form.slug.required" />
            </span>
          )}
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
            <span>
              <FormattedMessage id={'general.category'} />
            </span>
            <span style={{ color: 'darkred' }}>*</span>
            <DropDownList
              disabled={cn.isAppMain}
              name="category"
              className="drop-field"
              data={cn.categories}
              defaultValue={cn.stateCategory.value}
              onChange={ctrl.handleChangeCategory}
            />
          </div>
          {cn.submited && cn.stateCategory.value === '' && (
            <span style={{ color: 'darkred' }}>
              <FormattedMessage id="admin.projects.form.category.required" />
            </span>
          )}
        </div>
        <div className="col-12 col-md-6">
          <React.Fragment>
            <div className={!cn.currentProject.Id ? 'form-group' : 'd-none'}>
              <span>
                <FormattedMessage id={'general.owner'} />
              </span>
              <span style={{ color: 'darkred' }}>*</span>
              <DropDownList
                data={cn.users}
                textField="TextField"
                dataItemKey="Id"
                defaultValue={cn.selectedOwner.value}
                defaultItem={cn.intl.formatMessage({
                  id: 'admin.groups.form.user.placeHolder',
                })}
                name="owner"
                className="drop-field"
                onChange={ctrl.handleChangeOwner}
              />
            </div>
            {!cn.currentProject.Id &&
              cn.submited &&
              !cn.selectedOwner.value.Id && (
                <span style={{ color: 'darkred' }}>
                  <FormattedMessage id="admin.projects.form.owner.required" />
                </span>
              )}
          </React.Fragment>
        </div>
        <div className="col-12">
          <div className="form-group">
            <span>
              <FormattedMessage id={'general.summary'} />
            </span>
            <span style={{ color: 'darkred' }}>*</span>
            <textarea
              disabled={cn.isAppMain}
              {...cn.register('summaryEN', {
                maxLength: 800,
              })}
              id="summaryEN"
              className="txtarea"
              placeholder={cn.intl.formatMessage({
                id: 'general.summary',
              })}
              onChange={(e) => {
                cn.setValue('summaryEN', e.target.value);
                cn.currentProject.SummaryEN = e.target.value;
                cn.setCurrentProject(cn.currentProject);
                cn.setInvalidSummaryEn(!e.target.value);
              }}
              defaultValue={cn.currentProject.SummaryEN}
            ></textarea>
          </div>
          {!cn.isAppMain && !cn.currentProject.SummaryEN && cn.invalidSummaryEn  && (
            <span style={{ color: 'darkred' }}>
              <FormattedMessage id="admin.projects.form.summary.required" />
            </span>
          )}
          {!cn.isAppMain &&
            cn.currentProject.SummaryEN &&
            cn.currentProject.SummaryEN.length > 800 && (
              <span style={{ color: 'darkred' }}>
                <FormattedMessage id="admin.projects.form.summary.maxLength" />
              </span>
            )}
        </div>
        <div className="col-12">
          <div className="form-group">
            <span>
              <FormattedMessage id={'general.summaryES'} />
            </span>
            <span style={{ color: 'darkred' }}>*</span>
            <textarea
              disabled={cn.isAppMain}
              {...cn.register('summaryES', {
                maxLength: 800,
              })}
              id="summaryES"
              className="txtarea"
              placeholder={cn.intl.formatMessage({
                id: 'general.summaryES',
              })}
              onChange={(e) => {
                cn.setValue('summaryES', e.target.value);
                cn.currentProject.SummaryES = e.target.value;
                cn.setCurrentProject(cn.currentProject);
                cn.setInvalidSummaryEs(!e.target.value);
              }}
              defaultValue={cn.currentProject.SummaryES}
            ></textarea>
          </div>
          {!cn.isAppMain && !cn.currentProject.SummaryES && cn.invalidSummaryEs && (
            <span style={{ color: 'darkred' }}>
              <FormattedMessage id="admin.projects.form.summary.required" />
            </span>
          )}
          {!cn.isAppMain &&
            cn.currentProject.SummaryES &&
            cn.currentProject.SummaryES.length > 800 && (
              <span style={{ color: 'darkred' }}>
                <FormattedMessage id="admin.projects.form.summary.maxLength" />
              </span>
            )}
        </div>
        <div className="col-12">
          <div className="form-group">
            <span>
              {/* <FormattedMessage id={'general.summary'} /> */}
              <FormattedMessage id={'general.summaryPT'} />
            </span>
            <span style={{ color: 'darkred' }}>*</span>
            <textarea
              disabled={cn.isAppMain}
              {...cn.register('summaryPT', {
                maxLength: 800,
              })}
              id="summaryPT"
              className="txtarea"
              placeholder={cn.intl.formatMessage({
                id: 'general.summaryPT',
              })}
              onChange={(e) => {
                cn.setValue('summaryPT', e.target.value);
                cn.currentProject.SummaryPT = e.target.value;
                cn.setCurrentProject(cn.currentProject);
                cn.setInvalidSummaryPt(!e.target.value);
              }}
              defaultValue={cn.currentProject.SummaryPT}
            ></textarea>
          </div>
          {!cn.isAppMain && !cn.currentProject.SummaryPT && cn.invalidSummaryPt && (
            <span style={{ color: 'darkred' }}>
              <FormattedMessage id="admin.projects.form.summary.required" />
            </span>
          )}
          {!cn.isAppMain &&
            cn.currentProject.SummaryPT &&
            cn.currentProject.SummaryPT.length > 800 && (
              <span style={{ color: 'darkred' }}>
                <FormattedMessage id="admin.projects.form.summary.maxLength" />
              </span>
            )}
        </div>
        <div className="col-12">
          <div className="form-group">
            <span>
              <FormattedMessage id={'general.description'} />
            </span>
            <span style={{ color: 'darkred' }}>*</span>
            <textarea
              disabled={cn.isAppMain}
              {...cn.register('descriptionEN', {
                maxLength: 3000,
              })}
              id="descriptionEN"
              className="txtarea"
              onChange={(e) => {
                cn.setValue('descriptionEN', e.target.value);
                cn.currentProject.DescriptionEN = e.target.value;
                cn.setCurrentProject(cn.currentProject);
                cn.setInvalidDescriptionEn(!e.target.value);
              }}
              placeholder={cn.intl.formatMessage({ id: 'general.description' })}
              defaultValue={cn.currentProject.DescriptionEN}
            ></textarea>
          </div>
          {!cn.isAppMain && !cn.currentProject.DescriptionEN && cn.invalidDescriptionEn && (
            <span style={{ color: 'darkred' }}>
              <FormattedMessage id="admin.projects.form.description.required" />
            </span>
          )}
        </div>
        <div className="col-12">
          <div className="form-group">
            <span>
              {/* <FormattedMessage id={'general.description'} /> */}
              <FormattedMessage id={'general.descriptionES'} />
            </span>
            <span style={{ color: 'darkred' }}>*</span>
            <textarea
              disabled={cn.isAppMain}
              {...cn.register('descriptionES', {
                maxLength: 3000,
              })}
              id="descriptionES"
              className="txtarea"
              onChange={(e) => {
                cn.setValue('descriptionES', e.target.value);
                cn.currentProject.DescriptionES = e.target.value;
                cn.setCurrentProject(cn.currentProject);
                cn.setInvalidDescriptionEs(!e.target.value);
              }}
              placeholder={cn.intl.formatMessage({ id: 'general.descriptionES' })}
              defaultValue={cn.currentProject.DescriptionES}
            ></textarea>
          </div>
          {!cn.isAppMain && !cn.currentProject.DescriptionES && cn.invalidDescriptionEs && (
            <span style={{ color: 'darkred' }}>
              <FormattedMessage id="admin.projects.form.description.required" />
            </span>
          )}
        </div>
        <div className="col-12">
          <div className="form-group">
            <span>
              {/* <FormattedMessage id={'general.description'} /> */}
              <FormattedMessage id={'general.descriptionPT'} />
            </span>
            <span style={{ color: 'darkred' }}>*</span>
            <textarea
              disabled={cn.isAppMain}
              {...cn.register('descriptionPT', {
                maxLength: 3000,
              })}
              id="descriptionPT"
              className="txtarea"
              onChange={(e) => {
                cn.setValue('descriptionPT', e.target.value);
                cn.currentProject.DescriptionPT = e.target.value;
                cn.setCurrentProject(cn.currentProject);
                cn.setInvalidDescriptionPt(!e.target.value);
              }}
              placeholder={cn.intl.formatMessage({ id: 'general.descriptionPT' })}
              defaultValue={cn.currentProject.DescriptionPT}
            ></textarea>
          </div>
          {!cn.isAppMain && !cn.currentProject.DescriptionPT && cn.invalidDescriptionPt && (
            <span style={{ color: 'darkred' }}>
              <FormattedMessage id="admin.projects.form.description.required" />
            </span>
          )}
        </div>
        <div className="col-6">
          <div className="form-group">
            <span>
              <FormattedMessage id={'general.publicationDate'} />
            </span>
            <span style={{ color: 'darkred' }}>*</span>
            <DatePicker
              disabled={cn.isAppMain}
              {...cn.register('launchDate', {
                required: true,
              })}
              value={cn.launchDate}
              className="datepic"
              onChange={cn.changePublicationDate}
            />
          </div>
          {cn.errors.launchDate && (
            <span style={{ color: 'darkred' }}>
              <FormattedMessage id="admin.projects.form.launchDate.required" />
            </span>
          )}
        </div>
        <div className="col-6">
          <div className="form-group">
            <span>
              <FormattedMessage id={'general.websiteLink'} />
            </span>
            <span style={{ color: 'darkred' }}>*</span>
            <input
              disabled={cn.isAppMain}
              {...cn.register('url', {
                required: !cn.isAppMain,
              })}
              id="url"
              type="text"
              className="fields"
              placeholder={'https://'}
              defaultValue={cn.currentProject.Url}
              onChange={(e) => {
                cn.setValue('url', e.target.value);
              }}
            ></input>
          </div>
          {cn.errors.url && (
            <span style={{ color: 'darkred' }}>
              <FormattedMessage id="admin.projects.form.url.required" />
            </span>
          )}
        </div>
      </React.Fragment>
    );
}