import React from "react";
import { Alert } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export default function GroupFormAlerts(props: any) {

    return (
        <React.Fragment>
            {props.successfullSaved && (
                <Alert variant="success">
                    <FormattedMessage id={'general.successfullSaved'} />
                </Alert>
            )}
            {props.isError && (
                <Alert variant="warning">
                    <FormattedMessage id={'general.error'} />
                </Alert>
            )}
            {props.isRequiredOwner && (
                <Alert variant="warning">
                    <FormattedMessage id={'admin.groups.form.users.owner.requiredOne'} />
                </Alert>
            )}
            {props.existsGroupUser && (
                <Alert variant="warning">
                    <FormattedMessage id={'admin.groups.form.user.exists'} />
                </Alert>
            )}

        </React.Fragment>
    );
}