import axios, { AxiosResponse } from "axios";
import { Project } from "../../models/project.model";

export class UserAppsService {
    private locale: string = 'en';
    constructor(props?: any) {
        if(props.locale) {
            this.locale = props.locale;
        }
    }

    public async getUserApps():  Promise<Project[]> {
        const promise: Promise<Project[]> = new Promise<Project[]>((resolve,reject) => {
            axios.get<Project[], AxiosResponse<Project[]>>(`${process.env.REACT_APP_API}/apps/getUserApps`).then(
                response => {
                  if(response.data) {
                    response.data.forEach(project => {
                        project.ShowEnter = true;
                        switch (this.locale) {
                          case 'es':
                            if (project.Translations && project.Translations.Es) {
                              project.Name = project.Translations.Es.Name;
                              project.Description = `${project.Translations.Es.Description}`;
                            }
                            break;
                          case 'pt':
                            if (project.Translations && project.Translations.Pt) {
                                project.Name = project.Translations.Pt.Name;
                              project.Description = `${project.Translations.Pt.Description}`;
                            }
                            break;
            
                          default:
                           project.Description =`${project.Description}`;
                            break;
                        }
                      });
                  }
                    resolve(response.data); 
                }
            );
        });
       return await promise;
    }

    public async getUserAppsMemory():  Promise<Project[]> {
      // return new Promise<Project[]>((resolve) => {
      //     this.getUserApps().then((x) => {
      //       resolve(x);
      //     })
      // });
      return this.getUserApps();
    }

    public async getAdminUserApps():  Promise<Project[]> {
      const promise: Promise<Project[]> = new Promise<Project[]>((resolve,reject) => {
          axios.get<Project[], AxiosResponse<Project[]>>(`${process.env.REACT_APP_API}/apps/getAdminUserApps`).then(
              admUserAppsResponse => {
                if(admUserAppsResponse.data) {
                  admUserAppsResponse.data.forEach(admProject => {
                    admProject.ShowEnter = true;
                      switch (this.locale) {
                        case 'pt':
                          if (admProject.Translations && admProject.Translations.Pt) {
                            admProject.Name = admProject.Translations.Pt.Name;
                            admProject.Description = `${admProject.Translations.Pt.Description}`;
                          }
                          break;
                          
                        case 'es':
                          if (admProject.Translations && admProject.Translations.Es) {
                            admProject.Name = admProject.Translations.Es.Name;
                            admProject.Description = `${admProject.Translations.Es.Description}`;
                          }
                          break;
          
                        default:
                          admProject.Description =`${admProject.Description}`;
                          break;
                      }
                    });
                }
                  resolve(admUserAppsResponse.data); 
              }
          );
      });
     return await promise;
  }

    public async addAppUser(data: any): Promise<any> {
        return axios.post(`${process.env.REACT_APP_API}/admin/addAppUser`,
            data
        );
    }
}