import axios, { AxiosResponse } from "axios";
import { DatasetLower } from '../../models/dataset.lower.model';
import { Dataset } from '../../models/dataset.model';
export class DatasetsService {
    private locale: string = 'en';
    constructor(props?: any) {
        if (props.locale) {
            this.locale = props.locale;
        }
    }

    public async getAllDatasets(apiKey: string): Promise<AxiosResponse<any[]>> {
        return axios.get(`${process.env.REACT_APP_API}/datasets/getDatasets?apiKey=${apiKey}`,
        );
    }

    public async getDatasetById(id: number, apiKey: string): Promise<AxiosResponse<any[]>> {
        return axios.get(`${process.env.REACT_APP_API}/datasets/getDatasetById?id=${id}&key=${apiKey}`
        );
    }

    public async deleteDataset(id: number): Promise<any> {
        return axios.post(`${process.env.REACT_APP_API}/admin/deleteDataset`,
        {"Id": id.toString()}
        ).catch(err => {throw err});
    }

    public async addDataset(dataset: DatasetLower): Promise<any> {
    return axios.post(`${process.env.REACT_APP_API}/admin/addDataset`, {
        "name": dataset.name,
        "slug": dataset.slug,
        "accessLevel": dataset.accessLevel,
        "config": dataset.config,
        "appId": dataset.appId,
        "repositoryType": dataset.repositoryType,
        "userId": null,
        "CreatedAt": new Date(),
        "ModifiedAt": null,
        "Description": null,
        "Owner": null,
        "key": null
    }
    );
  }

  public async updateDataset(dataset: DatasetLower): Promise<Dataset> {
    return axios.post(`${process.env.REACT_APP_API}/admin/updateDataset`, 
    {   "Id":dataset.id?.toString(),
        "Name": dataset.name,
        "Slug": dataset.slug,
        "AccessLevel": dataset.accessLevel,
        "Config": dataset.config,
        "AppId": dataset.appId?.toString(),
        "RepositoryType": dataset.repositoryType,
        "Key": dataset.key,
        "UserId": dataset.userId,
        "CreatedAt": dataset.createdAt,
        "ModifiedAt": new Date(),
        "Description": dataset.description,
        "Owner": dataset.owner,
    }
    );
  }

}