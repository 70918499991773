import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Project } from '../../models/project.model';
import { UserAppsService } from '../../services/users/userApps.service';
import moment from 'moment';
import { useWraperContext } from '../complements/Wrapper';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useMsal } from '../../services/auth/authProvider';
import { ProjectsService } from '../../services/projects/projects.service';
import ProjectFilter from '../../models/filters/project.filter';
import { Pagination } from '../../models/pagination.model';
import { getImageByCategory } from '../../services/general';
import { UsersService } from '../../services/users/users.service';
import AdministratorType from '../../models/administratorType.model';
import { INFRA_DIGITAL_APP_0 } from '../../globalSettings';
import Loading from '../loading/Loading.component';

function Projects(props?: any) {
  const { userInternal, user } = useMsal();

  const { locale } = useWraperContext();
  const [dataCardsProjects, setDataCardsProjects] = useState<Project[]>([]);
  const [allProjects, setAllProjects] = useState<Project[]>([]);
  const [pagination, setPagination] = useState<Pagination>();
  const [backendProjects, setBackendProjects] = useState<Project[]>([]);
  const [currentProjects, setCurrentProjects] = useState<Project[]>([]);
  const [isUserInternalPr, setIsUserInternalPr] = useState<boolean>(false);
  const [noHaveProjects, setNoHaveProjects] = useState<boolean>(false);
  const [loadMoreItems, setLoadMoreItems] = useState<boolean>(false);
  const [noRecords, setNoRecords] = useState<boolean>(false);

  const userAppsService = new UserAppsService({ locale: locale });
  const appsService = new ProjectsService({
    locale: locale
  });
  const usersService = UsersService({ locale: locale });

  const defaultPageSize = 12;
  const [loading, setLoading] = useState<boolean>(false);

  function evalAdmin(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (user) {
        const userName = usersService.getUsername(user);
        usersService.getIsAdminbyUsername(userName).then((result: AdministratorType) => {
          getData(result);
          resolve();
        });
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    function fetchBusiness() {
      if (userInternal) {
        setIsUserInternalPr(userInternal());
      }
      evalAdmin().then(() => {
        setLoading(false);
      });
    }

    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const getData = async (administratorType: AdministratorType) => {
    let response: Project[] = [];
    let filter: ProjectFilter = new ProjectFilter();
    if (props && props.projectFilter) {
      filter = props.projectFilter;
    }
    if (!filter.category && !filter.name) {
      if (userInternal) {
        if (userInternal() || administratorType.IsSuperUser) {
          response = await appsService.getAllItems(user?user:undefined);
          setAllProjects(response);
        } else {
          response = await userAppsService.getUserAppsMemory();
          setAllProjects(response);
        }
      }
    } else {
      response = await appsService.getProjectsByFilter(filter, allProjects);
    }

    if (response) {
      let dataCards: any = [];
      let projects: Project[] = response;
      if (projects.length > 0) {
        projects = projects.filter(s => s.IsActive === true && s.Slug?.toLowerCase() !== INFRA_DIGITAL_APP_0.toLowerCase());

        projects.forEach((projectR: Project) => {
          if (projectR && projectR.Description) {
            var description: any = undefined;
            if (projectR.Description.indexOf("\"En\":") !== -1 && projectR.Description.indexOf("\"Es\":") !== -1
              && projectR.Description.indexOf("\"Pt\":") !== -1) {
              description = JSON.parse(projectR.Description);

              switch (locale) {
                case 'en-US':
                case 'en':
                  description = description.En;
                  break;
                case 'es-ES':
                case 'es-US':
                case 'es':
                  description = description.Es;
                  break;
                case 'pt-PT':
                case 'pt':
                  description = description.Pt;
                  break;
              }
            } else {
              description = projectR.Description;
            }

            projectR.Description = description.toString();
          }
        });

        projects.sort((a, b) => {
          return a.LaunchDate && b.LaunchDate ?
            ((new Date(b.LaunchDate).getTime()) - (new Date(a.LaunchDate.toString())).getTime()) : -1;
        });
        setBackendProjects(projects);
        let paged = await appsService.getProjectsPaged(1, defaultPageSize, projects);
        if (paged[0] && paged[0].totalPages && paged[0].totalPages > 1) {
          setLoadMoreItems(true);
        }

        setPagination(paged[0]);
        projects = paged[1];
        setCurrentProjects(projects);
        dataCards = loadDatacards(projects);
        setNoHaveProjects(false);
      } else {
        setNoHaveProjects(true);
      }
      dataCards = dataCards.filter((s: any) => s.title.toLowerCase() !== INFRA_DIGITAL_APP_0.toLowerCase());
      setDataCardsProjects(dataCards);
      
      if(dataCards.length > 0) {
        setNoRecords(false);
      } else {
        setNoRecords(true);
      }

    }
  }

  function loadDatacards(projects: Project[]) {
    let dataCards: any = [];
    projects.forEach((project: Project, index: number) => {
      if (project.IsActive) {
        let image = null;
        if (project.Category) {
          image = (project.Thumbnail) ? process.env.REACT_APP_CLOUD_FRONT + "/" + project.Thumbnail :
            (getImageByCategory(project.Category));
        }
        dataCards.push({
          id: project.Id,
          img: image,
          title: project.Name,
          dateActive: (project.LaunchDate !== null),
          date: moment(project.LaunchDate).format('MMMM DD, yyyy'), //'April 19, 2021'
          description: project.Description,
          // TODO: add real data
          infReadMore: true,
          infEnter: project.ShowEnter,
          url: project.Url,
          category: project.Category,
          Slug: project.Slug,
          Finished: project.Finished
        });
      }
    });
    setLoading(false);
    return dataCards;
  }

  const paginate = async (event: any) => {
    if (pagination && pagination.pageNumber) {
      const pageNumber = pagination.pageNumber + 1;
      if (pageNumber === pagination.totalPages) {
        setLoadMoreItems(false);
      }
      let paged = await appsService.getProjectsPaged(pageNumber, defaultPageSize, backendProjects);
      setPagination(paged[0]);
      let projects: Array<Project> = [];
      if (currentProjects && currentProjects.length > 0) {
        projects = currentProjects.concat(paged[1]);
      } else {
        projects = paged[1];
      }

      setCurrentProjects(projects);
      let dataCards = loadDatacards(projects);
      setDataCardsProjects(dataCards);

    }
  }

  function projectItems() {
    return (
      dataCardsProjects && dataCardsProjects.map((data: any, index: number) => {
        return (

          <div key={index} className="col-12 col-md-4">
            <article className={data.category}>
              <div className="gestor">
                <img src={data.img} alt="" />
                {!data.Finished && <span className="soon">
                  <FormattedMessage id={'home.coming.soon'} />
                </span>}
              </div>
              <div className="contenido">
                <h3 className="title">{data.title}</h3>
                <p>
                  {data.dateActive &&
                    <span>
                      <FormattedDate
                        value={new Date(data.date)}
                        year="numeric"
                        month="long"
                        day="2-digit"
                      />
                    </span>
                  }
                </p>
                {(data.description !== null) && <p>{data.description}</p>}
                <div className="lnk-btn">
                  {data.infReadMore && (
                    <button key={index}>
                      <Link
                        to={
                          `/info/${data.Slug}`
                        }
                      >
                        <FormattedMessage id={'home.button.readmore'} />
                      </Link>
                    </button>
                  )}
                  {data.infEnter && data.Finished && (
                    <button className="enter">
                      <Link to={{ pathname: data.url }} target="blank">
                        <FormattedMessage id={'home.button.enter'} />
                      </Link>
                    </button>
                  )}
                </div>
              </div>
            </article>
          </div>
        );
      })
    );
  }
  return (
    <React.Fragment>
      {!loading && (
        <section id="projects">
          <div className="contain">
            <div className={isUserInternalPr ? 'row' : 'row mt-5'}>
              {noHaveProjects && (
                <div className="alert alert-warning expand-bottom-empty">
                  <FormattedMessage id={'home.projects.empty'} />
                </div>
              )}
              {projectItems()}
            </div>
            {dataCardsProjects.length > 0 && loadMoreItems && (
              <div className="row justify-content-center">
                <div className="col-4 d-flex">
                  <div className="loadMoreItems">
                    <button onClick={paginate}>
                      <FormattedMessage id={'home.button.readmore'} />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      )}
      {noRecords && (
        <div className="alert alert-info expand-bottom-empty">
          <FormattedMessage id={'home.projects.search.empty'} />
        </div>
      )}
      {loading &&
        <div className="spinner-position-bottom">
          <Loading></Loading>
        </div>
      }
    </React.Fragment>
  );
}
export default Projects;