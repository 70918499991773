import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { AppContact } from "../../../models/appContact";
import { AppTeam } from "../../../models/appTeam.model";
import { Project } from "../../../models/project.model";
import { User } from "../../../models/user.model";
import { useMsal } from "../../../services/auth/authProvider";
import { useWraperContext } from "../../complements/Wrapper";

export function ProjectFormConsts(){
    const paramsId: any = useParams<{}>();
    const intl = useIntl();
    const {setValue, register, handleSubmit, formState: { errors }} = useForm();
    const { locale } = useWraperContext();
    const anchor = 'pointer';
  
 const [currentProject, setCurrentProject] = useState<Project>(new Project());
 const [summaryEN, setSummaryEN] = useState<string>('');
 const [summaryES, setSummaryES] = useState<string>('');
 const [summaryPT, setSummaryPT] = useState<string>('');
 const [descriptionEN, setDescriptionEN] = useState<string>('');
 const [descriptionES, setDescriptionES] = useState<string>('');
 const [descriptionPT, setDescriptionPT] = useState<string>('');
 const [appContacts, setAppContacts] = useState<AppContact[]>([]);
 const [deletedTemAppContacts, setDeletedTempAppContacts] = useState<AppContact[]>([]);
 const [appTeams, setAppTeams] = useState<AppTeam[]>([]);
 const [launchDate, setLaunchDate] = React.useState(new Date());
 const history = useHistory();
 const changePublicationDate = ({ value }: any) => { setLaunchDate(value); };
 const [showUpload, setShowUpload] = useState<boolean>(true);
 const [showUploadS, setShowUploadS] = useState<boolean>(true);
 const categories2 = [
   {
     id: 'energy',
     name: intl.formatMessage({
       id: 'admin.datasets.access.level.public',
     }),
   },
   {
     id: 'private-transport',
     name: intl.formatMessage({
       id: 'admin.datasets.access.level.private',
     }),
   },
   {
     id: 'water',
     name: intl.formatMessage({
       id: 'admin.datasets.access.level.private',
     }),
   },
   {
     id: 'tools',
     name: intl.formatMessage({
       id: 'admin.datasets.access.level.private',
     }),
   },
 ];
 const categories = ["energy", "transport", "water", "tools"];
 const [stateCategory, setStateCategory] = React.useState({ value: '' });
 const [users, setUsers] = useState<User[]>([]);
 const [successfullSaved, setSuccessfullSaved] = useState<boolean>(false);
 const [isError, setIsError] = useState<boolean>(false);
 const [submited, setSubmited] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const defaultContact: AppContact = { NameContact: '', Emailcontact: '' };
 const [currentContact, setCurrentContact] = useState<AppContact>(defaultContact);
 const [mainPictureError, setMainPictureError] = useState<boolean>(false);
 const [contactInfoSubmited, setContactInfoSubmited] = useState<boolean>(false);
 const [selectedUser, setSelectedUser] = React.useState({ value: defaultUser });
 const [selectedOwner, setSelectedOwner] = React.useState({ value: defaultUser });
 const avaliableScreenshotExtension = ['.jpg', '.png', '.gif', '.jpeg', '.jfif'];
 const avaliableMainExtension = ['.jpg', '.png', '.gif', '.jpeg', '.jfif'];
 const maxFilePermitted = (300 * 1000000);
 const [existsTeamUser, setExistsTeamUser] = useState<boolean>(false);
 const [submitedUser, setSubmitedUser] = useState<boolean>(false);
 const [isActiveValue, setIsActiveValue] = useState<string>("false");
 const [isFinishedValue, setIsFinishedValue] = useState<string>("false");
 const [isBlocked, setIsBlocked] = useState<boolean>(false);
 const [files, setFiles] = React.useState<any[]>([]);
 const [events, setEvents] = React.useState<any[]>([]);
 const [filePreviews, setFilePreviews] = React.useState({});
 const [affectedFiles, setAffectedFiles] = React.useState([]);
 const [filesMain, setFilesMain] = React.useState<any[]>([]);
 const [eventsMain, setEventsMain] = React.useState<any[]>([]);
 const [filePreviewsMain, setFilePreviewsMain] = React.useState({});
 const [affectedFilesMain, setAffectedFilesMain] = React.useState([]);
 const { isAuthenticated, getToken } = useMsal();
 const [token, setToken] = React.useState('');
 const [isAppMain, setIsAppMain] = React.useState(false);
 const [isNewMainImage, setIsNewMainImage] = useState<boolean>(false);
 const [isNewScreenshotsImage, setIsNewScreenshotsImage] = useState<boolean>(false);
 const [noReloadingScreenshots, setNoReloadingScreenshots] = useState<boolean>(true);
 const [fileSizeExceded, setFileSizeExceded] = useState<boolean>(false);
 const [fileTypeInvalid, setFileTypeInvalid] = useState<boolean>(false);
 const [fileTypeSInvalid, setFileTypeSInvalid] = useState<boolean>(false);
 const [screenshotfileSizeExceded, setScreenshotfileSizeExceded] = useState<boolean>(false);
 const [invalidSummaryEn, setInvalidSummaryEn] = useState<boolean>(false);
 const [invalidSummaryEs, setInvalidSummaryEs] = useState<boolean>(false);
 const [invalidSummaryPt, setInvalidSummaryPt] = useState<boolean>(false);
 const [invalidDescriptionEn, setInvalidDescriptionEn] = useState<boolean>(false);
 const [invalidDescriptionEs, setInvalidDescriptionEs] = useState<boolean>(false);
 const [invalidDescriptionPt, setInvalidDescriptionPt] = useState<boolean>(false);

  return ({
    paramsId,
      currentProject, setCurrentProject, 
      appContacts, setAppContacts,
      deletedTemAppContacts, setDeletedTempAppContacts,
      appTeams, setAppTeams,
    launchDate, setLaunchDate,
      history,
      changePublicationDate,
      showUpload, setShowUpload,
      showUploadS, setShowUploadS,
      categories,
      stateCategory, setStateCategory,
      users, setUsers,
      successfullSaved, setSuccessfullSaved,
      isError, setIsError,
      submited, setSubmited,
      loading, setLoading,
      defaultContact,
      currentContact, setCurrentContact,
      mainPictureError, setMainPictureError,
      contactInfoSubmited, setContactInfoSubmited,
      selectedUser, setSelectedUser,
      selectedOwner, setSelectedOwner,
      avaliableScreenshotExtension,
      avaliableMainExtension,
      maxFilePermitted,
      existsTeamUser, setExistsTeamUser,
      submitedUser, setSubmitedUser,
      isActiveValue, setIsActiveValue,
      isFinishedValue, setIsFinishedValue,
      isBlocked, setIsBlocked,
      files, setFiles,
      events, setEvents,
      filePreviews, setFilePreviews,
      affectedFiles, setAffectedFiles,
      filesMain, setFilesMain,
      eventsMain, setEventsMain,
      filePreviewsMain, setFilePreviewsMain,
      affectedFilesMain, setAffectedFilesMain,
      isAuthenticated, getToken,
      token, setToken,
      isAppMain, setIsAppMain,
      isNewMainImage, setIsNewMainImage,
      isNewScreenshotsImage, setIsNewScreenshotsImage,
      noReloadingScreenshots, setNoReloadingScreenshots,
      fileSizeExceded, setFileSizeExceded,
      fileTypeInvalid, setFileTypeInvalid,
      fileTypeSInvalid, setFileTypeSInvalid,
      screenshotfileSizeExceded, setScreenshotfileSizeExceded,
      setValue, register, handleSubmit, locale, anchor, errors, intl,
      summaryEN, setSummaryEN,summaryES, setSummaryES, summaryPT, setSummaryPT,
      descriptionEN, setDescriptionEN, descriptionES, setDescriptionES, descriptionPT, setDescriptionPT,
      invalidSummaryEn, setInvalidSummaryEn,
      invalidSummaryEs, setInvalidSummaryEs,
      invalidSummaryPt, setInvalidSummaryPt,
      invalidDescriptionEn, setInvalidDescriptionEn,
      invalidDescriptionEs, setInvalidDescriptionEs,
      invalidDescriptionPt, setInvalidDescriptionPt
  })
}
export  const defaultUser: User = {
    Id: 0,
    Username: '',
    Sub: '',
    IsInternal: false,
    IsAdmin: false,
    CreatedAt: new Date(),
    Inactive: true,
    Infra2_V_GroupsByUsers: []
  };

