import React, { useEffect, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useWraperContext } from "../../complements/Wrapper";
import { Grid, GridColumn, GridFilterCellProps, GridFilterChangeEvent, GridNoRecords, GridPageChangeEvent } from "@progress/kendo-react-grid";
import { useHistory } from 'react-router-dom';

import "../../../stylesheets/admin/_admin.scss";
import { ProjectsService } from "../../../services/projects/projects.service";
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { PageState } from '../../../models/pagestate.model';
import { GroupView } from '../../../models/groupView.model';
import { confirmAlert } from 'react-confirm-alert';
import { Dataset } from '../../../models/dataset.model';
import { DatasetsService } from '../../../services/datasets/datasets.service';
import { Alert } from "react-bootstrap";
import { DropdownFilterCell } from "../../common/dropdownFilterCell";
import { Project } from "../../../models/project.model";
import AdministratorType from "../../../models/administratorType.model";
import { useMsal } from "../../../services/auth/authProvider";
import { UsersService } from "../../../services/users/users.service";
import { UserAppsService } from "../../../services/users/userApps.service";
import Loading from '../../loading/Loading.component';

function DatasetsAdmin() {
  const intl = useIntl();
  const { locale } = useWraperContext();
  const initialFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const { user } = useMsal();
  const usersService = UsersService({ locale: locale });
  const userAppsService = new UserAppsService({ locale: locale });
  const projectsService = new ProjectsService({
    locale: locale
  });
  const [projects, setProjects] = useState<Project[]>([]);
  const [datasets, setDatasets] = useState<any[]>([]);
  const initialDataState: PageState = { skip: 0, take: 10 };
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [filter, setFilter] = React.useState(initialFilter);
  const [dataTranslates] = useState<IntlShape>(useIntl());
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const datasetsService = new DatasetsService({ locale: locale });
  const [isError, setIsError] = useState<boolean>(false);
  const [successfullSaved] = useState<boolean>(false);
  const generalYes = 'general.yes';
  const generalNo = 'general.no';

  const apiKey = process.env.INFRA_API_KEY ? process.env.INFRA_API_KEY : '';

  const enterEdit = async (item: GroupView) => {
    history.push({
      pathname: `/admin/datasets/form/${item.Id}`,
      state: {},
    });
  };
  const handleClickDelete = async (item: Dataset) => {
    setLoading(true);
    try {
      await datasetsService.deleteDataset(Number(item.Id));
      await getData();
      setIsDeleted(true);
      setTimeout(() => {
        setIsDeleted(false);
      }, 4000);
    } catch (err) {
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 4000);
    }
    setLoading(false);
  };
  const enterDelete = async (item: Dataset) => {
    const options = {
      title: intl.formatMessage({
        id: 'general.delete',
      }),
      message: intl.formatMessage({
        id: 'general.deleteItemMessage',
      }),
      buttons: [
        {
          label: intl.formatMessage({
            id: generalYes,
          }),
          onClick: () => {
            handleClickDelete(item);
          },
        },
        {
          label: intl.formatMessage({
            id: generalNo,
          }),
          onClick: () => {/*do something here*/ },
        },
      ],
      childrenElement: () => <div />,
      // customUI: ({ onClose }) => <div>Custom UI</div>,
      closeOnEscape: true,
      closeOnClickOutside: true,
      willUnmount: () => {/*do something here*/ },
      afterClose: () => {/*do something here*/ },
      onClickOutside: () => {/*do something here*/ },
      onKeypressEscape: () => {/*do something here*/ },
      overlayClassName: 'overlay-custom-class-name',
    };
    confirmAlert(options);
  };
  const dataCrud = {
    confirm: {
      title: dataTranslates.formatMessage({
        id: 'confirm.to.submit.title',
      }),
      description: dataTranslates.formatMessage({
        id: 'confirm.to.submit.description',
      }),
      yes: dataTranslates.formatMessage({
        id: generalYes,
      }),
      no: dataTranslates.formatMessage({
        id: generalNo,
      }),
    },
  };

  const MyCrudCommandCell = (props: any) => (
    <CrudCommandCell
      {...props}
      data={dataCrud}
      enterEdit={enterEdit}
      enterDelete={enterDelete}
    />
  );
  const CrudCommandCell = (props: any) => {
    return (
      <td>
        <button
          className="k-button k-primary"
          onClick={() => props.enterEdit(props.dataItem)}
        >
          <FormattedMessage id={'general.edit'} />
        </button>
        <button
          className="k-button btn pl-2"
          onClick={() => props.enterDelete(props.dataItem)}
        >
          <FormattedMessage id={'general.delete'} />
        </button>
      </td>
    );
  };

  const getData = async () => {
    setLoading(true);
    let admType = new AdministratorType();
    if (user) {
      const userName = usersService.getUsername(user);
      admType = await usersService.getIsAdminbyUsername(userName);
    }
    const datasetsResult = await datasetsService.getAllDatasets(
      apiKey
    );
    let projectsResult: Project[] = [];

    if (admType.IsSuperUser) {
      projectsResult = await projectsService.getAllItems();
      setProjects(projectsResult);
    } else if (admType.IsAdminGroup) {
      projectsResult = await userAppsService.getAdminUserApps();
      setProjects(projectsResult);

    }

    let result: Dataset[] = [];
    datasetsResult.data.forEach((dataset: Dataset) => {
      if (projectsResult.filter(s => s.Id === dataset.AppId).length > 0) {
        result.push(dataset);
      }
    });

    setDatasets(result);
    setLoading(false);
  }



  useEffect(() => {
    function fetchBusiness() {
      setLoading(true);
      getData();
    }
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewDataset = async (event: any) => {
    history.push({
      pathname: '/admin/datasets/form',
      state: {},
    });
  }

  const pageChange = (event: GridPageChangeEvent) => {
    setPage(event.page);
  };

  const ProjectsFilterCell: any = (props: GridFilterCellProps) => (
    <DropdownFilterCell
      {...props}
      data={projects.map(project => {
        return {
          text: project.Name as string,
          value: project.Name as string
        }
      })}
      defaultItem={"All"}
    />
  );

  const onFilterChange = (eventFilter: any) => {
    setPage(initialDataState);
    setFilter(eventFilter);
  };

  return (
    <React.Fragment>
      {isDeleted && (
        <Alert variant="success">
          <FormattedMessage id={'general.successfullDeleted'} />
        </Alert>
      )}
      {successfullSaved && (
        <Alert variant="success">
          <FormattedMessage id={'general.successfullSaved'} />
        </Alert>
      )}
      {isError && (
        <Alert variant="warning">
          <FormattedMessage id={'general.error'} />
        </Alert>
      )}
      <div className="module-pjs">
        <div className="header-pj">
          <h2>
            <FormattedMessage id={'admin.datasets.title'} />
          </h2>

          <button className="btn btn-primary" onClick={addNewDataset}>
            <FormattedMessage id={'admin.datasets.AddNew'} />
          </button>
        </div>
        <div className="content-body">
          {datasets.length > 0 && (
            <Grid
              sortable={true}
              data={filterBy(datasets, filter).slice(
                page.skip,
                page.take + page.skip
              )}
              filterable={true}
              filter={filter}
              onFilterChange={(e: GridFilterChangeEvent) =>
                onFilterChange(e.filter)
              }
              groupable={false}
              reorderable={true}
              // pageable={{
              //     buttonCount: 10,
              //     pageSizes: false,
              // }}
              skip={page.skip}
              take={page.take}
              pageable={true}
              onPageChange={pageChange}
              total={filterBy(datasets, filter).length}
              expandField="expanded"
              className="hidde-scroll-horizontal"
            >
              <GridNoRecords>
                <FormattedMessage id={'general.norecords'} />
              </GridNoRecords>
              <GridColumn
                field="Name"
                title={intl.formatMessage({
                  id: 'admin.datasets.nameTitle',
                })}
                width="500px"
              />
              <GridColumn
                field="Infra2_App.AppName"
                title={intl.formatMessage({
                  id: 'admin.datasets.app',
                })}
                filterCell={ProjectsFilterCell}
                width="600px"
              />
              <GridColumn
                cell={MyCrudCommandCell}
                width="240px"
                title={intl.formatMessage({
                  id: 'general.actions',
                })}
                filterable={false}
              />
            </Grid>
          )}
        </div>
      </div>
      {loading && (
        <Loading></Loading>
      )}
    </React.Fragment>
  );
}

export default DatasetsAdmin;
