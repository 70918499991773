import { AppTeam } from "./appTeam.model";
import { Translations } from "./Translations.model";

export class Project {
  Id?: number;
  Name?: string;
  Slug?: string;
  IsShowcased?: boolean;
  Category?: string;
  Summary?: string;
  SummaryEN?: string;
  SummaryES?: string;
  SummaryPT?: string;
  Description?: string;
  DescriptionEN?: string;
  DescriptionES?: string;
  DescriptionPT?: string;
  Thumbnail?: string; //url de imagen principal,
  Url?: string; // url de la app. ej. https://pavimentados.infradinamica.org
  Team?: AppTeam[];
  VendorName?: string;
  ContactInfo?: string;
  contactInfo?: any;
  LaunchDate?: Date;
  LaunchDateString?: string;
  IsActive?: boolean;
  IsActiveString?: string;
  Translations?: Translations;
  Screenshots?: string | null;
  Config?: string;
  PublicationDate?: Date;
  WebSiteLink?: string;
  TeamLeader?: string;
  //Computed
  ShowEnter?: boolean = true;
  Finished?: boolean = false;
  IsDeleted?: boolean = false;
}