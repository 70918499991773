import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { User } from "../../../models/user.model";
import { useForm } from "react-hook-form";
import { UsersService } from "../../../services/users/users.service";
import { useWraperContext } from "../../complements/Wrapper";
import { useHistory, useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import Loading from '../../loading/Loading.component';

export interface Role {
	value: string;
}

function UserFormAdmin() {
	const intl = useIntl();
	const history = useHistory();
	const defaultUser = {
		Id: 0,
		Username: '',
		Sub: '',
		IsInternal: false,
		IsAdmin: false,
		CreatedAt: new Date(),
		Inactive: false
	};
	const [currentUser, setCurrentUser] = useState<User>(defaultUser);
	const [roles, setRoles] = useState<Role[]>([]);
	const [stateRole, setStateRole] = useState({
		value: { value: '' }
	});
	const { register, handleSubmit, setValue, formState: { errors } } = useForm();
	const { locale } = useWraperContext();
	const usersService = UsersService({ locale: locale });
	const paramsId: any = useParams<{}>();
	const [successfullSaved, setSuccessfullSaved] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [isAdminValue, setIsAdminValue] = useState<string>("false");
	const [isInternalValue, setIsInternalValue] = useState<string>("false");
	const [isActiveValue, setIsActiveValue] = useState<string>("true");
	const [isBlocked, setIsBlocked] = useState<boolean>(false);

  const getData = () => {
		setLoading(true);
		const id: number = paramsId['id'];
		if (id) {
			usersService.getUsers().then(response => {
				const current = response.data.filter((s: User) => s.Id === Number(id));
				if (current) {
					const user: User = current[0];
					setCurrentUser(current[0]);
					setValue("userName", user.Username);
					setValue("name", user.Name);
					setIsActiveValue(user.Inactive === true ? "false" : "true");

					setValue("isAdmin", user.IsAdmin);
					setIsAdminValue(user.IsAdmin === true ? "true" : "false");
					setValue("isInternal", user.IsInternal);
					setIsInternalValue(user.IsInternal === true ? "true" : "false");
					setValue("createdAt", user.CreatedAt);
					setValue("sub", user.Sub);
					if (user.Role) {
						setStateRole({ value: { value: user.Role } });
					}
				}
        setIsBlocked(false);
				setLoading(false);
			});
		} else {
      setIsBlocked(false);
			setLoading(false);
		}
	}

  const getRoles = () => {
		const rolesResult = usersService.getRoles().map(role => {
			return { value: role };
		});

		setRoles(rolesResult);
	}

  
  
	useEffect(() =>{
    function fetchBusiness () {
      const id: number = paramsId['id'];
      if (id) {
        setIsBlocked(true);
      }
      getRoles();
      setCurrentUser(defaultUser);
      getData();
    }
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

	const onSubmit = async (data: any) => {
		setLoading(true);
		try {

			if (data && data.userName) {
				const user: User = {
					Id: currentUser.Id,
					Username: data.userName as string,
					Name: data.name,
					// Role: stateRole.value.value,
					CreatedAt: new Date(),
					Inactive: isActiveValue === "true" ? false : true as boolean,
					IsAdmin: isAdminValue === "true" ? true : false as boolean,
					IsInternal: isInternalValue === "true" ? true : false as boolean,
					Sub: '',
				};
					if (currentUser.Id === 0) {
						await usersService.addUser(user);
						setLoading(false);
					} else {
						await usersService.updateUser(user);
						setLoading(false);
					}
					setSuccessfullSaved(true);
				
				setTimeout(() => {
					setSuccessfullSaved(false);
					history.push({
						pathname: '/admin/users/',
						state: {},
					});
				}, 4000);
			}
		} catch (err) {
      console.log('err:' +JSON.stringify(err));
			setLoading(false);

			setIsError(true);
			setTimeout(() => {
				setIsError(false);
			}, 4000);
		}
	}

	const handleChangeRole = (event: DropDownListChangeEvent) => {
		const role: Role = event.target.value;
		setStateRole({
			value: role
		});
	};

	return (
    <React.Fragment>
      {successfullSaved && (
        <Alert variant="success">
          <FormattedMessage id={'general.successfullSaved'} />
        </Alert>
      )}
      {isError && (
        <Alert variant="warning">
          <FormattedMessage id={'general.error'} />
        </Alert>
      )}
      <div className={!loading ? 'module-pjs' : 'module-pjs d-none'}>
        <div className="header-pj">
          <h2>
            <FormattedMessage id={'admin.users.title'} />
          </h2>
        </div>
        <div className="hide-scroll-horizontal-bodies content-body">
          <form
            className={isBlocked ? 'form noClick' : 'form'}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="row mt-4">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <FormattedMessage id={'admin.users.form.userName.label'}>
                    {(msg: any) => (
                      <div>
                        <label label-for="userName">{msg} </label>
                        <span style={{ color: 'darkred' }}>*</span>
                        <input
                          {...register('userName', {
                            required: true,
                            maxLength: 255,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: 'invalid email address',
                            },
                          })}
                          id="userName"
                          className="fields"
                          type="text"
                          placeholder={msg}
                          onChange={(e) => setValue('userName', e.target.value)}
                          defaultValue={currentUser.Username}
                        ></input>
                      </div>
                    )}
                  </FormattedMessage>
                  {errors.userName && (
                    <span style={{ color: 'darkred' }}>
                      <FormattedMessage id="admin.users.form.userName.required" />
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <FormattedMessage id="admin.users.form.name.label">
                    {(msg: any) => (
                      <div>
                        <label label-for="name">{msg}</label>
                        <span style={{ color: 'darkred' }}>*</span>
                        <input
                          {...register('name', {
                            required: true,
                            maxLength: 200,
                          })}
                          id="name"
                          className="fields"
                          type="text"
                          placeholder={msg}
                        ></input>
                      </div>
                    )}
                  </FormattedMessage>
                  {errors.name && (
                    <span style={{ color: 'darkred' }}>
                      <FormattedMessage id="admin.users.form.name.required" />
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 d-none">
                <div className="form-group">
                  <label label-for="role">
                    {intl.formatMessage({
                      id: 'admin.users.form.role.label',
                    })}
                  </label>
                  {roles.length > 0 && (
                    <DropDownList
                      {...register('role', {
                        required: true,
                      })}
                      name="role"
                      className="drop-field"
                      textField="value"
                      dataItemKey="value"
                      data={roles}
                      defaultValue={stateRole.value}
                      defaultItem={intl.formatMessage({
                        id: 'admin.users.form.role.label',
                      })}
                      onChange={handleChangeRole}
                    ></DropDownList>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <div className="d-flex mt-5">
                    <strong>
                      {intl.formatMessage({
                        id: 'admin.users.form.isInternal.label',
                      })}
                    </strong>

                    <label className="radio-btn">
                      Yes
                      <input
                        type="radio"
                        {...register('isInternal')}
                        id="isInternal"
                        onChange={(e) => {
                          setValue('isInternal', true);
                          setIsInternalValue(e.target.value);
                        }}
                        checked={isInternalValue === 'true'}
                        value="true"
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio-btn">
                      No
                      <input
                        type="radio"
                        {...register('isInternal')}
                        id="isInternal"
                        onChange={(e) => {
                          setValue('isInternal', false);
                          setIsInternalValue(e.target.value);
                        }}
                        checked={isInternalValue === 'false'}
                        value="false"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  
                </div>
              </div>
              <div className="col-12 col-md-6 mt-5">
                <div className="d-flex-column">
                  <div className="d-flex">
                    <FormattedMessage id={'admin.users.form.isAdmin.label'} />

                    <label className="radio-btn">
                      Yes
                      <input
                        type="radio"
                        {...register('isAdmin')}
                        id="isAdmin"
                        onChange={(e) => {
                          setValue('isAdmin', true);
                          setIsAdminValue(e.target.value);
                        }}
                        checked={isAdminValue === 'true'}
                        value="true"
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio-btn">
                      No
                      <input
                        type="radio"
                        {...register('isAdmin')}
                        id="isAdmin"
                        onChange={(e) => {
                          setValue('isAdmin', false);
                          setIsAdminValue(e.target.value);
                        }}
                        checked={isAdminValue === 'false'}
                        value="false"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <input
                type="hidden"
                id="createdAt"
                {...register('createdAt')}
              ></input>
              <input type="hidden" id="sub" {...register('sub')}></input>
            </div>
            <div className="row justify-content-end">
              <div className="col-12 col-md-6">
                <div className="d-flex">
                  <FormattedMessage id={'admin.users.form.isActive.label'} />

                  <label className="radio-btn">
                    Yes
                    <input
                      type="radio"
                      {...register('inactive')}
                      id="inactive"
                      onChange={(e) => {
                        setValue('inactive', false);
                        setIsActiveValue('true');
                      }}
                      checked={isActiveValue === 'true'}
                      value="true"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="radio-btn">
                    No
                    <input
                      type="radio"
                      {...register('inactive')}
                      id="inactive"
                      onChange={(e) => {
                        setValue('inactive', true);
                        setIsActiveValue('false');
                      }}
                      checked={isActiveValue === 'false'}
                      value="false"
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <button
                  type="submit"
                  className="newPj"
                 
                >
                  <FormattedMessage id={'admin.users.form.save'} />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && (
        <Loading></Loading>
      )}
    </React.Fragment>
  );
}

export default UserFormAdmin;
