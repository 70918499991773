import './groupForm.admin.scss';
import {
  DropDownList,
} from "@progress/kendo-react-dropdowns";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { Group } from "../../../models/group.model";
import { Project } from "../../../models/project.model";
import { User } from "../../../models/user.model";
import { UserGroupView } from "../../../models/userGroupView.model";
import { ProjectsService } from "../../../services/projects/projects.service";
import { UsersService } from "../../../services/users/users.service";
import { useWraperContext } from "../../complements/Wrapper";
import AdministratorType from '../../../models/administratorType.model';
import { useMsal } from '../../../services/auth/authProvider';
import { UserAppsService } from '../../../services/users/userApps.service';
import Loading from '../../loading/Loading.component';
import { GroupFormController } from './groupForm.controller';
import { defaultGroup, defaultUser } from './groupForm.consts';
import GroupFormAlerts from './groupForm.Alerts';

function GroupFormAdminConstants() {
  const {
    register, handleSubmit, setValue, formState: { errors } } = useForm();
  const { locale } = useWraperContext();
  const intl = useIntl();
  const { user } = useMsal();
  const [administratorType, setAdministratorType] = useState<AdministratorType>({ IsSuperUser: false, IsAdminGroup: false });
  const [currentGroup, setCurrentGroup] = useState<Group>(defaultGroup);
  const [userGroups, setUserGroups] = useState<UserGroupView[]>([]);
  const [selectedUser, setSelectedUser] = React.useState({ value: defaultUser });
  const [deletedTempUserGroups, setDeletedTempUserGroups] = useState<UserGroupView[]>([]);
  const [stateApp, setStateApp] = React.useState({ value: new Project() });
  const [isAppMain, setIsAppMain] = React.useState(false);
  const [apps, setApps] = useState<Project[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const history = useHistory();
  const paramsId: any = useParams<{}>();
  const userAppsService = new UserAppsService({ locale: locale });
  const projectService = new ProjectsService({ locale: locale });
  const usersService = UsersService({ locale: locale });
  const [successfullSaved, setSuccessfullSaved] = useState<boolean>(false);
  const [submited, setSubmited] = useState<boolean>(false);
  const [submitedUser, setSubmitedUser] = useState<boolean>(false);
  const [isAdminValue, setIsAdminValue] = useState<string>("false");
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isReadOnlyGroupValue, setIsReadOnlyGroupValue] = useState<string>("false");
  const [existsGroupUser, setExistsGroupUser] = useState<boolean>(false);
  const [isUserDefaultGroupValue, setIsUserDefaultGroupValue] = useState<string>("false");
  const maxB2CUsers = 10;
  const [allowAddB2CUser, setAllowAddB2CUser] = useState<boolean>(true);
  const [isRequiredOwner, setIsRequiredOwner] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOwnerGroup, setIsOwnerGroup] = useState<boolean>(false);
  const btnDissabledClass = 'btn disabled';

  return ({
    locale, intl, user, administratorType, setAdministratorType, currentGroup, setCurrentGroup,
    userGroups, setUserGroups, selectedUser, setSelectedUser, deletedTempUserGroups, setDeletedTempUserGroups,
    stateApp, setStateApp, isAppMain, setIsAppMain, apps, setApps, users, setUsers, history, paramsId,
    userAppsService, projectService, usersService, successfullSaved, setSuccessfullSaved,
    submited, setSubmited, submitedUser, setSubmitedUser, isAdminValue, setIsAdminValue, isBlocked, setIsBlocked,
    isReadOnlyGroupValue, setIsReadOnlyGroupValue, existsGroupUser, setExistsGroupUser, 
    isUserDefaultGroupValue, setIsUserDefaultGroupValue, maxB2CUsers, allowAddB2CUser, setAllowAddB2CUser,
    isRequiredOwner, setIsRequiredOwner, isError, setIsError, loading, setLoading,
    isOwnerGroup, setIsOwnerGroup, btnDissabledClass, register, handleSubmit, setValue, errors
  });
}

function GroupFormAdmin() {
  
  
  const cn = GroupFormAdminConstants();
    const controller = GroupFormController(cn);



  useEffect(() => {
    function fetchBusiness() {
      const id: number = cn.paramsId['id'];
      if (id) {
        cn.setIsBlocked(true);
      }
      cn.setLoading(true);
      if (cn.user) {
        const userName = cn.usersService.getUsername(cn.user);
        cn.usersService.getIsAdminbyUsername(userName).then(admType => {
          cn.setAdministratorType(admType);
          cn.setCurrentGroup(defaultGroup);
          controller.getUsers().then(responseUsers => {
            cn.setUsers(responseUsers.data);
          });
          controller.getProjects(admType).then((response: any) => {
            cn.setApps(response);
            controller.getData(response);
          });
        });
      }
    }
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <GroupFormAlerts successfullSaved={cn.successfullSaved}
        isError={cn.isError} isRequiredOwner={cn.isRequiredOwner}
        existsGroupUser={cn.existsGroupUser} />
      {
        <div className={!cn.loading ? 'module-pjs' : 'module-pjs d-none'}>
          <div className="header-pj">
            <h2>
              <FormattedMessage id={'admin.groups.title'} />
            </h2>
          </div>
          <div className="hide-scroll-horizontal-bodies content-body">
            <form
              className={cn.isBlocked ? 'form noClick' : 'form'}
              onSubmit={cn.handleSubmit(controller.onSubmit)}
            >
              <div className="row justify-content-center">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <span>
                      <FormattedMessage
                        id={'admin.groups.form.appName.title'}
                      />
                    </span>
                    <span style={{ color: 'darkred' }}>*</span>
                    <DropDownList
                      disabled={cn.isAppMain || cn.isOwnerGroup}
                      {...cn.register('appId', {
                        required: true,
                        min: 1,
                      })}
                      name="appId"
                      className="drop-field"
                      textField="Name"
                      dataItemKey="Id"
                      data={cn.apps}
                      defaultValue={cn.stateApp.value}
                      defaultItem={cn.intl.formatMessage({
                        id: 'admin.groups.form.appName.placeHolder',
                      })}
                      onChange={controller.handleChangeApp}
                    ></DropDownList>

                    {cn.submited &&
                      (!cn.stateApp.value ||
                        (cn.stateApp.value && !cn.stateApp.value.Id)) && (
                        <span style={{ color: 'darkred' }}>
                          <FormattedMessage id="admin.groups.form.appName.required" />
                        </span>
                      )}
                  </div>
                  {cn.errors.appId && (
                    <span style={{ color: 'darkred' }}>
                      <FormattedMessage id="admin.groups.form.appName.required" />
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <span>
                      <FormattedMessage
                        id={'admin.groups.form.groupName.title'}
                      />
                    </span>
                    <span style={{ color: 'darkred' }}>*</span>
                    <input
                      disabled={cn.isAppMain || cn.isOwnerGroup}
                      {...cn.register('groupName', {
                        required: true,
                        maxLength: 255,
                      })}
                      id="groupName"
                      type="text"
                      className="fields"
                      placeholder={cn.intl.formatMessage({
                        id: 'admin.groups.form.groupName.title',
                      })}
                      onChange={(e) => cn.setValue('groupName', e.target.value)}
                      defaultValue={cn.currentGroup.Name}
                    ></input>
                  </div>
                  {cn.submited && cn.errors.groupName && (
                    <span style={{ color: 'darkred' }}>
                      <FormattedMessage id="admin.groups.form.groupName.required" />
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-6 d-none">
                  <div className="form-group">
                    <div className="d-flex-column">
                      <div className="d-flex">
                        <span>
                          <FormattedMessage
                            id={'admin.groups.form.isAdminGroup.title'}
                          />
                        </span>{' '}
                        <br />
                        <label className="radio-btn">
                          Yes
                          <input
                            disabled={cn.isAppMain || cn.isOwnerGroup}
                            className={
                              cn.administratorType.IsSuperUser
                                ? ''
                                : cn.btnDissabledClass
                            }
                            type="radio"
                            {...cn.register('isAdmin')}
                            id="isAdmin"
                            onChange={(e) => {
                              if (cn.administratorType.IsSuperUser) {
                                cn.setValue('isAdmin', true);
                                cn.setIsAdminValue(e.target.value);
                              }
                            }}
                            checked={cn.isAdminValue === 'true'}
                            value="true"
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="radio-btn">
                          No
                          <input
                            disabled={cn.isAppMain || cn.isOwnerGroup}
                            className={
                              cn.administratorType.IsSuperUser
                                ? ''
                                : cn.btnDissabledClass
                            }
                            type="radio"
                            {...cn.register('isAdmin')}
                            id="isAdmin"
                            onChange={(e) => {
                              if (cn.administratorType.IsSuperUser) {
                                cn.setValue('isAdmin', false);
                                cn.setIsAdminValue(e.target.value);
                              }
                            }}
                            checked={cn.isAdminValue === 'false'}
                            value="false"
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {!cn.isAdminValue && (
                    <span style={{ color: 'darkred' }}>
                      <FormattedMessage id="admin.groups.form.isAdminGroup.required" />
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-6 d-none">
                  <div className="form-group">
                    <div className="d-flex-column">
                      <div className="d-flex">
                        <span>
                          <FormattedMessage
                            id={'admin.groups.form.isUserDefaultGroup.title'}
                          />
                        </span>{' '}
                        <br />
                        <label className="radio-btn">
                          Yes
                          <input
                            disabled={cn.isAppMain || cn.isOwnerGroup}
                            className={
                              cn.administratorType.IsSuperUser
                                ? ''
                                : cn.btnDissabledClass
                            }
                            type="radio"
                            {...cn.register('isUserDefaultGroup')}
                            id="isUserDefaultGroup"
                            onChange={(e) => {
                              if (cn.administratorType.IsSuperUser) {
                                cn.setValue('isUserDefaultGroup', true);
                                cn.setIsUserDefaultGroupValue(e.target.value);
                              }
                            }}
                            checked={cn.isUserDefaultGroupValue === 'true'}
                            value="true"
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="radio-btn">
                          No
                          <input
                            disabled={cn.isAppMain || cn.isOwnerGroup}
                            className={
                              cn.administratorType.IsSuperUser
                                ? ''
                                : cn.btnDissabledClass
                            }
                            type="radio"
                            {...cn.register('isUserDefaultGroup')}
                            id="isUserDefaultGroup"
                            onChange={(e) => {
                              if (cn.administratorType.IsSuperUser) {
                                cn.setValue('isAdmin', false);
                                cn.setIsUserDefaultGroupValue(e.target.value);
                              }
                            }}
                            checked={cn.isUserDefaultGroupValue === 'false'}
                            value="false"
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {!cn.isAdminValue && (
                    <span style={{ color: 'darkred' }}>
                      <FormattedMessage id="admin.groups.form.isUserDefaultGroup.required" />
                    </span>
                  )}
                </div>
                <div className="col-12 col-md-12">
                  <div className="form-group">
                    <div className="d-flex-column">
                      <div className="d-flex">
                        <span>
                          <FormattedMessage
                            id={'admin.groups.form.isReadOnlyGroup.title'}
                          />
                        </span>{' '}
                        <br />
                        <label className="radio-btn">
                          Yes
                          <input
                            disabled={cn.isAppMain || cn.isOwnerGroup}
                            type="radio"
                            {...cn.register('isReadOnlyGroup')}
                            id="isReadOnlyGroup"
                            onChange={(e) => {
                              cn.setValue('isReadOnlyGroup', true);
                              cn.setIsReadOnlyGroupValue(e.target.value);
                            }}
                            checked={cn.isReadOnlyGroupValue === 'true'}
                            value="true"
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="radio-btn">
                          No
                          <input
                            disabled={cn.isAppMain || cn.isOwnerGroup}
                            type="radio"
                            {...cn.register('isReadOnlyGroup')}
                            id="isReadOnlyGroup"
                            onChange={(e) => {
                              cn.setValue('isReadOnlyGroup', false);
                              cn.setIsReadOnlyGroupValue(e.target.value);
                            }}
                            checked={cn.isReadOnlyGroupValue === 'false'}
                            value="false"
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {!cn.isAdminValue && (
                    <span style={{ color: 'darkred' }}>
                      <FormattedMessage id="admin.groups.form.isReadOnlyGroup.required" />
                    </span>
                  )}
                </div>
                {controller.loadDetailUsers()}

              </div>
              <div className="row mt-5">
                <div className="col-12 col-md-7"></div>
                <div className="col-12 col-md-5">
                  <button
                    type="submit"
                    className="newPj"
                    onClick={() => {
                      cn.setSubmited(true);
                    }}
                  >
                    <FormattedMessage id={'admin.groups.form.save'} />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      }
      {cn.loading && (
        <Loading />
      )}
    </React.Fragment>
  );
}
export default GroupFormAdmin;
