import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useWraperContext } from '../complements/Wrapper';
// images
import IDB from '../../assets/img/IDB-Logo.png';
import { useMsal } from '../../services/auth/authProvider';
import { UsersService } from '../../services/users/users.service';
import { isMobile } from 'react-device-detect';
import LanguageToggle from '../complements/tools/Language-toggle';
import ProfileToggle from '../complements/tools/Profile-toggle';
import { FormattedMessage } from 'react-intl';
import {evalAdmin } from './Header.Controller';
import LoadingHeader from './LoadingHeader';
import { NavItemAboutUs } from './Header.Controller';

function Header(props?: any) {
  const { locale } = useWraperContext();
  const [isUserInternalHd, setIsUserInternalHd] = useState<boolean>(false);
  const { userInternal, user } = useMsal();
  const [username, setUsername] = useState<string>('user');
  const [isSuperUser, setIsSuperUser] = useState<boolean>(false);
  const [categoryActive, setCategoryActive] =
    useState<string>('');
  const usersService = UsersService({ locale: locale });
  const [loading, setLoading] = useState<boolean>(false);
  const [showed, setShowed] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  

  useEffect(() => {
    function fetchBusinesses(){
      setLoading(true);
      if (user) {
        const userName = usersService.getUsername(user);
        setUsername(userName);
      }
      if (userInternal) {
        setIsUserInternalHd(userInternal());
      }
      evalAdmin({user: user, usersService: usersService, setIsAdmin: setIsAdmin,
        setIsSuperUser: setIsSuperUser, 
        setLoading: setLoading});    
    }
    fetchBusinesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeSession = (event: any) => {
    window.location.href = "/logout";
  }

  const onFilterCategoryByMobile = (category: string) => {
    if (props.onFilterCategoryByMobile) {
      props.onFilterCategoryByMobile(category);
    }
  }

  const mailto = 'infradigital@iadb.org';

  return (
    { categoryActive } && (
      <React.Fragment>
        {loading && <LoadingHeader></LoadingHeader>}
        <nav id="header" className="navbar navbar-expand-md navbar-light">
          {!loading && <div className="container">
            <Link className="navbar-brand" to="/home">
              <img src={IDB} width="auto" height="50" className="" alt="" />
              <span>Infradigital</span>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => {
                setShowed(!showed);
              }}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`${showed ? 'show ' : ''} collapse navbar-collapse`}
              id="navbarNav"
            >
              <ul className="navbar-nav">
                <NavItemAboutUs
                  isUserInternalHd={isUserInternalHd} onFilterCategoryByMobile={onFilterCategoryByMobile} setCategoryActive={setCategoryActive} setShowed={setShowed} categoryActive={categoryActive}></NavItemAboutUs>
                {/* <NavItemEnergy
                  isUserInternalHd={isUserInternalHd} onFilterCategoryByMobile={onFilterCategoryByMobile} setCategoryActive={setCategoryActive} setShowed={setShowed} categoryActive={categoryActive}></NavItemEnergy>
                <NavItemTransport
                  isUserInternalHd={isUserInternalHd} onFilterCategoryByMobile={onFilterCategoryByMobile}
                  setCategoryActive={setCategoryActive} setShowed={setShowed}
                  categoryActive={categoryActive}></NavItemTransport>
                <NavItemWater
                  isUserInternalHd={isUserInternalHd} onFilterCategoryByMobile={onFilterCategoryByMobile}
                  setCategoryActive={setCategoryActive} setShowed={setShowed}
                  categoryActive={categoryActive}></NavItemWater>
                <NavItemTools
                  isUserInternalHd={isUserInternalHd} onFilterCategoryByMobile={onFilterCategoryByMobile} setCategoryActive={setCategoryActive}
                  setShowed={setShowed}
                  categoryActive={categoryActive}></NavItemTools> */}
                <li className={'nav-item aboutUs'}>
                  <span className="nav-link flex-center">
                    <a href={`mailto:${mailto}`}>
                    <FormattedMessage id={'general.contactSupport'} />
                    </a>
                  </span>
                </li>
                {isMobile && (
                  <li className={'nav-item'}>
                    <span className="nav-link flex-center">
                      <LanguageToggle></LanguageToggle>
                    </span>
                  </li>
                )}
                {isMobile && (
                  <li>
                    <button
                      className="closeSession"
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'none',
                        color: 'gray',
                      }}
                      onClick={closeSession}
                    >
                      <FormattedMessage id={'sidebar.principal.closeSession'} />
                    </button>
                  </li>
                )}
              </ul>
            </div>
            {!isMobile && (
              <div className="d-flex header-profile">
                {username && <ProfileToggle showAdmin={true}
                  username={username} isAdmin={isAdmin} isSuperUser={isSuperUser}></ProfileToggle>}
                <LanguageToggle></LanguageToggle>
              </div>
            )}
          </div>
          }
        </nav>
      </React.Fragment>
    )
  );
}

export default Header;