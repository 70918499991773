import { useEffect, useState } from 'react';

import {
  Link,
  useHistory,
  useParams
} from "react-router-dom";

import { AppTeam } from '../../models/appTeam.model';
import { Project } from '../../models/project.model';
import { UserAppsService } from '../../services/users/userApps.service';
import coreTeamImg from '../../assets/img/core-team.png';
import { useWraperContext } from '../complements/Wrapper';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useMsal } from '../../services/auth/authProvider';
import { ProjectsService } from '../../services/projects/projects.service';
import moment from 'moment';
import DetailSlider from '../Detail-slider/Detail-slider';
import { AppContact } from '../../models/appContact';
import { getImageByCategory } from '../../services/general';


function Descript(props?: any) {
  const { userInternal, user } = useMsal();
  const history = useHistory();
  const { locale } = useWraperContext();
  const paramsId: any = useParams<{}>();
  const [project, setProject] = useState<Project>(new Project());
  const [coreTeamsData, setcoreTeamsData] = useState<AppTeam[]>([]);

  useEffect(() => {
    function fetchBusinesses() {
      const getData = async () => {
        const userAppsService = new UserAppsService({ locale: locale });
        const appsService = new ProjectsService({
          locale: locale
        });

        const slug: string = paramsId['slug'];
        let response: Project[] = [];
        if (userInternal) {
          if (userInternal()) {
            response = await appsService.getAllItems(user?user:undefined);
          } else {
            response = await userAppsService.getUserAppsMemory();
          }
          if (response) {
            const projects: Project[] = response;
            const filterProject = projects.filter(s => s.Slug === slug);
            if (filterProject.length > 0) {
              const currentProject = filterProject[0];

              if (currentProject && currentProject.Description) {
                var description: any = currentProject.Description;
                if (currentProject.Description.indexOf("\"En\":") !== -1) {
                  description = JSON.parse(currentProject.Description);

                  switch (locale) {
                    case 'en-US':
                    case 'en':
                      description = description.En;
                      break;
                    case 'es-ES':
                    case 'es-US':
                    case 'es':
                      description = description.Es;
                      break;
                    case 'pt-PT':
                    case 'pt':
                      description = description.Pt;
                      break;
                  }
                } else {
                  description = currentProject.Description;
                }

                currentProject.Description = description;
              }
              const a: any = currentProject.ContactInfo;
              currentProject.contactInfo = currentProject.ContactInfo
                ? JSON.parse(a)
                : currentProject.ContactInfo;
              setProject(currentProject);
              setcoreTeamsData(currentProject.Team ? currentProject.Team : []);
              props.setCategoryNameActive(currentProject.Category);
              changeColors(currentProject.Category);
            } else {
              history.push({
                pathname: '/home',
                state: {},
              });
            }
          }
        }
      }
      getData();
    }

    fetchBusinesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeColors = (filter: any) => {
    const colors = document.getElementById('colors');
    if (colors !== null) {
      colors.className = '';
      if (filter) {
        colors.classList.add(filter);
        colors.classList.add(filter + 'Active');
      }
    }
  }

  let image = null;
  if (project.Category) {
    image = project.Thumbnail
      ? project.Thumbnail
      : getImageByCategory(project.Category);
  }
  return (
    <div>
      {project.Screenshots && <DetailSlider data={project.Screenshots} />}
      <section id="description" className={project.Category}>
        <div className="nav-descript">
          <div className="row center-image">
            {!project.Screenshots == null && project.Thumbnail && (
              <img
                id="screenshots"
                src={`${process.env.REACT_APP_CLOUD_FRONT}/${image}`}
                alt=""
              />
            )}
          </div>
          <div className="row">
            <div className="col-6">
              <h3 className={project.Category}>{project.Name}</h3>
            </div>
            <div className="col-6 d-flex align-items-right">
              <div className="lauchDate">
                {project.LaunchDate !== null && (
                  <FormattedDate
                    value={
                      new Date(
                        moment(project.LaunchDate).format('MMMM DD, yyyy')
                      )
                    }
                    year="numeric"
                    month="long"
                    day="2-digit"
                  />
                )}
              </div>
              {project.ShowEnter && (
                <button className="descriptEnter">
                  <Link to={{ pathname: project.Url }} target="blank">
                    <FormattedMessage id={'home.button.enter'} />
                  </Link>
                </button>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-6"></div>
            <div className="col-6 d-flex align-items-right"></div>
          </div>
          <div className="row">
            <div className="col-12">
              <p>{project.Description}</p>
              {coreTeamsData && coreTeamsData.length > 0 && (
                <h3 className={project.Category}>
                  <FormattedMessage id={'general.mainContact'} />
                </h3>
              )}
              <div className="d-flex">
                {project &&
                  project.contactInfo &&
                  project.contactInfo.length > 0 &&
                  project.contactInfo.map((data: AppContact, index: number) => {
                    return (
                      <div className="people d-flex">
                        <div className="col-4">
                          <img src={coreTeamImg} alt="" />
                        </div>
                        <div className="col-8">
                          <h5>{data.NameContact}</h5>
                          <a href={`mailto:${data.Emailcontact}`} className={project.Category}>
                            {data.Emailcontact}
                          </a>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Descript;