import React, { useEffect, useState } from "react";
import { Project } from "../../../models/project.model";
import { ProjectsService } from "../../../services/projects/projects.service";
import { useWraperContext } from "../../complements/Wrapper";
import { Grid, GridColumn, GridFilterCellProps, GridFilterChangeEvent, GridNoRecords, GridPageChangeEvent } from "@progress/kendo-react-grid";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { PageState } from "../../../models/pagestate.model";

import "../../../stylesheets/admin/_admin.scss";
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { CompositeFilterDescriptor, filterBy } from "@progress/kendo-data-query";
import { Alert } from "react-bootstrap";
import { DropdownFilterCell } from "../../common/dropdownFilterCell";
import moment from 'moment';
import { useMsal } from "../../../services/auth/authProvider";
import AdministratorType from "../../../models/administratorType.model";
import { UserAppsService } from "../../../services/users/userApps.service";
import { filterOperators } from "../../../models/complements/jsons/filterGridOperatos";
import { UsersService } from "../../../services/users/users.service";
import { INFRA_DIGITAL_APP_0 } from "../../../globalSettings";
import Loading from "../../loading/Loading.component";

function ProjectsAdmin() {
	const initialFilter: CompositeFilterDescriptor = {
		logic: "and",
		filters: [],
	};
	const [loading, setLoading] = useState<boolean>(false);
	const { locale } = useWraperContext();

	const userAppsService = new UserAppsService({ locale: locale });
	const categories = ["energy", "transport", "water", "tools"];
	const history = useHistory();
	const appsService = new ProjectsService({
		locale: locale
	});
	const [dataTranslates] = useState<IntlShape>(useIntl());
	const initialDataState: PageState = { skip: 0, take: 10 };
	const [page, setPage] = React.useState<PageState>(initialDataState);
	const [projects, setProjects] = useState<Project[]>([]);
	const intl = useIntl();
	const { user } = useMsal();
	const usersService = UsersService({ locale: locale });
	const [isDeleted, setIsDeleted] = useState<boolean>(false);
	const [administratorType, setAdministratorType] = useState<AdministratorType>({ IsSuperUser: false, IsAdminGroup: false });
	const [isError, setIsError] = useState<boolean>(false);
	const [filter, setFilter] = React.useState(initialFilter);
	const generalYes = 'general.yes';
	const generalNo = 'general.no';

	const getData = async () => {
		setLoading(true);
		try {
			let admType = new AdministratorType();
			if (user) {
				const userName = usersService.getUsername(user);
				admType = await usersService.getIsAdminbyUsername(userName);
				setAdministratorType(admType);
			}

			let projectsData: Project[] = [];
			if (admType.IsSuperUser) {
				projectsData = await appsService.getAllItems();


				setProjects(projectsData);
			} else if (admType.IsAdminGroup) {
				const projectR = await userAppsService.getAdminUserApps();
				projectR.forEach(p => {
					if (projectsData.filter(s => s.Name === p.Name).length === 0) {
						projectsData.push(p);
					}
				});
				setProjects(projectsData);

			}

			projectsData.forEach((projectR: Project) => {
				if (projectR.LaunchDate) {
					projectR.LaunchDateString = moment(new Date(projectR.LaunchDate?.toString())).format('MM/DD/yyyy hh:mm A');
				}

				projectR.IsActiveString = projectR.IsActive === true ? intl.formatMessage({
					id: generalYes
				}) : intl.formatMessage({
					id: generalNo
				});

					if (projectR.Description) {
						var description: any = undefined;
					
						if(projectR.Description.indexOf("\"En\":") !== -1 &&projectR.Description.indexOf("\"Es\":") !== -1 
						&& projectR.Description.indexOf("\"Pt\":") !== -1)
						{
						 description = JSON.parse(projectR.Description);
						
							switch (locale) {
								case "en-US":
									case "en":
									  description = description.En;
									  break;
									case "es-ES":
                  case 'es-US':
									case "es":
									  description = description.Es;
									  break;
									case "pt-PT":
									case "pt":
									  description = description.Pt;
									  break;
							}
					
						
					}  else {
						description = projectR.Description;
					}

					projectR.Description = description;
				}
			});

			setProjects(projectsData);
			setLoading(false);
		} catch (ex) {
			setLoading(false);
			setIsError(true);

			setTimeout(() => {
				setIsError(false);
			}, 4000);


		}

	}



	useEffect(() => {
		function fetchBusiness() {
			getData().then();
		}
		fetchBusiness();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const CrudCommandCell = (props: any) => {
		return (
			<td>
				<button
					className="k-button k-primary"
					onClick={() => props.enterEdit(props.dataItem)}
				>
					<FormattedMessage id={'general.edit'} />
				</button>
				{
					administratorType?.IsSuperUser && (
						props.dataItem.Name?.toLowerCase() !== INFRA_DIGITAL_APP_0.toLowerCase())
					&&
					<button
						className="k-button btn pl-2"
						onClick={() => props.enterDelete(props.dataItem)}
					>
						<FormattedMessage id={'general.delete'} />
					</button>
				}{(!administratorType?.IsSuperUser || (
					props.dataItem.Name?.toLowerCase() === INFRA_DIGITAL_APP_0.toLowerCase())) &&
					<span className="hidenButtonDelete">
					</span>
				}
			</td>
		);
	};

	const enterEdit = async (item: Project) => {
		history.push({
			pathname: `/admin/projects/form/${item.Id}`,
			state: {},
		}
		)
	};

	const enterDelete = async (item: Project) => {
		const options = {
			title: intl.formatMessage({
				id: 'general.delete',
			}),
			message: intl.formatMessage({
				id: 'general.deleteItemMessage',
			}),
			buttons: [
				{
					label: intl.formatMessage({
						id: generalYes,
					}),
					onClick: () => {
						handleClickDelete(item);
					}
				},
				{
					label: intl.formatMessage({
						id: generalNo,
					}),
					onClick: () => {/*do something here*/ }
				}
			],
			childrenElement: () => <div />,
			closeOnEscape: true,
			closeOnClickOutside: true,
			willUnmount: () => { /*do something here*/ },
			afterClose: () => { /*do something here*/ },
			onClickOutside: () => { /*do something here*/ },
			onKeypressEscape: () => { /*do something here*/ },
			overlayClassName: "overlay-custom-class-name"
		};
		confirmAlert(options);
	};

	const handleClickDelete = async (item: Project) => {
		setLoading(true);
		try {
			await appsService.deleteApp(Number(item.Id));
			await getData();
			setIsDeleted(true);
			setTimeout(() => {
				setIsDeleted(false);
			}, 4000);


		} catch (err) {
			setIsError(true);
			setTimeout(() => {
				setIsError(false);
			}, 4000);
		}
		setLoading(false);
	}

	const dataCrud = {
		confirm: {
			title: dataTranslates.formatMessage({
				id: 'confirm.to.submit.title',
			}),
			description: dataTranslates.formatMessage({
				id: 'confirm.to.submit.description',
			}),
			yes: dataTranslates.formatMessage({
				id: generalYes,
			}),
			no: dataTranslates.formatMessage({
				id: generalNo,
			}),
		},
	};

	const MyCrudCommandCell = (props: any) => (
		<CrudCommandCell {...props} data={dataCrud} enterEdit={enterEdit} enterDelete={enterDelete} />
	);

	const categoriesItems = () => {
		const items: [{ text: string, value: string | null }] = [{
			text: intl.formatMessage({
				id: 'general.all',
			}), value: null
		}];

		categories.forEach(category => {
			items.push({
				text: category,
				value: category
			})
		})

		return items;
	}

	const CategoriesFilterCell: any = (props: GridFilterCellProps) => {
		return (
			<DropdownFilterCell
				{...props}
				data={categoriesItems()}
				value={props.value}
				defaultItem={intl.formatMessage({
					id: 'general.all',
				})}
			/>
		);
	}

	const BooleanFilterCell: any = (props: GridFilterCellProps) => (
		<DropdownFilterCell
			{...props}
			data={[
				{
					text: intl.formatMessage({
						id: 'general.all',
					}), value: null
				},
				{
					text: intl.formatMessage({
						id: generalYes,
					}), value: intl.formatMessage({
						id: generalYes,
					})
				},
				{
					text: intl.formatMessage({
						id: generalNo,
					}), value: intl.formatMessage({
						id: generalNo,
					})
				}]}
			defaultItem={intl.formatMessage({
				id: 'general.all',
			})}
		/>
	);

	const addNewProject = async (event: any) => {
		history.push({
			pathname: '/admin/projects/form',
			state: {},
		});
	}

	const pageChange = (event: GridPageChangeEvent) => {
		setPage(event.page);
	};


	const onFilterChange = (eventFilter: any) => {
		setPage(initialDataState);
		setFilter(eventFilter);
	}
	return (
		<React.Fragment>
			{isDeleted && (
				<Alert variant="success">
					<FormattedMessage id={'general.successfullDeleted'} />
				</Alert>
			)}

			{isError && (
				<Alert variant="warning">
					<FormattedMessage id={'general.error'} />
				</Alert>
			)}

			<div className={!loading ? 'module-pjs' : 'module-pjs d-none'}>
				<div className="header-pj">
					<h2>
						<FormattedMessage id={'admin.apps.title'} />
					</h2>
					{administratorType.IsSuperUser &&
						<button className="btn btn-primary" onClick={addNewProject}>
							<FormattedMessage id={'admin.apps.AddNew'} />
						</button>
					}

				</div>
				<div className="content-body">
					{!loading && projects.length > 0 && (
						<Grid
							sortable={true}
							data={filterBy(projects, filter).slice(
								page.skip,
								page.take + page.skip
							)}
							filterable={true}
							filter={filter}
							filterOperators={filterOperators}
							onFilterChange={(e: GridFilterChangeEvent) => onFilterChange(e.filter)}
							groupable={false}
							reorderable={true}
							skip={page.skip}
							take={page.take}
							pageable={true}
							onPageChange={pageChange}
							total={filterBy(projects, filter).length}
							expandField="expanded"
							className="hidde-scroll-horizontal"
						>
							<GridNoRecords>
								<FormattedMessage id={'general.norecords'} />
							</GridNoRecords>
							<GridColumn
								field="Name"
								title={intl.formatMessage({
									id: 'admin.projects.grid.name.title',
								})}
								width="350px"
							/>
							<GridColumn
								field="Category"
								width="130px"
								title={intl.formatMessage({
									id: 'admin.projects.grid.category.title',
								})}
								filterCell={CategoriesFilterCell}
							/>
							<GridColumn
								field="Description"
								filterable={false}
								title={intl.formatMessage({
									id: 'admin.projects.grid.description.title',
								})}
								width="400px"
							/>
							<GridColumn
								field="LaunchDateString"
								title={intl.formatMessage({
									id: 'admin.projects.grid.launchDate.title',
								})}
								width="200px"
							/>
							<GridColumn
								field="IsActiveString"
								title={intl.formatMessage({
									id: 'admin.projects.grid.isActive.title',
								})}
								filterCell={BooleanFilterCell}
								width="100px"
							/>
							<GridColumn
								cell={MyCrudCommandCell}
								filterable={false}
								title={intl.formatMessage({
									id: 'general.actions',
								})}
								width="150"
							/>
						</Grid>
					)}
				</div>
			</div>
			{loading && <Loading></Loading>
			}
		</React.Fragment>
	);
}



export default ProjectsAdmin;

