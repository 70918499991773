import { Group } from "../../../models/group.model";
import { User } from "../../../models/user.model";

export const defaultGroup: Group = {
        Id: 0,
        AppId: 0,
        Name: '',
        IsDeleted: false,
      };
      
     export const defaultUser: User = {
        Id: 0,
        Username: '',
        Sub: '',
        IsInternal: false,
        IsAdmin: false,
        CreatedAt: new Date(),
        Inactive: true,
        Infra2_V_GroupsByUsers: []
      }