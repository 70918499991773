import React, { useState } from "react";
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useWraperContext } from '../../complements/Wrapper';
import { ProjectsService } from '../../../services/projects/projects.service';
import { Dataset } from '../../../models/dataset.model';
import { useHistory } from 'react-router';
import { DatasetsService } from '../../../services/datasets/datasets.service';
import { DefaultValue } from '../../../models/complements/defaultValue';
import { DefaultObject } from '../../../models/complements/defaultObject';
import { useParams } from 'react-router-dom';
import { UserAppsService } from '../../../services/users/userApps.service';
import { useMsal } from "../../../services/auth/authProvider";
import { UsersService } from "../../../services/users/users.service";


export function DatasetsFormConsts() {
    const intl = useIntl();
    const {
        register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [apps, setApps] = useState<DefaultObject[]>([]);
    const [submited, setSubmited] = useState<boolean>(false);
    const { locale } = useWraperContext();
    const userAppsService = new UserAppsService({ locale: locale });
    const usersService = UsersService({ locale: locale });
    const projectService = new ProjectsService({
        locale: locale,
    });
    const datasetService = new DatasetsService({ locale: locale });
    const [currentDataset, setCurrentDataset] = useState<Dataset>(new Dataset());
    const [stateApp, setStateApp] = React.useState<DefaultValue>({ value: {} });
    const [stateAccess, setStateAccess] = React.useState<DefaultValue>({ value: {} });
    const [stateType, setStateType] = React.useState<DefaultValue>({ value: {} });
    const [stateDataset] = React.useState({ Name: '', Config: '' });
    const [isEdit, setIsEdit] = React.useState(false);
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const paramsId: any = useParams<{}>();
    const [successfullSaved] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isBlocked, setIsBlocked] = useState<boolean>(false);
    const { user } = useMsal();
    const apiKey = process.env.INFRA_API_KEY ? process.env.INFRA_API_KEY : '';

    return ({
        register, handleSubmit, setValue, apps, setApps,
        submited, setSubmited, locale, userAppsService, usersService, projectService,
        datasetService, currentDataset, setCurrentDataset, stateApp, setStateApp, stateAccess, setStateAccess,
        stateType, setStateType, stateDataset, isEdit, setIsEdit, history, loading, setLoading,
        paramsId, successfullSaved, isError, setIsError, isBlocked, setIsBlocked, user,
        apiKey, intl, errors
    });
}