import * as React from "react";

import {
    DropDownList,
    DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { useIntl } from "react-intl";
interface DropDownItem {
    text: string;
    value: any;
}
interface DropdownFilterCellProps extends GridFilterCellProps {
    defaultItem: string;
    data: DropDownItem[];
    customOperator?: string;
}

export const DropdownFilterCell = (props: DropdownFilterCellProps) => {
    const intl = useIntl();
    const hasValue: any = (value: any): boolean => {
        return Boolean(value && value !== props.defaultItem);
    }

    const onChange = (event: DropDownListChangeEvent) => {
       let hasV = hasValue(event.target.value);
        if (event.target.value.value !== null) {
            props.onChange({
                value: hasV ? event.target.value.value : null,
                operator: hasV ? (props.customOperator ? props.customOperator : "eq") : "",
                syntheticEvent: event.syntheticEvent,
            });
        } else {
            props.onChange({
                value: null,
                operator: "",
                syntheticEvent: event.syntheticEvent,
            });
        }
    };

    const onClearButtonClick = (event: any) => {
        event.preventDefault();
        props.onChange({
            value: null,
            operator: "",
            syntheticEvent: event,
        });
    };
    return (

        <div className="k-filtercell">
            {<DropDownList
                textField="text"
                dataItemKey="value"
                data={props.data}
                onChange={onChange}
                defaultValue={props.value ? props.data.filter(s => s.value === props.value)[0] : props.defaultItem}
                defaultItem={props.defaultItem}
            />}
            <button
                className="k-button k-button-icon k-clear-button-visible"
                title={intl.formatMessage({
					id: 'general.clearFilter',
				})}
                disabled={!hasValue(props.value)}
                onClick={onClearButtonClick}
            >
                <span className="k-icon k-i-filter-clear" />
            </button>
        </div>
    );
};