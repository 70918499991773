export const bannerHome: any = [
    {
      img: 'https://ferms.neocities.org/aud/slider-1.jpg',
      title: 'Infradigital',
      description: 'Accesa y utiliza herramientas digitales. Gestiona e integra la información. Innovación creada para los sectores de Energía, Transporte, Agua y Saneamiento.',
      infButton:  false,
      infReadMore: false,
      infEnter: false,
      category: 'energy',
    },
];