import React from 'react';

// images
import backLogin from '../../assets/img/login-bk.jpg';
import IDB from '../../assets/img/IDB-Logo.png';
import { useMsal, loginRequest } from '../../services/auth/authProvider';
import { FormattedMessage } from 'react-intl';

const Login: React.FC = () => {
    const {login} = useMsal();
    return (
    <main id="login">
        <div className="row">
            <div className="col-12 col-md-6 pad-0 d-none d-md-block">
                <div className="img-back">
                    <img src={backLogin} alt="" />
                    <div className="filter"></div>
                </div>
            </div>
          <div className="col-12 col-md-6 pad-0 log  p-relative">
                <div className="form">
                    <h2>
                        <FormattedMessage id={'login.title'} />
                    </h2>
                    <p>
                        <FormattedMessage id={'login.subtitle'} />
                    </p>
                    <br/>
                    <p className="intro">
                        <FormattedMessage id={'login.description'} />
                    </p>
                    <button  onClick={()=>login?login(loginRequest):null}
                     className="signin cursor-pointer">
                         <FormattedMessage id={'login.button.login'} />
                     </button>
                </div>
                <footer className="d-flex">
                    <nav>
                        <a href="/">
                            <FormattedMessage id={'login.footer.policy'} />
                        </a>
                    </nav>
                    <div className="logo">
                        <img src={IDB} alt="IDB Logo" />
                    </div>
                </footer>
            </div>
        </div>
    </main>
    )
};

export default Login;