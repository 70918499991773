import { useIntl } from "react-intl";
import FilePreview from "react-preview-file";
import { INFRA_DIGITAL_APP_0 } from "../../../globalSettings";
import { AppTeam } from "../../../models/appTeam.model";
import { ProjectsService } from "../../../services/projects/projects.service";
import { UsersService } from "../../../services/users/users.service";
import { guid } from "@progress/kendo-react-common";
import { ManagmentArchivesService } from '../../../services/ManagmentArchives/managmentArchives.service';
import { User } from "../../../models/user.model";
import { StatusModel } from "../../../models/status.model";
import { AttachmentS3 } from "../../../models/attachmentS3";
import { Project } from "../../../models/project.model";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";

export function ProjectFormController(props: any) {
  const intl = useIntl();
  const appService = new ProjectsService({
    locale: props.locale
  });

  const usersService = UsersService({ locale: props.locale });
  const managmentArchivesService = new ManagmentArchivesService({ locale: props.locale });
  const getData = () => {
    const id: number = props.paramsId["id"];
    if (id) {
      appService.getAppById(Number(id)).then(data => {

        if (data.Name?.toLowerCase() === INFRA_DIGITAL_APP_0.toLowerCase()) {
          props.setIsAppMain(true);
        }
        if (data.Category) {
          props.setStateCategory({
            value: data.Category,
          });
        }

        if (data.Summary) {
          if (data.Summary.indexOf("\"En\":") !== -1 && data.Summary.indexOf("\"Es\":") !== -1
            && data.Summary.indexOf("\"Pt\":") !== -1) {
            let summary: any = JSON.parse(data.Summary);
            props.setSummaryEN(summary.En);
            data.SummaryEN = summary.En;
            props.setSummaryES(summary.Es);
            data.SummaryES = summary.Es;
            props.setSummaryPT(summary.Pt);
            data.SummaryPT = summary.Pt;

          } else {
            data.SummaryEN = data.Summary;
          }
        }

        if (data.LaunchDate) {
          data.LaunchDate = new Date(data.LaunchDate);
        }


        if (data.ContactInfo) {
          const contactInfo = JSON.parse(data.ContactInfo);
          if(contactInfo) {
            contactInfo.forEach((at: any) => {
              at.newId= guid();
            })
          }
          props.setAppContacts(contactInfo);
        }
        if (data.Team) {
          var coreTeam: AppTeam[] = [];
          if (typeof data.Team === "string") {
            coreTeam = JSON.parse(data.Team);
            data.Team = coreTeam;
          }

         

          props.setAppTeams(data.Team);
        }

        props.setValue("name", data.Name);
        props.setValue("slug", data.Slug);
        props.setValue("summary", data.Summary);
        // props.setValue("description", data.Description);

        if (data.Description) {
          if (data.Description.indexOf("\"En\":") !== -1 && data.Description.indexOf("\"Es\":") !== -1
            && data.Description.indexOf("\"Pt\":") !== -1) {
            let description: any = JSON.parse(data.Description);
            props.setDescriptionEN(description.En);
            data.DescriptionEN = description.En;
            props.setDescriptionES(description.Es);
            data.DescriptionES = description.Es;
            props.setDescriptionPT(description.Pt);
            data.DescriptionPT = description.Pt;
          }
        } else {
          data.DescriptionEN = data.Description;

        }
        if (data.LaunchDate) {
          props.setLaunchDate(data.LaunchDate);
        }
        props.setValue("url", data.Url);
        props.setIsActiveValue(data.IsActive === true ? "true" : "false");
        props.setIsFinishedValue(data.Finished === true ? "true" : "false");
        props.setValue('finished',data.Finished);
        props.setCurrentProject(data);
        props.setLoading(false);
        props.setIsBlocked(false);
      });

    } else {
      props.setIsBlocked(false);
      props.setLoading(false);
    }
  };

  const validateEmail = (): boolean => {
    let isValid = false;
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (props.currentContact.Emailcontact !== "") {
      if (!pattern.test(props.currentContact.Emailcontact)) {
        if (props.errors) {
          props.errors["emailContact"] = intl.formatMessage({
            id: 'general.email.invalid',
          });
        }
        isValid = false;
      } else {
        isValid = true;
      }
    }
    return isValid;
  };

  const getUsers = async () => {
    return await usersService.getUsers();
  };

  const addContact = (): Promise<void>  => {
    return new Promise<void>((resolve) => {
      props.setLoading(true);
        const isValidEmail = validateEmail();
        if (props.currentContact && props.currentContact.NameContact !== '' && props.currentContact.Emailcontact !== '' && isValidEmail) {
          props.appContacts.push({
            NameContact: props.currentContact.NameContact,
            Emailcontact: props.currentContact.Emailcontact,
            newId: guid()
          });
          props.setAppContacts(props.appContacts);
          props.setCurrentContact({ NameContact: '', Emailcontact: '' });
          props.setContactInfoSubmited(false);
        }
        props.setLoading(false);
        resolve();
    })
  };

  const addAppTeam = () => {
    if (props.selectedUser && props.selectedUser.value && props.selectedUser.value.Id !== 0) {
      props.setLoading(true);
      const exists = existsTeamInList(props.selectedUser.value);
      if (!exists) {
        const team: AppTeam = {
          newId: guid(),
          Name: props.selectedUser.value.Name ? props.selectedUser.value.Name : props.selectedUser.value.Username,
          Role: props.selectedUser.value.Role || '',
          Company: '',
          Email: props.selectedUser.value.Username
        };

        props.appTeams.push(team);
        props.setAppTeams(props.appTeams);
        props.setSelectedUser({
          value: props.defaultUser
        });
      } else {
        props.setExistsTeamUser(true);
        setTimeout(() => {
          props.setExistsTeamUser(false);
        }, 3000);
      }
      props.setSubmitedUser(false);
      props.setLoading(false);
    }
  };

  const existsTeamInList = (user: User): boolean => {
    let result: boolean = false;
    if (props.appTeams && props.appTeams.filter((s: any) => s.Name === user.Name || s.Name === user.Username).length > 0) {
      result = true;
    }
    return result;
  };

  const uploadFileAndGetUrl = async (
    fileName: string,
    path: string,
    file: any) => {
    return new Promise<StatusModel>((resolve, reject) => {
      managmentArchivesService
        .uploadfile(fileName, file.extension.replace('.', ''), path)
        .then((dataUrlTicket: any) => {
          if (dataUrlTicket.Status !== undefined && dataUrlTicket.Status !== 200) {
            resolve(dataUrlTicket);
          } else {
            managmentArchivesService
              .sendUrlConfirmed(
                dataUrlTicket.Data[0],
                file.getRawFile(),
                file.extension.replace('.', '')
              )
              .then(() => {
                resolve({
                  Status: 200,
                  Data: `${path}/${dataUrlTicket.FileName}`,
                });
              })
              .catch((ex) => {
                reject(ex);
              });
          }
        });
    });
  };
  const setScreenshots = async (modeEdit: boolean, screenshots: any, oldScreenshots: any) => {
    return new Promise<string[] | null>((resolve, reject) => {
      try {
        if (screenshots.length > 0) {
          let screenShotsNewData: string[] = [];
          const waiters = new Promise((resolveInt, rejectInt) => {
            screenshots.forEach((screenshot: AttachmentS3, index: number) => {
              uploadFileAndGetUrl(
                screenshot.fileName,
                `apps/${screenshot.slug}/screenshots`,
                screenshot.file
              ).then((x: StatusModel) => {
                screenShotsNewData.push(x.Data);
                if (x.Status === 413) {
                  props.setLoading(false);
                  props.setScreenshotfileSizeExceded(true);
                }
                if (index === (screenshots.length - 1)) {
                  resolveInt(screenShotsNewData);
                }
              });
            });
          });
          waiters.then((x: any) => {
            if (oldScreenshots && oldScreenshots !== null && (JSON.parse(oldScreenshots).length !== undefined)) {
              const fusion = JSON.parse(oldScreenshots);
              fusion.push(...x);
              resolve(fusion);
            } else {
              resolve(x);
            }
          });
        } else {
          resolve(null);
        }
      } catch (err) {
        resolve(null);
      }
    });
  };

  const setMainImage = (
    modeEdit: boolean,
    mainImages: AttachmentS3[]
  ) => {
    return new Promise<StatusModel>((resolve, reject) => {
      try {
        if (mainImages.length > 0) {
          mainImages.forEach((mainImage: AttachmentS3) => {
            uploadFileAndGetUrl(
              mainImage.fileName,
              `apps/${mainImage.slug}/thumbnail`,
              mainImage.file
            ).then((x: any) => {
              console.log('FILEEEEEEEEE:', x);

              if (x.Status === 413) {
                props.setLoading(false);
                props.setFileSizeExceded(true);
              }
              resolve(x);
            });
          });
        }
      } catch (err) {
        resolve({Status: 400, Data: null});
      }
    });
  };

  function makeid(length: number) {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  const onSubmit = async (data: any) => {
    props.setMainPictureError(false);
    props.setSubmited(true);
    props.setLoading(true);
    props.setFileSizeExceded(false);
    props.setScreenshotfileSizeExceded(false);
    props.setInvalidSummaryEn(!data.summaryEN);
    props.setInvalidSummaryEs(!data.summaryES);
    props.setInvalidSummaryPt(!data.summaryPT);
    props.setInvalidDescriptionEn(!data.descriptionEN);
    props.setFileTypeInvalid(false);
    props.setFileTypeSInvalid(false);

    try {
      if (
        !(props.currentProject.Id && props.currentProject.Thumbnail) &&
        props.affectedFilesMain.length === 0
      ) {
        props.setLoading(false);
        props.setMainPictureError(true);
        return;
      }
      if (data && data.name
        && ((!props.isAppMain && data.slug && props.stateCategory.value
          && (data.summary || (data.summaryES && data.summaryEN && data.summaryPT))
          && data.launchDate && data.url) || props.isAppMain)
        && ((!props.currentProject.Id && props.selectedOwner.value) || props.currentProject.Id)
        && data.isActive !== undefined
        && props.currentProject.SummaryEN && props.currentProject.SummaryES && props.currentProject.SummaryPT
        && props.currentProject.DescriptionEN && props.currentProject.DescriptionES && props.currentProject.DescriptionPT
      ) {
        let temporalUrlMainData = '';
        const isInsert = !props.currentProject.Id || props.currentProject.Id === 0;
        if (props.currentProject.Id && props.currentProject.Thumbnail) {
          temporalUrlMainData = props.currentProject.Thumbnail;
        }
        if (
          (props.affectedFilesMain.length === 0 && !props.currentProject.Thumbnail) ||
          props.affectedFilesMain.filter((f: any) => f.validationErrors)
            .length > 0
        ) {
          props.setLoading(false);
          props.setFileTypeInvalid(true);
          return;
        }
        if (
          props.affectedFiles.length > 0 &&
          props.affectedFiles.filter((f: any) => f.validationErrors)
            .length > 0
        ) {
          props.setLoading(false);
          props.setFileTypeSInvalid(true);
          return;
        }
        if (props.affectedFilesMain.length > 0) {
          props.setFileTypeInvalid(false);
          const mainImage = await setMainImage(
            !isInsert,
            props.affectedFilesMain.map((x: any) => {
              return {
                fileName: x.name,
                slug: data.slug,
                file: x
              };
            })
          );
          temporalUrlMainData = mainImage.Data;
          if (!temporalUrlMainData) {
            props.setLoading(false);
            return;
          }
        }
        const urlMainData = temporalUrlMainData;
        const oldScreen = props.currentProject.Screenshots;
        const urlScreenshotsData: any =
          props.affectedFiles.length > 0
            ? await setScreenshots(
              !isInsert,
              props.affectedFiles.map((item: any) => {
                return {
                  fileName: item.name,
                  slug: data.slug,
                  file: item
                };
              }), oldScreen
            )
            : oldScreen;
        if (urlScreenshotsData && urlScreenshotsData.length !== undefined &&
          (urlScreenshotsData.length > 0 && urlScreenshotsData[0] === undefined)) {
          return;
        }
        let urlScreenshotsDataJSON: string = '';
        if (urlScreenshotsData) {
          urlScreenshotsDataJSON = urlScreenshotsData.map !== undefined
            ? JSON.stringify(urlScreenshotsData)
            : urlScreenshotsData;
        }


        let summary: any = {
          En: props.currentProject.SummaryEN,
          Es: props.currentProject.SummaryES,
          Pt: props.currentProject.SummaryPT
        };

        if (props.isAppMain && !props.currentProject.SummaryEN && !props.currentProject.SummaryES && !props.currentProject.SummaryPT) {
          summary = {
            En: INFRA_DIGITAL_APP_0,
            Es: INFRA_DIGITAL_APP_0,
            Pt: INFRA_DIGITAL_APP_0
          };
        }


        let description: any = {
          En: props.currentProject.DescriptionEN,
          Es: props.currentProject.DescriptionES,
          Pt: props.currentProject.DescriptionPT
        };

        if (props.isAppMain && !props.currentProject.DescriptionEN && !props.currentProject.DescriptionES && !props.currentProject.DescriptionPT) {
          description = {
            En: INFRA_DIGITAL_APP_0,
            Es: INFRA_DIGITAL_APP_0,
            Pt: INFRA_DIGITAL_APP_0
          };
        }

        

        const project: Project = {
          Id: props.currentProject.Id,
          Name: data.name as string,
          Slug: data.slug,
          IsShowcased: true,
          Category: props.stateCategory.value ? props.stateCategory.value : 'transport',
          Summary: JSON.stringify(summary),
          Description: JSON.stringify(description),
          LaunchDate: data.launchDate,
          Url: data.url,
          IsActive: data.isActive,
          Team: props.appTeams,
          ContactInfo: props.appContacts,
          Thumbnail: urlMainData,
          Screenshots: urlScreenshotsDataJSON,
          Finished: data.finished
        };
        if (isInsert) {
          await appService.addApp(project, props.selectedOwner.value.Id);
        } else {
          await appService.updateApp(project);
        }
        props.setSuccessfullSaved(true);
        props.history.push({
          pathname: '/admin/projects/',
          state: {},
        });
        props.setLoading(false);
      } else {
        props.setLoading(false);
      }
    } catch (ex) {
      props.setLoading(false);
      props.setIsError(true);
      setTimeout(() => {
        props.setIsError(false);
      }, 4000);
    }

  };

  const handleChangeCategory = (event: DropDownListChangeEvent) => {
    props.setStateCategory({
      value: event.target.value,
    });
  };


  const handleChangeUser = (event: DropDownListChangeEvent) => {
    const user: User = event.target.value;
    props.setSelectedUser({
      value: user,
    });
  };

  const handleChangeOwner = (event: DropDownListChangeEvent) => {
    const user: User = event.target.value;
    props.setSelectedOwner({
      value: user,
    });
  };

  const onAddMain = (event: any) => {
    props.setMainPictureError(false);
    props.setFilesMain(event.newState);
    props.setEventsMain([...props.events, `File selected: ${event.affectedFiles[0].name}`]);
    const allFiles: any = [
      ...props.affectedFiles,
      ...event.affectedFiles.filter((x: any) =>
        props.avaliableMainExtension.includes(x.extension.toLowerCase())
      ),
    ];
    props.setAffectedFilesMain(allFiles);
    props.setIsNewMainImage(true);
    props.setFileTypeInvalid(false);
  };

  const onRemoveMain = (event: any) => {
    let newFilePreviews: any = { ...props.filePreviews };
    event.affectedFiles.forEach((fileItem: any) => {
      delete newFilePreviews[fileItem.uid];
    });
    props.setFilesMain(event.newState);
    props.setEventsMain([...props.events, `File removed: ${event.affectedFiles[0].name}`]);
    props.setFilePreviewsMain(newFilePreviews);
    props.setAffectedFilesMain(event.newState);
    props.setIsNewMainImage(false);
  };

  const onProgressMain = (event: any) => {
    props.setFilesMain(event.newState);
    props.setEventsMain([
      ...props.events,
      `On Progress: ${event.affectedFiles[0].progress} %`,
    ]);
  };

  const onAdd = (event: any) => {
    props.setFiles(event.newState);
    props.setEvents([...props.events, `File selected: ${event.affectedFiles[0].name}`]);
    const allFiles: any = [...props.affectedFiles, ...event.affectedFiles.filter((x: any) =>
      props.avaliableScreenshotExtension.includes(x.extension.toLowerCase()))];
    props.setAffectedFiles(allFiles);
    props.setFileTypeSInvalid(false);
    if (props.currentProject.Screenshots || (props.currentProject.Screenshots && props.currentProject.Screenshots.length === 0)) {
      props.setIsNewScreenshotsImage(true);
    }
  };

  const onRemove = (event: any) => {
    let newFilePreviews: any = { ...props.filePreviews };
    event.affectedFiles.forEach((fileItem: any) => {
      delete newFilePreviews[fileItem.uid];
    });
    props.setFiles(event.newState);
    props.setEvents([...props.events, `File removed: ${event.affectedFiles[0].name}`]);
    props.setFilePreviews(newFilePreviews);
    props.setAffectedFiles(event.newState);
    props.setIsNewScreenshotsImage(false);
  };

  const onProgress = (event: any) => {
    props.setFiles(event.newState);
    props.setEvents([...props.events, `On Progress: ${event.affectedFiles[0].progress} %`]);
  };

  const onRemoveWithArtificialEvent = (filesR: any, uid: string, type: number) => {
    const artifitialEvent = {
      affectedFiles: filesR,
      newState: [],
    };
    if (type === 1) {
      onRemoveMain(artifitialEvent);
    } else {
      onRemove(artifitialEvent);
    }
    const nameTag = 'k-upload-files k-reset';
    const elements: any = document
      .getElementsByClassName((type === 1) ? 'fileUp' : 'fileUpTwo')[0]
      .getElementsByClassName(nameTag)[0].children;
    for (const key in elements) {
      if (Object.prototype.hasOwnProperty.call(elements, key)) {
        const element = elements[key];
        if (element.dataset.uid === uid) {
          if (elements[key].outerHTML !== undefined) {
            if (elements.length > 1) {
              elements[key].remove();
            } else {
              if (type === 1) {
                props.setShowUpload(false);
                setTimeout(() => {
                  props.setShowUpload(true);
                }, 50);
              } else {
                props.setShowUploadS(false);
                setTimeout(() => {
                  props.setShowUploadS(true);
                }, 50);
              }
            }
          }
        }
      }
    }
  };

  const removeActualScreenshotByUrl = (url: string) => {
    if (props.currentProject.Screenshots) {
      const cProject = props.currentProject;
      cProject.Screenshots = JSON.stringify(JSON.parse(props.currentProject.Screenshots).filter(
        (x: string) => x !== url
      ));
      props.setCurrentProject(cProject);
      console.log(props.currentProject);
      props.setNoReloadingScreenshots(false);
      setTimeout(() => {
        props.setNoReloadingScreenshots(true);
      }, 5);
    }
  };
  const CustomListItemUI = (propsC: any, type: number) => {
    const filesR = propsC.files;
    return (
      <ul className="ul-no-decoration">
        {filesR.map((fileItem: any) => {
          let rawFile = fileItem.getRawFile();
          return (
            <li key={fileItem.name}>
              <FilePreview file={rawFile}>
                {(preview) => (
                  <img
                    src={preview}
                    alt="filePreview"
                    style={{ width: 70, height: 70 }}
                  />
                )}
              </FilePreview>
              <strong className="k-upload-status">
                <button
                  type="button"
                  className="k-button k-button-icon k-flat k-upload-action"
                  onClick={() => {
                    onRemoveWithArtificialEvent(filesR, fileItem.uid, type);
                  }}
                >
                  <span
                    aria-label="Remove"
                    title="Remove"
                    className="k-icon k-delete k-i-x"
                  ></span>
                </button>
              </strong>
            </li>
          );
        })}
      </ul>
    );
  };


  return (
    {
      getData,
      validateEmail,
      getUsers,
      addContact,
      addAppTeam,
      existsTeamInList,
      uploadFileAndGetUrl,
      setScreenshots,
      setMainImage,
      makeid,
      onSubmit,
      handleChangeCategory,
      handleChangeUser,
      handleChangeOwner,
      onAddMain,
      onRemoveMain,
      onProgressMain,
      onAdd,
      onRemove,
      onProgress,
      onRemoveWithArtificialEvent,
      removeActualScreenshotByUrl,
      CustomListItemUI
    }
  );
}