import React, { useEffect, useState  } from 'react';
import Slider from "react-slick";
import { bannerHome } from '../../core/services/mocks/data-slider';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FormattedMessage } from 'react-intl';
import { UserAppsService } from '../../services/users/userApps.service';
import { Project } from '../../models/project.model';
import { useWraperContext } from '../complements/Wrapper';
import { Link } from 'react-router-dom';
import { useMsal } from '../../services/auth/authProvider';
import { ProjectsService } from '../../services/projects/projects.service';
export interface DataBanner {
  img: string;
  title: string;
  description: string;
  infReadMore: boolean;
  infEnter: boolean;
  category: string;
  Url?: string;
  Slug?: string;
}

function SliderHome(props?: any) {
  const databanner = bannerHome;
  const [banners, setBanners] = useState<DataBanner[]>([]);
  const { locale } = useWraperContext();
  const { userInternal, user } = useMsal();


  const settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };



  useEffect(() => {
    function fetchBusiness () {
      setBanners(databanner);
      getAllDataSliderHome(locale, databanner,userInternal? userInternal(): false);
      }
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  async function getAllDataSliderHome(locale2: any, data: any, isUserInternalSh: boolean) {
    const userAppsService = new UserAppsService({ locale: locale2 });
    const appsService = new ProjectsService({
      locale: locale2
    });
    let projects: Project[] = [];
    let projectsX: Project[] = [];
    if (userInternal && userInternal()) {
      projectsX = await appsService.getAllItems(user?user:undefined);
      projectsX = projectsX.filter(s => s.IsActive === true);

    } else {
      projectsX = await userAppsService.getUserAppsMemory();
      projectsX = projectsX.filter(s => s.IsActive === true);
    }
    let category = projectsX.map((x) => x.Category);
    let projectsJ: Project[] = [];
    if (category.length > 0) {
      category = category.filter((elem, index, self) => index === self.indexOf(elem));
      category.forEach((x, i) => {
        const l = [...projectsX.filter((j) => j.Category === x)];
        if (l.length > 0) {
          projectsJ = [...projectsJ, l[0]];
        }
      })
    }
    if (projectsJ.length > 0) {
      projectsJ = projectsJ.filter(s => s.IsActive === true);
      projects = projects.filter(s => s.IsActive === true);
      projects = projectsJ.slice(0, 3);
    }
    let information: DataBanner[] = [];
    if (projects) {
      projects = projects.slice(0, 6);
      if (isUserInternalSh) {
        information = [
          ...data,
          ...projects.map((x: any) => {
            if (x && x.Description) {
              var description: any = undefined;
              if (x.Description.indexOf("\"En\":") !== -1 && x.Description.indexOf("\"Es\":") !== -1
                && x.Description.indexOf("\"Pt\":") !== -1) {
                description = JSON.parse(x.Description);
  
                switch (locale) {
                  case 'en-US':
                  case 'en':
                    description = description.En;
                    break;
                  case 'es-ES':
                  case 'es-US':
                  case 'es':
                    description = description.Es;
                    break;
                  case 'pt-PT':
                  case 'pt':
                    description = description.Pt;
                    break;
                }
              } else {
                description = x.Description;
              }
  
              x.Description = description.toString();
            }
            return {
              category: x.Category,
              description: x.Description,
              img: x.Screenshots && x.Screenshots.length > 0 ? ( process.env.REACT_APP_CLOUD_FRONT + "/"+x.Screenshots[0]) : 
               (process.env.REACT_APP_CLOUD_FRONT + "/"+x.Thumbnail),
              infButton: true,
              infEnter: x.ShowEnter,
              infReadMore: true,
              title: x.Name,
              Url: x.Url,
              Slug: x.Slug,
            };
          }),
        ];
      } else {
        information = [...data];
      }
    }
    setBanners(information);
  }


  return (
    <section id="slider">
      <Slider {...settings}>
        { banners &&
          banners.map((data: DataBanner, index: number) => {
            return (
              <div key={index} className={`items ${data.category}`}>
                <img src={data.img} alt={data.img} />
                <div className="filter"></div>
                <div className="info">
                  <h3>{data.title}</h3>
                  <p>
                    {data.title === 'Infradigital' ? (
                      <FormattedMessage id={'home.general.description'} />
                    ) : (
                      data.description
                    )}
                  </p>
                  {data.infReadMore && (
                    <button>
                      <Link
                        to={{
                          pathname: '/info/' + data.Slug,
                        }}
                        target="blank"
                        className="navLinStyleOff"
                      >
                        <FormattedMessage id={'home.button.readmore'} />
                      </Link>
                    </button>
                  )}
                  {data.infEnter && (
                    <button className="enter">
                      <Link
                        to={{
                          pathname: data.Url,
                        }}
                        target="blank"
                        className="navLinStyleOff"
                      >
                        <FormattedMessage id={'home.button.enter'} />
                      </Link>
                    </button>
                  )}
                </div>
              </div>
            );
          })}
      </Slider>
    </section>
  );
}

export default SliderHome;