import React, { useEffect, useState } from 'react';

//components
import Router from './Router';

//styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './stylesheets/app.scss';

import { useMsal, loginRequest } from "./services/auth/authProvider";
import Login from './components/Login/Login';
import { BrowserRouter } from 'react-router-dom';
import RunnerProvider from './run-provider';
import '@progress/kendo-theme-default/dist/all.css';
import Wrapper from './components/complements/Wrapper';
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import GeneralLangEnglish from './lang/general/general-en.json';
import GeneralLangSpanish from './lang/general/general-es.json';
import GeneralLangPortugues from './lang/general/general-pt.json';

function App() {
  const { isAuthenticated, token, isLogedIn, userInternal, getIsInternal, user } =
    useMsal();
  const [isUserInternalApp, setIsUserInternalApp] = useState<boolean>(false);
  const setCategoryMainColorActive = (filter: any) => {
    const colors = document.getElementById('colors');
    if (colors !== null) {
      colors.className = '';
      if (filter.category) {
        colors.classList.add(filter.category);
        colors.classList.add(filter.category + 'Active');
      }
    }
  };

  const getLanguage = () => {
    let lang = 'en-US';

    const currentLang = localStorage.getItem('lang');
    switch (currentLang) {
      case 'es':
        lang = 'es-ES';
        loadMessages(GeneralLangSpanish, 'es-ES');
        break;
      case 'en':
        lang = 'en-US';
        loadMessages(GeneralLangEnglish, 'en-US');
        break;
      case 'pt':
        lang = 'pt-PT';
        loadMessages(GeneralLangPortugues, 'pt-PT');
        break;
    }
    return lang;

  }
  useEffect(() => {
    if (user) {
      localStorage.setItem('userLogin', JSON.stringify(user));
    }

    if (getIsInternal) {
      setIsUserInternalApp(getIsInternal());
    }
    console.log('package:', '0.1.3');
  }, [getIsInternal, userInternal, isUserInternalApp, user]);


  return (
    <LocalizationProvider language={getLanguage()}>
      <Wrapper>
        <div id="colors">
          {!isAuthenticated && <Login />}
          {isAuthenticated && (!token || !isLogedIn) && (
            <RunnerProvider loginRequest={loginRequest} />
          )}
          {isAuthenticated && isLogedIn && (
            <BrowserRouter>
              {isAuthenticated && token && isLogedIn && (
                <div className={isUserInternalApp ? '' : ' isExternal'}>
                  <Router setCategoryFActive={setCategoryMainColorActive} />
                </div>
              )}
            </BrowserRouter>
          )}
        </div>
      </Wrapper>
    </LocalizationProvider>
  );
}

export default App;