import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AppTeam } from "../../../models/appTeam.model";
import { ProjectFormController } from "./projectForm.controller";

export default function ProjectFormAppTeams (props: any) {
    const intl = useIntl();
    const ctrl = ProjectFormController({
        appTeams: props.appTeams,
        selectedUser: props.selectedUser,
        setLoading: props.setLoading,
        setAppTeams: props.setAppTeams,
        defaultUser: props.defaultUser,
        setExistsTeamUser: props.setExistsTeamUser,
        setSubmitedUser: props.setSubmitedUser,
        setSelectedUser: props.setSelectedUser});
return ( <table>
    <thead>
      <tr>
        <th style={{ width: '40%' }}>
          <FormattedMessage id="admin.projects.form.coreTeam.name.title" />
        </th>
        <th style={{ width: '20%' }}>
          <FormattedMessage id="admin.common.grid.actions.title" />
        </th>
      </tr>
    </thead>

    <tbody>
      {props.appTeams.map((item: AppTeam, index: number) => {
        return (
          <React.Fragment key={index}>
            <tr>
              <td>{item.Name}</td>
              <td>
                <button type="button" onClick={(e) => {
                        e.preventDefault();
                        const appTeam = props.appTeams.filter(
                          (s: any) => s.newId !== item.newId
                        );
                        props.setAppTeams(appTeam);
                  }}>
                  <span className="icon-trash"></span>
                </button>
              </td>
            </tr>
          </React.Fragment>
        );
      })}

      <tr>
        <td colSpan={2}>
          <DropDownList
            data={props.users}
            textField="TextField"
            dataItemKey="Id"
            value={props.selectedUser.value}
            defaultItem={intl.formatMessage({
              id: 'admin.groups.form.user.placeHolder',
            })}
            name="users"
            className="drop-field"
            onChange={ctrl.handleChangeUser}
          />
        </td>

        <td>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              props.setSubmitedUser(true);
              ctrl.addAppTeam();
            }}
          >
            <span className="icon-add-user"></span>
          </button>
        </td>
      </tr>
    </tbody>
  </table>)
}