import React, { useEffect, useState } from "react";

import "../../../stylesheets/admin/_admin.scss";

import {
	Chart,
	ChartSeries,
	ChartSeriesItem,
	ChartLegend,
	ChartSeriesLabels,
} from "@progress/kendo-react-charts";

import "hammerjs";
import { DashboardService } from "../../../services/Dashboard/dashboard.service";
import { useWraperContext } from "../../complements/Wrapper";
import { DashboardView } from "../../../models/dashboardView.model";
import { useIntl } from "react-intl";
import Loading from '../../loading/Loading.component';


const labelContent = (e: any) => e.category;
function DashboardAdmin() {
	const intl = useIntl();

	const { locale } = useWraperContext();
	const dashboardService = new DashboardService({ locale: locale });
	const [dashboard, setDashboard] = useState<DashboardView>();
  const [loading, setLoading] = useState<boolean>(false);

  

	useEffect(() => {
    function fetchBusiness() {
      setLoading(true);
      dashboardService.getDashboard().then((response: any) => {
        if (response.data && response.data.length > 0) {
          setDashboard(response.data[0]);
          setLoading(false);
        }
      });
  
    }
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const projectsPerCategoryBinding = [{
		"kind": "energy", "share": dashboard?.Energy_Apps
		, "colorField": "#f4db4e"
	}, {
		"kind": "transport", "share": dashboard?.Transport_Apps
		, "colorField": "#308144"
	}, {
		"kind": "water", "share": dashboard?.Water_Apps
		, "colorField": "#4ba6de"
	}, {
		"kind": "tools", "share": dashboard?.Tools_Apps
		, "colorField": "#3c3c3c"
	}];

	const datasetsPerCategoryBinding = [{
		"kind": "energy", "share": dashboard?.Energy_Datasets
		, "colorField": "#f4db4e"
	}, {
		"kind": "transport", "share": dashboard?.Transport_Datasets
		, "colorField": "#308144"
	}, {
		"kind": "water", "share": dashboard?.Water_Datasets
		, "colorField": "#4ba6de"
	}, {
		"kind": "tools", "share": dashboard?.Tools_Apps
		, "colorField": "#3c3c3c"
	}];
	return (
    <React.Fragment>
      <div  className={!loading? "module-pjs":"module-pjs d-none"}>


      <div className="row">
        <div className="col-12 col-md-6 sect-chart">
          <div className="k-card">
            <span className="dashboard_TotalProjects">
              {dashboard?.Total_Apps}
            </span>{' '}
            {intl.formatMessage({
              id: 'admin.dashboard.totalApps',
            })}
          </div>
        </div>
        <div className="col-12 col-md-6 sect-chart">
          <div className="k-card">
            <span className="dashboard_TotalDatasets">
              {dashboard?.Total_Datasets}
            </span>{' '}
            {intl.formatMessage({
              id: 'admin.dashboard.totalDatasets',
            })}
          </div>
        </div>
      </div>

      <div className="row mt-6">
        <div className="col-12 col-md-6 sect-chart">
          <div className="k-card">
            {intl.formatMessage({
              id: 'admin.dashboard.appsPerCategory',
            })}
            <Chart>
              <ChartSeries>
                <ChartSeriesItem
                  type="donut"
                  data={projectsPerCategoryBinding}
                  categoryField="kind"
                  colorField="colorField"
                  field="share"
                  tooltip={{
                    visible: true,
                    background: 'darkgray',
                  }}
                >
                  <ChartSeriesLabels
                    color="#fff"
                    background="none"
                    content={labelContent}
                  />
                </ChartSeriesItem>
              </ChartSeries>
              <ChartLegend visible={false} />
            </Chart>
          </div>
        </div>

        <div className="col-12 col-md-6 sect-chart">
          <div className="k-card">
            {intl.formatMessage({
              id: 'admin.dashboard.datasetsPerCategory',
            })}
            <Chart>
              <ChartSeries>
                <ChartSeriesItem
                  type="donut"
                  data={datasetsPerCategoryBinding}
                  categoryField="kind"
                  colorField="colorField"
                  field="share"
                  tooltip={{
                    visible: true,
                    background: 'darkgray',
                  }}
                >
                  <ChartSeriesLabels
                    color="#fff"
                    background="none"
                    content={labelContent}
                  />
                </ChartSeriesItem>
              </ChartSeries>
              <ChartLegend visible={false} />
            </Chart>
          </div>
        </div>
      </div>
      </div>
      {loading && (
        <Loading></Loading>
      )}
    </React.Fragment>
  );
}

export default DashboardAdmin;
