import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from 'react-router-dom';
import AdministratorType from "../../../models/administratorType.model";
import { useMsal } from "../../../services/auth/authProvider";
import { UsersService } from "../../../services/users/users.service";
import "../../../stylesheets/admin/navigation/_navigation.admin.scss";
import { useWraperContext } from "../../complements/Wrapper";
function NavigationAdmin() {
  const { locale } = useWraperContext();
  const location = useLocation();
  const { user } = useMsal();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split('/');
  const usersService = UsersService({ locale: locale });
  const [isSuperUser, setIsSuperUser] = useState<boolean>(false);
 
  

  useEffect(()=> {
    function fetchBusiness() { 
      if (user) {
        const userName = usersService.getUsername(user);
        usersService.getIsAdminbyUsername(userName).then((result: AdministratorType) => {
          setIsSuperUser(result.IsSuperUser);
         
        });
      }
    }
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <h2 className="feature-title">
        {' '}
        <FormattedMessage id={'infradinamica.title'} />
      </h2>
      <ul className="list-menu">
        {isSuperUser && <li
          className={
            'btmMenu' +
            (!splitLocation[2] &&
              splitLocation[1] &&
              splitLocation[1] === 'admin'
              ? ' active'
              : '')
          }
        >
          <Link to={{ pathname: '/admin' }}>
            <span className="icon-dashboard"></span>
            <FormattedMessage id={'admin.dashboard'} />
          </Link>
        </li>}

        <li
          className={
            'btmMenu' +
            (splitLocation[2] && splitLocation[2] === 'projects'
              ? ' active'
              : '')
          }
        >
          <Link to={{ pathname: '/admin/projects' }}>
            <span className="icon-projects"></span>
            <FormattedMessage id={'admin.menu.apps.title'} />
          </Link>
        </li>
        <li
          className={
            'btmMenu' +
            (splitLocation[2] &&
              splitLocation[2] === 'groups'
              ? ' active'
              : '')
          }
        >
          <Link to={{ pathname: '/admin/groups' }}>
            <span className="icon-group-users"></span>
            <FormattedMessage id={'admin.menu.groups.title'} />
          </Link>
        </li>
        {/* <li
            className={
              'btmMenu' +
              (splitLocation[2] && splitLocation[2] === 'userGroups'
                ? ' active'
                : '')
            }
          >
            <Link to={{ pathname: '/admin/userGroups' }}>
              <span className="icon-group-users"></span>
              <FormattedMessage id={'admin.menu.userGroups.title'} />
            </Link>
          </li> */}

        {isSuperUser &&
          <li
            className={
              'btmMenu' +
              (splitLocation[2] && splitLocation[2] === 'users'
                ? ' active'
                : '')
            }
          >
            <Link to={{ pathname: '/admin/users' }}>
              <span className="icon-users"></span>
              <FormattedMessage id={'admin.menu.users.title'} />
            </Link>
          </li>
        }
        <li
          className={
            'btmMenu' +
            (splitLocation[2] && splitLocation[2] === 'datasets'
              ? ' active'
              : '')
          }
        >
          <Link to={{ pathname: '/admin/datasets' }}>
            <span className="icon-datasets"></span>
            <FormattedMessage id={'admin.menu.datasets.title'} />
          </Link>
        </li>
      </ul>
      <a className="btn-exit" href="/">
        <FormattedMessage id={'admin.returnToWebsite'} />
        <span className="icon-exit"></span>
      </a>
    </React.Fragment>
  );
}

export default NavigationAdmin;

